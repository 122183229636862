import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Delete from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';

import StatusCell from '../CampaignList/StatusCell';

const Row = ({ campaign, onClick, onDelete }) => {
  const clickHandler = () => {
    onClick(campaign);
  };

  const deleteHandler = () => {
    onDelete(campaign.id);
  };

  return (
    <TableRow
      hover
      key={campaign.id}
      onClick={clickHandler}
      sx={{
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      <TableCell
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        <Box display="flex" alignItems="center">
          <StatusCell isActive={true} />
          {campaign.title}
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          width: 115,
          '@media max-width:1279.95px': {
            display: 'none',
          },
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Button
          sx={{
            color: Colors.primary,
            marginLeft: 0.5,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
          onClick={deleteHandler}
        >
          Delete
          <Delete sx={{ color: Colors.primary, marginLeft: 1.25, width: 16 }} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  campaign: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default Row;
