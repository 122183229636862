import React from 'react';

import Colors from 'constants/colors';

import Button from '@mui/material/Button';

import { ReactComponent as FilterIcon } from 'assets/images/settings_icon.svg';
import PropTypes from 'prop-types';

const FilterButton = ({ onClick }) => (
  <Button
    variant="contained"
    sx={{
      color: Colors.black,
      backgroundColor: Colors.white,
      paddingTop: 1,
      paddingBottom: 1,
      paddingLeft: 3.5,
      paddingRight: 3.5,
      borderRadius: 6,
      '&:hover': {
        backgroundColor: Colors.lightGray,
      },
    }}
    onClick={onClick}
    startIcon={<FilterIcon />}
  >
    Filter
  </Button>
);

FilterButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default FilterButton;
