import React from 'react';

import { Save } from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BrushIcon from '@mui/icons-material/Brush';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import InfoIcon from '@mui/icons-material/Info';
import {
  Button,
  Divider,
  IconButton,
  Modal,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { styled } from '@mui/styles';

import ToolBarDrawer from './ToolBarDrawer';

const actionButtonText = [
  {
    tooltip: 'Add Team',
    button: 'Next',
  },
  {
    tooltip: 'Preview Campaign',
    button: 'Preview',
  },
  {
    tooltip: 'Create Campaign',
    button: 'Create',
  },
]

const actionButtonText2 = [
  {
    tooltip: 'Add Team',
    button: 'Next',
  },
  {
    tooltip: 'Preview Campaign',
    button: 'Preview',
  },
  {
    tooltip: 'Update Campaign',
    button: 'Update',
  },
]

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const ToolBarCampaign = ({
  setValue,
  value,
  type,
  editing,
  errors,
  create,
  save,
  activeStep,
  back,
  vendor,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [openModal, setOpenModal] = React.useState(false);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      color: 'rgba(255, 255, 255, 0.7)',
      '&.Mui-selected': {
        color: '#fff',
      },
    }),
  );

  return (
    <ToolBarDrawer variant="permanent" railWidth={true} open={true}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        height={1}
        maxWidth={75}
      >
        <Box width="100%" position="absolute" bottom={0}>
          <Divider />
          {errors.length > 0 ? (
            <IconButton
              sx={{ width: '100%', borderRadius: 0 }}
              aria-label="save"
              size="large"
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={handleOpen}
            >
              <InfoIcon fontSize="medium" color="error" />
            </IconButton>
          ) : (
            <IconButton
              sx={{ width: '100%', borderRadius: 0 }}
              aria-label="save"
              size="large"
            >
              <CheckCircleIcon fontSize="medium" color="success" />
            </IconButton>
          )}
          <Divider />
          {activeStep !== 0 ? (
            <Tooltip title="Back to builder" placement="left-start">
              <Button
                onClick={back}
                sx={{ width: '100%' }}
                size="large"
                variant="text"
                color="primary"
              >
                Back
              </Button>
            </Tooltip>
          ) : editing ? (
            <Tooltip title="Default inputs" placement="left-start">
              <IconButton
                sx={{ width: '100%', borderRadius: 0 }}
                onClick={() => setValue(9)}
                color="secondary"
                aria-label="save"
                size="large"
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Default inputs" placement="left-start">
                <IconButton
                  sx={{ width: '100%', borderRadius: 0 }}
                  onClick={() => setValue(9)}
                  color="secondary"
                  aria-label="save"
                  size="large"
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Divider />
              <Tooltip title="Save Template" placement="left-start">
                <IconButton
                  sx={{ width: '100%', borderRadius: 0 }}
                  onClick={errors.length > 0 ? handleOpen : save}
                  color="primary"
                  aria-label="save"
                  size="large"
                >
                  <Save />
                </IconButton>
              </Tooltip>
            </>
          )}
          <Divider />
          <Tooltip
            title={editing ?
              actionButtonText2[activeStep].tooltip :
              actionButtonText[activeStep].tooltip}
            placement="left-start"
          >
            <Button
              onClick={errors.length > 0 ? handleOpen : create}
              sx={{ width: '100%' }}
              size="large"
              variant="text"
              color="success"
            >
              {editing ? actionButtonText2[activeStep].button : actionButtonText[activeStep].button}
            </Button>
          </Tooltip>
        </Box>
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Box padding={2}>
          {errors.map((error) => (
            <Box mb={3}>
              <Typography variant="h6">
                {`Card ${error.card.toString()}`}
              </Typography>
              {error.issues
                .filter((el) => el !== '')
                .map((val) => (
                  <Box display="flex">
                    <InfoIcon fontSize="small" color="error" />
                    <Typography sx={{ ml: 2 }} variant="subtitle2">
                      {val}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ))}
        </Box>
      </Popover>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            border: 'none',
            boxShadow: 24,
          }}
        >
          <Box p={2} borderBottom="0.5px solid #000">
            <Typography variant="h6">Required Information</Typography>
            It looks like this campaign is missing some important data. Review
            the list below and fill out the required data to publish.
          </Box>

          {errors.map((error) => (
            <Box p={2}>
              <Typography variant="h6">
                {`Card ${error.card.toString()}`}
              </Typography>
              {error.issues
                .filter((el) => el !== '')
                .map((val) => (
                  <Box display="flex">
                    <InfoIcon fontSize="small" color="error" />
                    <Typography sx={{ ml: 2 }} variant="subtitle2">
                      {val}
                    </Typography>
                  </Box>
                ))}
            </Box>
          ))}
          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            borderTop="0.5px solid #000"
          >
            <Button onClick={handleClose} variant="text">
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </ToolBarDrawer>
  );
};

export default ToolBarCampaign;
