import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Grid from '@mui/material/Grid';

import ActionsIcon from 'assets/images/actions_icon.svg';
import EngagementsIcon from 'assets/images/engagements_icon.svg';
import ImpressionsIcon from 'assets/images/impressions_icon.svg';
import TeamMembersIcon from 'assets/images/team_members_icon.svg';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reportActions } from 'store/entities/Reports';

import MetricsItem from './MetricsItem';

const METRICS_ARRAY = [
  {
    name: 'engagements',
    title: 'engagements',
    icon: EngagementsIcon,
  },
  {
    name: 'impressions',
    title: 'views',
    icon: ImpressionsIcon,
  },
  {
    name: 'conversions',
    title: 'actions',
    icon: ActionsIcon,
  },
  {
    name: 'teamMembers',
    title: 'Team Members',
    icon: TeamMembersIcon,
  },
];

const MetricsContainer = ({ campaignId, type, teamMembers }) => {
  const [loadingMetrics, setLoadingMetrics] = useState(false);
  const [metrics, setMetrics] = useState<any>({});
  const { campaign } = useSelector((state: RootState) => state.reports);
  const dispatch = useDispatch();

  useEffect(() => {
    if (campaign) {
      setMetrics(campaign.metrics);
      setLoadingMetrics(campaign.loadingMetrics);
    }
  }, [campaign]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(reportActions.fetchCampaignMetrics({ campaignId }));
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const getCount = (val) => {
    switch (val) {
      case 'engagements':
        return metrics ? metrics.engagements : 0;
      case 'impressions':
        return metrics ? metrics.impressions : 0;
      case 'conversions':
        return metrics ? metrics.actions : 0;
      case 'teamMembers':
        return teamMembers ? teamMembers?.length : 0;
      default:
        return 0;
    }
  };

  const renderMetrics = (data) =>
    data.map((metric) => (
      <Grid item xs={6} md={6} key={metric.name}>
        <MetricsItem
          count={getCount(metric.name)}
          title={metric.title}
          icon={metric.icon}
        />
      </Grid>
    ));

  return (
    <Box width="100%" height="100%" position="relative">
      <Grid container spacing={1}>
        {renderMetrics(METRICS_ARRAY)}
      </Grid>
      {loadingMetrics && (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

MetricsContainer.propTypes = {
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  teamMembers: PropTypes.number.isRequired,
};

export default MetricsContainer;
