import React from 'react';

import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, AppBarProps, Hidden, IconButton } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';

import NavigationContext from 'contexts/NavigationContext';
import useRequiredContext from 'hooks/useRequiredContext';

const NavigationButton = () => {
  const { state, open, close } = useRequiredContext(NavigationContext)
  return (
    <IconButton onClick={() => (state === 'open' ? close() : open())}>
      <MenuIcon />
    </IconButton>
  )
}
export interface HeaderAppBarProps extends AppBarProps {
  title?: string;
  actions?: JSX.Element[];
  tabs?: JSX.Element;
}

const HeaderAppBar: React.FC<HeaderAppBarProps> = ({
  title, actions, tabs, children, ...props
}) => (
  <AppBar {...props}>
    <Toolbar>
      <Typography variant="h6">{title}</Typography>
      {tabs}
      <Hidden smDown>
        <>{children}</>
      </Hidden>
      <Box flexGrow={1} />
      {actions}
      <Hidden mdUp>
        <NavigationButton />
      </Hidden>
    </Toolbar>
  </AppBar>
);

export default HeaderAppBar;
