import React from 'react';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

import Moment from 'moment';

import TimeSelector from './TimeSelector';

const Time = ({ startTime, endTime, setStartTime, setEndTime }) => {
  const handleHour = (hour, isEndTime) => {
    let newTime;
    if (isEndTime) {
      newTime = Moment(endTime).set('hours', hour);
      setEndTime(newTime);
    } else {
      newTime = Moment(startTime).set('hours', hour);
      setStartTime(newTime);
    }
  };

  const handleMinute = (minute, isEndTime) => {
    let newTime;
    if (isEndTime) {
      newTime = Moment(endTime).set('minutes', minute);
      setEndTime(newTime);
    } else {
      newTime = Moment(startTime).set('minutes', minute);
      setStartTime(newTime);
    }
  };

  const handlePeriod = (period, isEndTime) => {
    if (isEndTime) {
      const hours = endTime.hours();
      switch (period) {
        case 'AM': {
          const newTime = Moment(endTime).set(
            'hours',
            hours > 12 ? hours - 12 : hours,
          );
          setEndTime(newTime);
          break;
        }
        case 'PM': {
          const newTime = Moment(endTime).set(
            'hours',
            hours <= 12 ? hours + 12 : hours,
          );
          setEndTime(newTime);
          break;
        }
        default:
          break;
      }
    } else {
      const hours = startTime.hours();
      switch (period) {
        case 'AM': {
          const newTime = Moment(startTime).set(
            'hours',
            hours > 12 ? hours - 12 : hours,
          );
          setStartTime(newTime);
          break;
        }
        case 'PM': {
          const newTime = Moment(startTime).set(
            'hours',
            hours <= 12 ? hours + 12 : hours,
          );
          setStartTime(newTime);
          break;
        }
        default:
          break;
      }
    }
  };

  return (
    <Box mt={3}>
      <Box>
        <Typography sx={{ mb: 0.5 }} variant="subtitle2">
          Start Time
        </Typography>
        <TimeSelector
          hour={startTime.format('h')}
          minute={startTime.format('mm')}
          period={startTime.format('A')}
          handleHour={handleHour}
          handleMinute={handleMinute}
          handlePeriod={handlePeriod}
        />
      </Box>
      <Box mt={3}>
        <Typography sx={{ mb: 0.5 }} variant="subtitle2">
          End Time
        </Typography>
        <TimeSelector
          hour={endTime ? endTime.format('h') : '12'}
          minute={endTime ? endTime.format('mm') : '00'}
          period={endTime ? endTime.format('A') : 'AM'}
          handleHour={(val) => handleHour(val, true)}
          handleMinute={(val) => handleMinute(val, true)}
          handlePeriod={(val) => handlePeriod(val, true)}
        />
      </Box>
    </Box>
  );
};

export default Time;
