import React from 'react';

import Colors from 'constants/colors';

import VariableWidthDrawer, { VariableWidthDrawerProps } from 'components/VariableWidthDrawer';

const DRAWER_WIDTH = 245;
const NAVIGATION_RAIL_WIDTH = 75;

export interface NavigationDrawerProps extends Omit<VariableWidthDrawerProps, 'width'> {
  railWidth?: boolean
}

const NavigationDrawer: React.FC<NavigationDrawerProps> = ({ railWidth, sx, ...props }) => (
  <VariableWidthDrawer
    width={railWidth ? NAVIGATION_RAIL_WIDTH : DRAWER_WIDTH}
    sx={{
      '& .MuiDrawer-paper': {
        backgroundColor: Colors.darkGray,
        color: Colors.white,
      },
      ...sx,
    }}
    {...props}
  />
)

export default NavigationDrawer;
