import React, { useEffect, useMemo, useState } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { teamsActions } from 'store/entities/Teams';

import CreateLocation from 'components/CreateLocation';
import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import MissingData from 'components/MissingData';
import NoSearchResult from 'components/NoSearchResult';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import EditLocation from './components/EditLocation';
import FilterDrawer from './components/FilterDrawer';
import LocationsList from './components/LocationsList/LocationsList';

interface AddTeamButtonProps {
  display: string;
  onClick: () => void;
}

const AddTeamButton: React.FC<AddTeamButtonProps> = ({ display, onClick }) => (
  <Button variant="contained" onClick={onClick} startIcon={<AddIcon />}>
    {display}
  </Button>
);

const Locations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [createOpen, setCreateOpen] = useState(false);
  const [locationList, setLocations] = useState<any>([]);
  const [loadingLocations, setloadingLocations] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);

  const { ids, entities, status } = useSelector((state: any) => state.teams);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    const teamsList = ids.map((id) => entities[id]);
    setLocations(teamsList);
  }, [entities]);

  useEffect(() => {
    switch (status) {
      case 'pending':
        setloadingLocations(true);
        break;
      case 'fulfilled':
        setloadingLocations(false);
        break;
      default:
        setloadingLocations(false);
        break;
    }
  }, [status]);

  const handleLocationClick = (location) => {
    setSelectedLocation(location);
    setIsEditOpen(true);
  };

  const menuOptions = useMemo(
    () => [
      { name: t('editInfo'), click: handleLocationClick },
      // { name: 'Active Account', click: () => {} }
    ],
    undefined,
  );

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const search = event.target.value;
    const locations = ids.map((id) => entities[id]);
    if (search !== null && search !== '') {
      const updatedLocations: any[] = [];
      locations.forEach((location) => {
        if (location.name.toLowerCase().includes(search.toLowerCase())) {
          updatedLocations.push(location);
        }
      });
      setLocations(updatedLocations);
    } else {
      setLocations(locations);
    }
  };

  const handleFilter = (status) => {
    //  dispatch(getLocationList({ status }));
    dispatch(teamsActions.fetchTeams({ filter: { status } }));
  };

  const getLocationTable = () => {
    if (locationList && locationList.length > 0) {
      return (
        <LocationsList
          locations={locationList}
          menuOptions={menuOptions}
          onClick={handleLocationClick}
          onEdit={handleLocationClick}
          loading={loadingLocations}
        />
      );
    } else if (!locationList || locationList.length === 0) {
      return <MissingData message={t('noTeams')} icon={2} />;
    }
    return <NoSearchResult message={t('noTeamsFound')} />;
  };

  return (
    <Scaffold
      HeaderProps={{
        title: t('teams'),
        actions: [
          locationList && locationList.length > 0 ? (
            <AddTeamButton
              display={t('addTeam')}
              onClick={() => setCreateOpen(true)}
            />
          ) : (
            <></>
          ),
        ],
      }}
    >
      <HomeContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} md={9} lg={10} xl={10}>
            <MatInput
              variant="outlined"
              placeholder={t('searchTeam')}
              onChange={handleSearch}
              onKeyPress={handleSearch}
              sx={{
                borderRadius: 1,
                backgroundColor: Colors.white,
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                  opacity: '.5',
                },
              }}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValue.length > 0 ? (
                      <ClearIcon
                        onClick={clearSearch}
                        sx={{ cursor: 'pointer', color: Colors.black }}
                      />
                    ) : (
                      <SearchIcon
                        sx={{ cursor: 'pointer', color: Colors.black }}
                      />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <Button
              sx={{
                backgroundColor: Colors.white,
                color: Colors.black,
                height: 50,
                width: '100%',
                borderRadius: 1,
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  backgroundColor: Colors.lightGray,
                },
              }}
              variant="contained"
              onClick={() => setIsFiltersOpen(true)}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <SettingsIcon />
                <Typography
                  style={{ flexGrow: 1, marginRight: 10 }}
                  variant="subtitle1"
                >
                  {t('filter')}
                </Typography>
              </div>
            </Button>
          </Grid>
        </Grid>
        {locationList && locationList.length > 0 ? (
          <Grid container sx={{ marginTop: 3.5 }}>
            {getLocationTable()}
          </Grid>
        ) : (
          <EmptyPage
            actions={[
              <AddTeamButton
                display={t('addTeam')}
                onClick={() => setCreateOpen(true)}
              />,
            ]}
            title={t('teams')}
            description={t('teamsEmptyDescription')}
            permissions={[Permissions.team.create]}
          />
        )}
      </HomeContainer>
      <EditLocation
        open={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        location={selectedLocation}
      />
      <CreateLocation open={createOpen} onClose={() => setCreateOpen(false)} />
      <FilterDrawer
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={handleFilter}
      />
    </Scaffold>
  );
};

Locations.propTypes = {};

export default Locations;
