import React from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Cancel from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/CloudDownloadOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';

import PendingCell from './PendingCell';

const PendingRow = ({ invite, onDelete }) => {
  const handleDownload = () => {
    const canvas: any = document.getElementById(`qr-code-id-${invite.id}`);
    const drawer = document.createElement('canvas');
    const pngUrl = canvas.toDataURL('image/png');
    const ctx = drawer.getContext('2d');
    const imageObj1 = new Image();
    const imageObj2 = new Image();
    imageObj1.src = 'tappshareqr.png';
    drawer.width = 401;
    drawer.height = 568;
    if (ctx) {
      imageObj1.onload = () => {
        ctx.drawImage(imageObj1, 0, 0, 401, 568);
        imageObj2.src = pngUrl;
        imageObj2.onload = () => {
          ctx.drawImage(imageObj2, 290, 445, 80, 80);
          ctx.fillStyle = '#fff';
          ctx.fillText(
            `Verification Code: ${invite.verificationCode}`,
            270,
            435,
          );
          const img = drawer
            .toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
          const downloadLink = document.createElement('a');
          downloadLink.href = img;
          downloadLink.download = `tappshare_${invite.location.replace(
            ' ',
            '-',
          )}_QRInvite.png`;
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        };
      };
    }
  };

  const deleteHandler = () => {
    onDelete(invite.id);
  };

  const renderQrCode = () => {
    if (!invite.link) {
      return null;
    }

    return (
      <Box display="none">
        <QRCode
          id={`qr-code-id-${invite.id}`}
          value={invite.link}
          fgColor={Colors.primary}
          size={200}
        />
      </Box>
    );
  };

  return (
    <>
      <TableRow
        hover
        key={invite.id}
        sx={{
          boxShadow: defaultStyles.box.shadow,
          backgroundColor: Colors.white,
          borderRadius: 2.5,
          '&:hover': {
            boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
            backgroundColor: `${Colors.white} !important`,
            cursor: 'pointer',
          },
        }}
      >
        <TableCell
          style={{ width: '20%' }}
          sx={{
            width: '20%',
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 12,
            color: 'inherit',
          }}
        >
          <PendingCell />
        </TableCell>
        <TableCell
          style={{ width: '25%' }}
          align="center"
          sx={{
            width: '25%',
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            fontWeight: 700,
            color: 'inherit',
          }}
        >
          {invite.location}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            fontWeight: 700,
            color: 'inherit',
          }}
        >
          {invite.verificationCode}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <IconButton
            aria-label="send invite"
            onClick={handleDownload}
            size="large"
          >
            <DownloadIcon />
          </IconButton>
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            width: 115,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <Button
            sx={{
              color: Colors.primary,
              marginLeft: 0.5,
              '@media max-width:1279.95px': {
                display: 'none',
              },
            }}
            onClick={deleteHandler}
          >
            Cancel
            <Cancel
              sx={{ color: Colors.primary, marginLeft: 1.25, width: 16 }}
            />
          </Button>
        </TableCell>
      </TableRow>

      {renderQrCode()}
    </>
  );
};

PendingRow.propTypes = {
  invite: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PendingRow;
