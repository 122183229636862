import React, { useState } from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import { useFormik } from 'formik';
import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { teamsActions } from 'store/entities/Teams';
import * as Yup from 'yup';

import MatDrawer from '../Elements/MatDrawer/MatDrawer';
import MatFormLabel from '../Elements/MatFormLabel/FormLabel';
import MatInput from '../Elements/MatInput/MatInput';
import { UploadImage } from '../ImageSelectors';

const TeamSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'This name is too short.')
    .max(50, 'This name is too long.')
    .required('Required'),
  phoneNumber: Yup.string().matches(
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    'Phone number is not valid',
  ),
  website: Yup.string().matches(
    /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    'Enter correct website!',
  ),
});

const CreateLocation = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [image, setImage] = useState('');

  /* const { loadingCreate } = useSelector((s: any) => s.locationsState); */

  const handleImage = (val) => {
    setImage(val);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phoneNumber: '',
      website: '',
    },
    validationSchema: TeamSchema,
    onSubmit: ({ name, phoneNumber, website }) => {
      dispatch(
        teamsActions.createTeam({
          name,
          phoneNumber,
          website,
          imageUrl: image,
        }),
      );
      onClose();
    },
  });

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => formik.handleSubmit()}
      title={t('createTeam')}
      submitBtn={t('create')}
      cancelBtn={t('cancel')}
      isLoading={false}
    >
      <Box width={1} maxWidth={465}>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sx={{ mb: 2 }}>
            <UploadImage onChange={handleImage} />
          </Grid>
          <Grid item md={12} xs={12} sx={{ mb: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title={`${t('giveTeamDistinctName')}`}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              {t('teamName')}
            </MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              size="small"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ mb: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title={`${t('phoneNumberReachTeam')}`}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              Phone Number
            </MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              name="phoneNumber"
              size="small"
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              onChange={formik.handleChange}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ mb: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title={`${t('websiteSpecificTeam')}`}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              {t('websiteAddress')}
            </MatFormLabel>
            <MatInput
              variant="outlined"
              placeholder=""
              size="small"
              name="website"
              value={formik.values.website}
              onChange={formik.handleChange}
              error={formik.touched.website && Boolean(formik.errors.website)}
              helperText={formik.touched.website && formik.errors.website}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

CreateLocation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CreateLocation;
