import React from 'react';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import BillingOverview from './components/BillingOverview';
import SelectPackage from './components/SelectPackage';

const Subscription = ({ upgradePlan, setUpgradePlan }) => {
  const { accountStatus } = useSelector((state: any) => state.subscriptions);

  const Packages = () => {
    if (!accountStatus || upgradePlan) {
      return <SelectPackage upgradePlan={upgradePlan} setUpgradePlan={setUpgradePlan} />;
    }
    return <BillingOverview setUpgradePlan={setUpgradePlan} />;
  };

  return (
    <div>
      <Packages />
    </div>
  );
};

Subscription.prototype = {
  upgradePlan: PropTypes.bool,
  setUpgradePlan: PropTypes.func,
};

export default Subscription;
