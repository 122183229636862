import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { useNavigate } from 'react-router-dom';

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import routes from 'routes';
import DOMPurify from 'dompurify';
import { Modal } from '@mui/material';

const CardPreview = ({
  card,
  folderId,
  handleOpen,
  handleClickTerminate,
  handleActions,
}) => {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openTags, setOpenTags] = useState(false);
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);

  const handleClickTags = (event) => {
    const { pageX, pageY } = event;
    setPosX(pageX);
    setPosY(pageY);
    setOpenTags(true);
  };

  const handleCloseTags = () => {
    setOpenTags(false);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    console.log('Card:::', card);
  }, [card]);

  const handleClickEdit = (id) => {
    navigate(routes.teamHub.build(), {
      state: { id, type: 'edit', folderId },
    });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onClickCard = (id) => {
    if (folderId) {
      navigate(routes.teamHub.card(id), {
        state: { folderId },
      });
    } else {
      navigate(routes.templates.campaign(id));
    }
  };

  return (
    <Card sx={{ cursor: 'pointer' }}>
      <CardHeader
        action={
          folderId ? (
            <div>
              <IconButton onClick={handleClick} aria-label="settings">
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={() => handleClickEdit(card.id)}>
                  Edit
                </MenuItem>
                <MenuItem onClick={() => handleClickTerminate(card.id)}>
                  Terminate
                </MenuItem>
              </Menu>
            </div>
          ) : null
        }
        subheader={Moment(card.createdAt).format('LL')}
      />
      <CardMedia
        component="img"
        height="194"
        image={card?.image?.url ? card?.image?.url : card.image}
        alt={card.id}
        onClick={() => onClickCard(card.id)}
      />
      <CardContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: 2,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            {card.title}
          </Typography>
          {card.ctaOne && (
            <Button
              color="primary"
              variant={card.styling?.buttonVariant}
              disableElevation
              onClick={() => {
                if (handleActions) {
                  handleActions();
                }
                const link = card.ctaOne.link.includes('https://')
                  ? card.ctaOne.link
                  : `https://${card.ctaOne.link}`;
                const win = window.open(link);
                win?.focus();
              }}
              sx={{
                borderRadius: card.styling?.buttonBorder,
                color: card.styling?.textButtonColor,
                height: 30,
                borderColor:
                  card.styling?.buttonVariant === 'outlined'
                    ? card.styling?.buttonColor
                    : '',
                backgroundColor:
                  card.styling?.buttonVariant === 'contained'
                    ? card.styling?.buttonColor
                    : '',
              }}
            >
              {card.ctaOne.name}
            </Button>
          )}
        </Box>
        <Box
          color={card.styling?.mainColor}
          textAlign={card.styling?.mainAlignment}
          fontSize={card.styling?.mainSize}
          fontStyle={
            card.styling?.mainFormat?.includes('italic') ? 'italic' : ''
          }
          sx={{
            textDecoration: card.styling?.mainFormat?.includes('underline')
              ? 'underline'
              : '',
          }}
          fontWeight={card.styling?.mainFormat?.includes('bold') ? 'bold' : ''}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              card?.description?.ops
                ? card.description.ops[0].insert
                : card?.description,
              {
                USE_PROFILES: { html: true },
              },
            ),
          }}
        />
      </CardContent>
      <CardActions disableSpacing sx={{ minHeight: 57 }}>
        {card?.tags?.length ? (
          <IconButton aria-label="share" onClick={handleClickTags}>
            <LocalOfferIcon />
          </IconButton>
        ) : null}
        <Modal
          open={openTags}
          onClose={handleCloseTags}
          BackdropProps={{ invisible: true }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: posY,
              left: posX,
              p: 2,
              bgcolor: 'background.paper',
              boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)',
            }}
          >
            <Typography variant="h6">Tags</Typography>
            {card?.tags?.map((val) => (
              <Box display="flex">
                <Typography sx={{ ml: 2 }} variant="subtitle2">
                  {`- ${val}`}
                </Typography>
              </Box>
            ))}
          </Box>
        </Modal>
      </CardActions>
    </Card>
  );
};

CardPreview.propTypes = {
  card: PropTypes.object.isRequired,
  handleOpen: PropTypes.func,
  handleClickTerminate: PropTypes.func,
  handleActions: PropTypes.func,
  folderId: PropTypes.string,
};

export default CardPreview;
