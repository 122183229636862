import React from 'react';

import Colors from 'constants/colors';

import Autocomplete from '@mui/lab/Autocomplete';
import { Box } from '@mui/material';
import TextField from '@mui/material/TextField';

// TODO: Integregate as classes={{ popper: classes.popper }} in Autocomplete
// const useStyles: any = makeStyles({
//   popper: {
//     '& li': {
//       color: Colors.black,
//       fontSize: 14,
//     },
//   },
// });

const suggestedOptions = [
  'Learn More',
  'Book Now',
  'Apply Now',
  'Contact Us',
  'Donate Now',
  'Download',
  'Request Time',
  'See Menu',
  'Shop Now',
  'Sign Up',
];

const CTATextField: React.FC<any> = ({ multiple, value, onChange, placeholder, size }) => (
  <Box width={1}>
    <Autocomplete
      freeSolo
      multiple={multiple}
      value={value}
      inputValue={value}
      onInputChange={(e, newInputValue) => onChange(newInputValue)}
      onChange={(e, newValue) => onChange(newValue)}
      disableClearable
      options={suggestedOptions.map((option) => option)}
      size={size || 'medium'}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={
            placeholder !== null && typeof placeholder !== 'undefined'
              ? placeholder
              : 'Button Text'
          }
          sx={{
            flexGrow: 1,
            [`& fieldset`]: {
              borderRadius: 4,
            },
            '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              borderColor: Colors.primary,
            },
            '& .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
              color: Colors.black,
              fontSize: 14,
            },
            '& .MuiInputLabel-outlined': {
              color: Colors.black,
            },
            '& .MuiInputBase-input::placeholder': {
              color: Colors.primaryBlack,
              opacity: 1,
            },
          }}
          variant="outlined"
          size={size || 'medium'}
          fullWidth
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  </Box>
);

export default CTATextField;
