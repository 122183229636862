import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { userActions } from 'store/domain/user';

import PasswordTextField from 'components/PasswordTextField';

const PASSWORD_PATTERN = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,20})');

const Form = () => {
  const { t } = useTranslation();
  const location: any = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [password, setPassword] = useState<any>(null);
  const [userName, setUserName] = useState<any>('');
  const [token, setToken] = useState<any>('');

  useEffect(() => {
    const values = queryString.parse(location.search);
    setToken(values.token);
    setUserName(values.username);
  }, []);

  const handlePassword = (val) => {
    setPassword(val.target.value);
  };

  const handleSuccess = () => {
    navigate('/');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (PASSWORD_PATTERN.exec(password)) {
      dispatch(
        userActions.passwordReset({
          password,
          token,
          username: userName,
          onSuccess: handleSuccess,
        }),
      );
      dispatch(userActions.clearStatus());
    } else {
      enqueueSnackbar(
        'Password must be at least 7 characters, one uppercase, one special',
        { variant: 'error' },
      );
    }
  };

  return (
    <Box>
      <Typography variant="h5" align="center" sx={{ mb: 4 }}>
        {t('forgotPasswordTitle')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PasswordTextField
              variant="outlined"
              required
              fullWidth
              value={password}
              autoFocus
              label="New Password"
              onChange={handlePassword}
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              disableElevation={true}
              color="primary"
              variant="contained"
              type="submit"
              sx={{
                width: 300,
                marginTop: 2,
                borderRadius: 8,
                height: 50,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {t('resetPassword')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default Form;
