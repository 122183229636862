import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import DateRangeIcon from '@mui/icons-material/DateRange';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Box, InputLabel, Select, MenuItem, Grid } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import ButtonGroup from '@mui/material/ButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';

import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import {
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
} from '@mui/icons-material';

const AVAILABLE_COLORS = [
  '#f44336',
  '#E91E63',
  '#673AB7',
  '#2196F3',
  '#00BCD4',
  '#4CAF50',
  '#FFC107',
  '#FF9800',
  '#607D8B',
  '#ef9a9a',
  '#F48FB1',
  '#B39DDB',
  '#90CAF9',
  '#80DEEA',
  '#A5D6A7',
  '#FFE082',
  '#FFCC80',
  '#B0BEC5',
];

const Styling = ({ item, onUpdate, tabIndex }) => {
  const [type, setType] = useState('redirect');
  const [buttonVariant, setButtonVariant] = useState('text');
  const [textSize, setTextSize] = useState('medium');
  const [borderCard, setBorderCard] = useState(1);
  const [elevation, setElevation] = useState(1);
  const [border, setBorder] = useState(1);
  const [formats, setFormats] = useState(() => ['bold', 'italic']);
  const [alignment, setAlignment] = useState<string | null>('left');

  useEffect(() => {
    if (item && !item?.styling) {
      const object = JSON.parse(JSON.stringify(item));
      object.styling = {};
      onUpdate(object);
    } else if (item?.styling) {
      const {
        buttonBorder,
        mainFormat,
        mainAlignment,
        mainSize,
        buttonVariant,
      } = item.styling;
      setBorder(buttonBorder || 1);
      setFormats(mainFormat || ['bold', 'italic']);
      setAlignment(mainAlignment || 'left');
      setTextSize(mainSize || 'medium');
      setButtonVariant(buttonVariant || 'text');
    }
  }, [item]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null,
  ) => {
    const object = item;
    object.styling.mainAlignment = newAlignment;
    onUpdate(object);
    setAlignment(newAlignment);
  };

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newFormats: string[],
  ) => {
    const object = {
      ...item,
      styling: { ...item.styling, mainFormat: newFormats },
    };
    onUpdate(object);
    setFormats(newFormats);
  };

  const handleColor = (c) => {
    const object = {
      ...item,
      styling: { ...item.styling, buttonColor: c.hex },
    };
    onUpdate(object);
  };

  const handleColorButtonText = (c) => {
    const object = {
      ...item,
      styling: { ...item.styling, textButtonColor: c.hex },
    };
    onUpdate(object);
  };

  const handleColorText = (c) => {
    const object = {
      ...item,
      styling: { ...item.styling, mainColor: c.hex },
    };
    onUpdate(object);
  };

  const handleTextSize = (e) => {
    const object = item;
    object.styling.mainSize = e.target.value;
    setTextSize(e.target.value);
    onUpdate(object);
  };

  const handleBorder = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, buttonBorder: e.target.value },
    };
    setBorder(e.target.value);
    onUpdate(object);
  };

  const handleElevation = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, cardElevation: e.target.value },
    };
    onUpdate(object);
  };

  const handleBorderCard = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, cardBorder: e.target.value },
    };
    setBorderCard(e.target.value);
    onUpdate(object);
  };

  const handleButtonVariant = (e) => {
    const object = {
      ...item,
      styling: { ...item.styling, buttonVariant: e.target.value },
    };
    setButtonVariant(e.target.value);
    onUpdate(object);
  };

  const renderItems = () => {
    switch (tabIndex) {
      case 1:
        return (
          <>
            <Typography my={2} color="blue" variant="h6">
              Card Styling
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  sx={{
                    marginBottom: 2,
                    width: '100%',
                  }}
                  margin="dense"
                >
                  <InputLabel id="demo-simple-select-label">
                    Border Radius
                  </InputLabel>
                  <Select
                    value={borderCard}
                    labelId="demo-simple-select-label"
                    onChange={handleBorderCard}
                    label="Border Radius"
                  >
                    <MenuItem value={1}>4</MenuItem>
                    <MenuItem value={2}>8</MenuItem>
                    <MenuItem value={3}>12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography my={2} color="blue" variant="h6">
              Text Styling
            </Typography>
            <Grid container spacing={2} width="100%">
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <ToggleButtonGroup
                  value={formats}
                  onChange={handleFormat}
                  aria-label="text formatting"
                >
                  <ToggleButton value="bold" aria-label="bold">
                    <FormatBold />
                  </ToggleButton>
                  <ToggleButton value="italic" aria-label="italic">
                    <FormatItalic />
                  </ToggleButton>
                  <ToggleButton value="underline" aria-label="underline">
                    <FormatUnderlined />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={6}>
                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  onChange={handleAlignment}
                  aria-label="text alignment"
                >
                  <ToggleButton value="left" aria-label="left aligned">
                    <FormatAlignLeft />
                  </ToggleButton>
                  <ToggleButton value="center" aria-label="centered">
                    <FormatAlignCenter />
                  </ToggleButton>
                  <ToggleButton value="right" aria-label="right aligned">
                    <FormatAlignRight />
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-text">
                    Text Size
                  </InputLabel>
                  <Select
                    value={textSize}
                    labelId="demo-simple-select-text"
                    onChange={handleTextSize}
                    label="Border Radius"
                  >
                    <MenuItem value="small">Small</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="large">Large</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TwitterPicker
                  style={{ minWidth: 238 }}
                  onChange={handleColorText}
                  colors={AVAILABLE_COLORS}
                  triangle="hide"
                />
              </Grid>
            </Grid>
          </>
        );
      case 5:
        return (
          <>
            <Typography my={2} color="blue" variant="h6">
              Styling
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h6">Button Text Color</Typography>
                <TwitterPicker
                  style={{ minWidth: 238 }}
                  onChange={handleColorButtonText}
                  colors={AVAILABLE_COLORS}
                  triangle="hide"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel id="demo-simple-select-text">
                    Button Type
                  </InputLabel>
                  <Select
                    value={buttonVariant}
                    labelId="demo-simple-select-text"
                    onChange={handleButtonVariant}
                    label="Button Type"
                  >
                    <MenuItem value="outlined">Outlined</MenuItem>
                    <MenuItem value="contained">Contained</MenuItem>
                    <MenuItem value="text">Text</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  variant="outlined"
                  sx={{
                    marginBottom: 2,
                    width: '100%',
                  }}
                  margin="dense"
                >
                  <InputLabel id="demo-simple-select-label">
                    Border Radius
                  </InputLabel>
                  <Select
                    value={border}
                    labelId="demo-simple-select-label"
                    onChange={handleBorder}
                    label="Border Radius"
                  >
                    <MenuItem value={1}>4</MenuItem>
                    <MenuItem value={2}>8</MenuItem>
                    <MenuItem value={3}>12</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <TwitterPicker
                  style={{ minWidth: 238 }}
                  onChange={handleColor}
                  colors={AVAILABLE_COLORS}
                  triangle="hide"
                />
              </Grid>
            </Grid>
          </>
        );

      default:
        return <></>;
    }
  };

  return <Box>{renderItems()}</Box>;
};

Styling.propTypes = {
  item: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

export default Styling;
