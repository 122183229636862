import React, { useState, useRef, useEffect } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Typography from '@mui/material/Typography';

import mapboxgl from 'mapbox-gl';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { reportActions } from 'store/entities/Reports';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import ZoomControl from 'components/Elements/MapControls/ZoomControl';
import Title from 'components/Elements/Title/Title';

import config from '../../../../config';

mapboxgl.accessToken = config.MAPBOX_ACCESS_TOKEN;

const ANIMATE_DURATATION = 1000;

const EngagementsByGeography = ({ timeFrame }) => {
  const dispatch = useDispatch();
  const [map, setMap] = useState<any>(null);
  const mapContainer = useRef(null);
  const { t } = useTranslation();

  const { loadingMap, mapData } = useSelector((state: any) => state.reports);

  const onZoomIn = () => {
    map.zoomIn({ duration: ANIMATE_DURATATION });
  };

  const onZoomOut = () => {
    map.zoomOut({ duration: ANIMATE_DURATATION });
  };

  const loadMap = (data) => {
    if (!map && mapContainer.current) {
      const newMap = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [0, 0],
        zoom: 0.1,
        attributionControl: false,
      });
      newMap.on('load', () => {
        newMap.addSource('engagments', {
          type: 'geojson',
          data: {
            features: data,
          },
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 30,
        });

        newMap.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'engagments',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'step',
              ['get', 'point_count'],
              Colors.primaryLight,
              100,
              Colors.primary,
              750,
              Colors.primaryDark,
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              10,
              100,
              15,
              750,
              20,
            ],
          },
        });

        newMap.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'engagments',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-size': 12,
          },
        });

        newMap.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'engagments',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': Colors.primary,
            'circle-radius': 4,
          },
        });
      });
      setMap(newMap);
    }
  };

  useEffect(() => {
    dispatch(reportActions.getMap({ timeFrame }));
  }, [timeFrame]);

  useEffect(() => {
    if (mapData && !loadingMap) {
      loadMap(mapData);
    }
  }, [mapData]);

  return (
    <CardContainer>
      <Box
        maxWidth="100%"
        maxHeight="100%"
        m={3}
        position="relative"
        height="calc(100% - 48px)"
        display="flex"
        flexDirection="column"
        sx={{
          maxWidth: '100%',
          '@media (max-device-width: 769px)': {
            height: 340,
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Title size="medium" weight="bold">
            {t('engagementsByGeo') as any}
          </Title>
        </Box>
        <Box
          sx={{
            '& .mapboxgl-canvas-container': {},
            '& .mapboxgl-canvas': {
              width: '100% !Important',
              maxHeight: '325px',
            },
            '& .mapboxgl-canvas:focus': {
              outline: 'none',
            },
          }}
          width="100%"
          flex={1}
          overflow="hidden"
          m="24px auto 0 auto"
          ref={
            (el) => (mapContainer.current = el as any) // eslint-disable-line
          }
        >
          {loadingMap && (
            <CircularProgress
              sx={{ position: 'absolute', left: '50%', top: '50%' }}
            />
          )}
        </Box>
        {/* <ZoomControl
          sx={{
            position: 'absolute',
            left: 8,
            bottom: 0,
            zIndex: 2,
            '@media (max-device-width: 959px)': {
              bottom: '40px',
            },
          }}
          onZoomIn={onZoomIn}
          onZoomOut={onZoomOut}
        /> */}
        <Box
          position="absolute"
          textAlign="right"
          bgcolor="white"
          left={0}
          bottom={0}
          right={0}
          pr={3}
          pt={1.5}
        >
          <Typography variant="caption">
            {t('availableLocationData')}
          </Typography>
        </Box>
      </Box>
    </CardContainer>
  );
};

EngagementsByGeography.propTypes = {
  timeFrame: PropTypes.string.isRequired,
};

export default EngagementsByGeography;
