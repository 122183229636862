import React from 'react';
import Colors from 'constants/colors';

import { Box } from '@mui/system';
import MatSelect from 'components/Elements/MatSelect/MatSelect';

import PropTypes from 'prop-types';

const FilterDropDown = ({ value, options, onChange }) => (
  <Box>
    <MatSelect
      value={value}
      defaultValue="All"
      onChange={(e) => onChange(e.target.value)}
      options={[{ name: 'All', id: 'all' }, ...options]}
      nameKey="name"
      valueKey="id"
      sx={{
        [`& fieldset`]: {
          borderColor: Colors.primary,
          opacity: '.5',
        },
      }}
    />
  </Box>
);

FilterDropDown.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FilterDropDown;
