import React from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const HOURS = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

const MINUTES = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

const PERIOD = ['AM', 'PM'];

const TimeSelector = ({
  hour,
  minute,
  period,
  handleHour,
  handleMinute,
  handlePeriod,
}) => (
  <Box display="flex">
    <FormControl
      variant="outlined"
      size="small"
      sx={{ backgroundColor: Colors.white }}
    >
      <InputLabel id="hour-label">Hour</InputLabel>
      <Select
        label="Hour"
        labelId="hour-label"
        value={hour}
        onChange={(e) => handleHour(e.target.value)}
      >
        {HOURS.map((h) => (
          <MenuItem value={h}>{h}</MenuItem>
        ))}
      </Select>
    </FormControl>
    <Box mx={0.5}>
      <FormControl
        variant="outlined"
        size="small"
        sx={{ backgroundColor: Colors.white }}
      >
        <InputLabel id="minute-label">Minute</InputLabel>
        <Select
          label="Minute"
          labelId="minute-label"
          value={minute}
          onChange={(e) => handleMinute(e.target.value)}
        >
          {MINUTES.map((m) => (
            <MenuItem value={m}>{m}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
    <FormControl
      variant="outlined"
      size="small"
      sx={{ backgroundColor: Colors.white }}
    >
      <InputLabel id="period-label">Period</InputLabel>
      <Select
        label="Period"
        labelId="period-label"
        value={period}
        onChange={(e) => handlePeriod(e.target.value)}
      >
        {PERIOD.map((p) => (
          <MenuItem value={p}>{p}</MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

export default TimeSelector;
