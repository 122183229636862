import Parse, { Object, JSONBaseAttributes, Pointer } from 'parse';

export type ReportType = 'engagement' | 'conversion' | 'impression' |'card_action';

export interface ReportAttributes {
  premium_pointer?: Pointer;
  team_member_pointer?: Pointer;
  business_pointer: Pointer;
  type: ReportType;
  createdAt: Date;
  count: number;
}

export type IReport = Object.ToJSON<ReportAttributes> & JSONBaseAttributes;

class Report extends Parse.Object<ReportAttributes> {
  constructor(init: ReportAttributes) {
    super('metrics', init);
  }
}

export default Report;
