import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import AddCardDrawer from './AddCardDrawer';
import { Divider } from '@mui/material';
import { useDispatch } from 'react-redux';
import { subscriptionActions } from 'store/entities/Subscriptions';

const CardBox = ({ cards, defaultCard }) => {
  const [showAddCardDrawer, setShowAddCardDrawer] = useState(false);
  const dispatch = useDispatch();
  const setDefaultCard = (paymentId) => {
    dispatch(subscriptionActions.updateDefaultCard({ paymentId }));
  };

  return (
    <Box
      width={350}
      pt={1}
      pb={1}
      pl={2}
      pr={2}
      borderRadius={1}
      border={0.5}
      borderColor={Colors.lightBlueGray}
      maxHeight="calc(100vh - 500px)"
      overflow="auto"
    >
      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            flexGrow: 1,
            fontWeight: 'bold',
            fontSize: 16,
          }}
        >
          Payment
        </Typography>
        <Button
          variant="text"
          color="primary"
          onClick={() => setShowAddCardDrawer(true)}
        >
          New Card
        </Button>
      </Box>
      <Divider />
      {cards.map((card) => (
        <>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography>{`**** **** **** ${card.card.last4}`}</Typography>
              <Typography>{card.card.brand}</Typography>
            </Box>
            {defaultCard === card.id ? (
              <Typography>Default</Typography>
            ) : (
              <Button variant="text" onClick={() => setDefaultCard(card.id)}>
                Set as Default
              </Button>
            )}
          </Box>
          <Divider />
        </>
      ))}
      <AddCardDrawer
        open={showAddCardDrawer}
        onClose={() => setShowAddCardDrawer(false)}
      />
    </Box>
  );
};

CardBox.propTypes = {
  cards: PropTypes.array,
  defaultCard: PropTypes.string.isRequired,
};

CardBox.defaultProps = {
  cards: [],
};

export default CardBox;
