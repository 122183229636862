import React, { useState } from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';

const useStyles: any = makeStyles({
  switchContainer: {
    position: 'relative',
    width: 'max-content',
  },
  root: {
    width: 127,
    height: 42,
    padding: 0,
    margin: 4,
    '& .MuiIconButton-root:hover': {
      backgroundColor: 'transparent',
    },
  },
  switchBase: {
    width: '100%',
    padding: 1,
    top: 4,
    left: -43,
    color: Colors.gray,
    transform: 'translateX(85px)',
    '&$checked': {
      transform: 'translateX(0px)',
      color: Colors.primary,
      '& + $track': {
        backgroundColor: 'transparent',
        opacity: 1,
        border: `1px solid ${Colors.primary}`,
      },
    },
    '&$focusVisible $thumb': {
      color: 'transparent',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 32,
    height: 32,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 21,
    border: `1px solid ${Colors.gray}`,
    backgroundColor: 'transparent',
    opacity: 1,
  },
  checked: {},
  focusVisible: {},
  statusLabel: {
    color: Colors.black,
    position: 'absolute',
    right: 35,
    top: 15,
    zIndex: -1,
    cursor: 'pointer',
  },
  inactiveLabel: {
    transform: ' translateX(-26px)',
    color: Colors.gray,
  },
});

export interface MatSwitchProps {
  labelActive: string;
  className?: string;
  labelInactive: string;
  defaultChecked: boolean;
  onChange: (a: any) => any;
}

const MatSwitch: React.FC<MatSwitchProps> = ({
  labelActive,
  labelInactive,
  defaultChecked,
  onChange,
  className,
}) => {
  const classes: any = useStyles();
  const [status, setStatus] = useState(defaultChecked);

  const onChangeHandler = (event) => {
    onChange(event.target.checked);
    setStatus(event.target.checked);
  };

  return (
    <Box position="relative" width="max-content">
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple={true}
        checked={status}
        onChange={onChangeHandler}
        className={className}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
      <span
        className={clsx(
          classes.statusLabel,
          !status ? classes.inactiveLabel : '',
        )}
      >
        {status ? labelActive : labelInactive}
      </span>
    </Box>
  );
};

export default MatSwitch;
