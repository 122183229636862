import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select/Select';

export interface MatSelectProps extends SelectProps {
  onChange?: (v: any) => any;
  onClick?: () => any;
  className?: string;
  style?: any;
  disabled?: boolean;
  icon?: any;
  defaultValue?: any;
  valueKey: string;
  nameKey: string;
  variant?: 'filled' | 'outlined' | 'standard';
  options: any[];
  borderColor?: string;
}

const MatSelect: React.FC<MatSelectProps> = ({
  options,
  onChange,
  onClick,
  value,
  className,
  disabled,
  icon,
  variant,
  valueKey,
  nameKey,
  defaultValue,
  borderColor,
  size,
  style,
}) => {
  const [height, setHeight] = useState(50);

  useEffect(() => {
    switch (size) {
      case 'small':
        setHeight(40);
        break;
      case 'medium':
        setHeight(50);
        break;
      default:
        setHeight(50);
        break;
    }
  }, [size]);

  const renderSelectItems = (optionItems) =>
    optionItems.map((optionItem, index) => (
      <MenuItem
        key={optionItem[valueKey] ? optionItem[valueKey] : index}
        value={optionItem[valueKey]}
        disabled={optionItem.disabled}
        sx={{
          color: '#1C2228',
          fontSize: 'inherit',
          textTransform: 'inherit',
          borderLeft: '2px solid transparent',
        }}
      >
        {optionItem[nameKey]}
      </MenuItem>
    ));

  return (
    <Select
      variant={variant || 'outlined'}
      value={value}
      defaultValue={defaultValue}
      onChange={onChange}
      disabled={disabled}
      IconComponent={ExpandMoreIcon}
      onClick={onClick}
      startAdornment={
        icon ? <InputAdornment position="start">{icon}</InputAdornment> : null
      }
      style={{ height }}
      sx={{
        display: 'flex',
        alignItems: 'center',
        fontSize: 'inherit',
        fontFamily: 'Arial, Helvetica, sans-serif',
        textTransform: 'inherit',
        color: Colors.black,
        '& .MuiSelect-selectMenu': {
          padding: '17.5px 14px',
        },
        '&:focus': {
          backgroundColor: 'transparent !important',
        },
        [`& fieldset`]: {
          border: ' 1px solid',
          borderWidth: '1px !important',
          borderColor: Colors.primary,
          boxShadow: '0 2px 7px 0 rgba(111,135,183,0.2)',
          borderRadius: 1,
        },
        ...style,
      }}
    >
      {renderSelectItems(options)}
    </Select>
  );
};

export default MatSelect;
