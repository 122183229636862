import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Menu from 'assets/images/menu.svg';
import PropTypes from 'prop-types';

const AppBar = ({ title, image, onOpenDrawer }) => (
  <Box
    width="100%"
    height={40}
    pl={1}
    pr={1}
    pb={0.5}
    pt={0.5}
    display="flex"
    alignItems="center"
    sx={{
      boxShadow: '0 4px 2px -2px #BDBDBD',
    }}
  >
    {image && (
      <img
        alt="logo"
        style={{
          height: 35,
          objectFit: 'contain',
          marginRight: 8,
        }}
        src={image}
      />
    )}
    <Typography sx={{ flexGrow: 1 }} variant="caption">
      {title}
    </Typography>
    <input
      style={{
        width: 24,
      }}
      type="image"
      alt="Menu"
      src={Menu}
      onClick={onOpenDrawer}
    />
  </Box>
);

AppBar.propTypes = {
  title: PropTypes.string.isRequired,
  onOpenDrawer: PropTypes.func.isRequired,
  image: PropTypes.string,
};

AppBar.defaultProps = {
  image: null,
};

export default AppBar;
