import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';

import InvitePendingIcon from 'assets/images/invite_pending.svg';

const PendingCell = () => (
  <Box display="flex" alignItems="center">
    <Box
      height={64}
      width={64}
      mr={2.5}
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url(${InvitePendingIcon})`,
        backgroundColor: Colors.violet,
      }}
    />
    Invite Pending
  </Box>
);

export default PendingCell;
