import React from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { defaultStyles } from 'constants/styles';

import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';

import PermissionRequired from 'components/PermissionRequired';

import StatusCell from '../../../TeamMembers/components/TeamMembersList/StatusCell';
import TextWithToolTip from 'components/TextWithToolTip/TextWithToolTip';

const Row: React.FC<any> = ({ location, onClick, onEdit }) => {
  const clickHandler = () => {
    onClick(location);
  };

  return (
    <TableRow
      hover
      key={location.id}
      onClick={clickHandler}
      sx={{
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      <TableCell
        sx={{
          maxWidth: 100,
          fontSize: 12,
          color: 'inherit',
          border: 'none',
          padding: 0,
          minHeight: 64,
          width: 84,
        }}
      >
        <StatusCell isActive={location.is_active} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        {location.is_active ? 'Active' : 'Inactive'}
      </TableCell>
      <TableCell
        sx={{
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
          border: 'none',
          padding: 0,
          minHeight: 64,
        }}
      >
        <TextWithToolTip text={location.name} />
      </TableCell>
      <TableCell
        align="right"
        sx={{
          fontSize: 16,
          color: 'inherit',
          border: 'none',
          padding: 0,
          minHeight: 64,
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <PermissionRequired permissions={[Permissions.team.edit]}>
          <IconButton
            onClick={() => onEdit(location)}
            aria-label="delete"
            size="large"
          >
            <EditIcon />
          </IconButton>
        </PermissionRequired>
      </TableCell>
    </TableRow>
  );
};

Row.propTypes = {
  location: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

export default Row;
