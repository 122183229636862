import React from 'react';

import Colors from 'constants/colors';

import ClearIcon from '@mui/icons-material/Clear';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import PropTypes from 'prop-types';

import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';

const EmailRow = ({
  email,
  firstName,
  lastName,
  handleDelete,
  handleEmail,
  handleFirstName,
  handleLastName,
}) => (
  <Grid container spacing={1} alignItems="center">
    <Grid item md={5} sx={{ marginBottom: 2 }}>
      <MatFormLabel component="legend" style={{ marginBottom: 1.75 }}>
        Email Address
      </MatFormLabel>
      <MatInput
        value={email}
        variant="outlined"
        placeholder=""
        onChange={(e) => handleEmail(e)}
        sx={{
          '& .MuiOutlinedInput-root': {
            maxHeight: 53,
          },
          [`& fieldset`]: {
            borderColor: Colors.primary,
          },
        }}
        size="small"
      />
    </Grid>
    <Grid item md={3} sx={{ marginBottom: 2 }}>
      <MatFormLabel component="legend" style={{ marginBottom: 1.75 }}>
        First Name
      </MatFormLabel>
      <MatInput
        value={firstName}
        variant="outlined"
        placeholder=""
        onChange={(e) => handleFirstName(e)}
        sx={{
          '& .MuiOutlinedInput-root': {
            maxHeight: 53,
          },
          [`& fieldset`]: {
            borderColor: Colors.primary,
          },
        }}
        size="small"
      />
    </Grid>
    <Grid item md={3} sx={{ marginBottom: 2 }}>
      <MatFormLabel component="legend" style={{ marginBottom: 1.75 }}>
        Last Name
      </MatFormLabel>
      <MatInput
        value={lastName}
        variant="outlined"
        placeholder=""
        onChange={(e) => handleLastName(e)}
        sx={{
          '& .MuiOutlinedInput-root': {
            maxHeight: 53,
          },
          [`& fieldset`]: {
            borderColor: Colors.primary,
          },
        }}
        size="small"
      />
    </Grid>
    <Grid item xs={1}>
      <IconButton
        aria-label="delete"
        onClick={handleDelete}
        sx={{ marginTop: 2 }}
        size="large"
      >
        <ClearIcon />
      </IconButton>
    </Grid>
  </Grid>
);

EmailRow.propTypes = {
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleEmail: PropTypes.func.isRequired,
  handleFirstName: PropTypes.func.isRequired,
  handleLastName: PropTypes.func.isRequired,
};

export default EmailRow;
