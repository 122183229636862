import React from 'react';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';

import PropTypes from 'prop-types';

const ImageTabBar = ({ tab, onChange }) => (
  <div>
    <ButtonGroup
      sx={{
        display: 'flex',
      }}
      color="primary"
    >
      <Button
        onClick={() => onChange(0)}
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
          '&.MuiButton-contained': {
            boxShadow: 'none',
          },
          '&:first-child': {
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          },
          '&:last-child': {
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          },
        }}
        variant={tab === 0 ? 'contained' : 'outlined'}
      >
        Library
      </Button>
      <Button
        onClick={() => onChange(1)}
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
          '&.MuiButton-contained': {
            boxShadow: 'none',
          },
          '&:first-child': {
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          },
          '&:last-child': {
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          },
        }}
        variant={tab === 1 ? 'contained' : 'outlined'}
      >
        Photos
      </Button>
      <Button
        onClick={() => onChange(2)}
        sx={{
          paddingLeft: 3,
          paddingRight: 3,
          '&.MuiButton-contained': {
            boxShadow: 'none',
          },
          '&:first-child': {
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          },
          '&:last-child': {
            borderTopRightRadius: 6,
            borderBottomRightRadius: 6,
          },
        }}
        variant={tab === 2 ? 'contained' : 'outlined'}
      >
        Gifs
      </Button>
    </ButtonGroup>
  </div>
);

ImageTabBar.propTypes = {
  tab: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ImageTabBar;
