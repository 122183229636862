import React, { useState } from 'react';

import Colors from 'constants/colors';
import { defaultStyles } from 'constants/styles';

import Cancel from '@mui/icons-material/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MembersActions } from 'store/entities/Members';

import StatusCell from './StatusCell';

const PendingRow = ({ invite, onClick, onDelete }) => {
  const dispatch = useDispatch();
  const [resendingInvite, setResendingInvite] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleResend = () => {
    setResendingInvite(true);
    dispatch(
     MembersActions.resendInviteEmail({
        callBack: ({ status, message }) => {
          setResendingInvite(false);
          switch (status) {
            case 'failure':
              enqueueSnackbar(message, { variant: 'error' });
              break;
            case 'success':
              enqueueSnackbar(message, { variant: 'success' });
              break;
            default:
          }
        },
        inviteId: invite.id,
      }),
    );
  };

  const clickHandler = () => {
    onClick(invite);
  };

  const deleteHandler = () => {
    onDelete(invite.id)
  };

  return (
    <TableRow
      hover
      key={invite.id}
      onClick={clickHandler}
      sx={{
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      <TableCell
        sx={{
          width: '20%',
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 12,
          color: 'inherit',
        }}
      >
        <StatusCell isActive={invite.status === 'active'} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '25%',
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        {invite.location}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          width: '25%',
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        {invite.verificationCode}
      </TableCell>
      <TableCell
        align="right"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <IconButton
          aria-label="send invite"
          onClick={handleResend}
          size="large"
        >
          {resendingInvite ? <CircularProgress size="1em" /> : <SendIcon />}
        </IconButton>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          width: 115,
          '@media max-width:1279.95px': {
            display: 'none',
          },
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Button
          sx={{
            color: Colors.primary,
            marginLeft: 0.5,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
          onClick={deleteHandler}
        >
          Cancel
          <Cancel sx={{ color: Colors.primary, marginLeft: 1.25, width: 16 }} />
        </Button>
      </TableCell>
    </TableRow>
  );
};

PendingRow.propTypes = {
  invite: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default PendingRow;
