import React, { useEffect, useState } from 'react';

import Permissions from 'constants/permissions';

import { Autocomplete, Chip, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MembersActions } from 'store/entities/Members';
import { teamsActions, teamsSelectors } from 'store/entities/Teams';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import MatSwitch from 'components/Elements/MatSwitch/MatSwtich';
import PermissionRequired from 'components/PermissionRequired';

import PermissionDrawer from './PermissionDrawer';
import { Save } from '@mui/icons-material';

const EditDrawer = ({ teamMember, open, onClose, onEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [locationId, setLocationId] = useState('');
  const [status, setStatus] = useState(true);
  const [editingPermission, setEditingPermission] = useState(false);
  const [tags, setTags] = useState<any>([]);

  const { businessId } = useSelector((state: any) => state.user);
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    if (teamMember) {
      setLocationId(teamMember.locationId);
      setStatus(teamMember.status === 'active');
      setTags(teamMember.tags);
    }
  }, [teamMember]);

  /* const handleAddTag = (tag) => {
    const newTags: any = [...tags];
    newTags.push(tag);
    setTags(newTags);
  };

  const handleRemoveTag = (index) => {
    const newTags = [...tags];
    newTags.splice(index, 1);
    setTags(newTags);
  }; */

  const handleSave = async () => {
    await dispatch(
      MembersActions.updateMember({
        teamMemberId: teamMember?.id,
        locationId,
        status,
        tagsArray: tags,
      }),
    );
    onEdit();
    onClose();
  };

  const handlePermissionDrawerClose = () => {
    setEditingPermission(false);
  };

  return (
    <>
      <MatDrawer
        open={open && !editingPermission}
        anchor="right"
        onClose={onClose}
        onCancel={onClose}
        onSubmit={handleSave}
        title={t('editTeamMember')}
        submitBtn={<Save />}
        cancelBtn={t('cancel')}
      >
        <Box width={465}>
          {teamMember && (
            <div>
              <Typography variant="h5">{teamMember.name}</Typography>
              <Typography variant="subtitle2" sx={{ marginBottom: 6.25 }}>
                {teamMember.email}
              </Typography>

              <Grid container spacing={2}>
                <Grid item md={12} xs={12} sx={{ marginBottom: 1.8 }}>
                  <MatFormLabel
                    icon={
                      <Tooltip title={t('selectLocationAddTeam') as any}>
                        <AlertIcon />
                      </Tooltip>
                    }
                  >
                    {t('Team')}
                  </MatFormLabel>
                  <MatSelect
                    value={locationId || ''}
                    onChange={(e) => setLocationId(e.target.value)}
                    options={activeLocations || []}
                    nameKey="name"
                    valueKey="id"
                    size="small"
                  />
                </Grid>

                <Grid item md={12} xs={12} sx={{ marginBottom: 1.8 }}>
                  <FormControl>
                    <MatSwitch
                      labelActive={t('active')}
                      labelInactive={t('inactive')}
                      defaultChecked={status}
                      onChange={() => setStatus(!status)}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <FormControl variant="outlined" sx={{ width: '100%' }}>
                <MatFormLabel
                  icon={
                    <Tooltip title={t('enterTagsKeywords') as any}>
                      <AlertIcon />
                    </Tooltip>
                  }
                >
                  {t('tags')}
                </MatFormLabel>
                {/* <MatChip
                  value={tags}
                  onAdd={(tag) => handleAddTag(tag)}
                  onDelete={(_, index) => handleRemoveTag(index)}
                  fullWidth={true}
                  placeholder={t('tags')}
                /> */}
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  defaultValue={tags}
                  freeSolo
                  onChange={(event, newInputValue) => {
                    setTags([...newInputValue, ...tags]);
                  }}
                  renderTags={(value: readonly string[], getTagProps) =>
                    value.map((option: string, index: number) => (
                      <Chip
                        color="primary"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Tags"
                    />
                  )}
                />
              </FormControl>

              <div style={{ height: 20 }} />
              {teamMember.role !== 'owner' ? (
                <PermissionRequired permissions={[Permissions.business.edit]}>
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => setEditingPermission(true)}
                  >
                    {t('editPermissions')}
                  </Button>
                </PermissionRequired>
              ) : null}
            </div>
          )}
        </Box>
      </MatDrawer>

      <PermissionDrawer
        open={editingPermission}
        onClose={handlePermissionDrawerClose}
        userId={teamMember && teamMember.id}
        businessId={businessId}
      />
    </>
  );
};

EditDrawer.propTypes = {
  teamMember: PropTypes.object,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

EditDrawer.defaultProps = {
  teamMember: null,
};

export default EditDrawer;
