import React from 'react';

import Colors from 'constants/colors';

import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

import PropTypes from 'prop-types';

const ImgStyled = styled('img')({
  width: '100%',
  height: 160,
  objectFit: 'contain',
  borderRadius: 2.5,
  transition: '.3s ease-in-out',
  '&:hover': {
    opacity: 0.5,
  },
});

const GiphyPhotos = ({ photos, onSelect }) => {
  const selectPhoto = (photo) => {
    onSelect({
      type: 'giphy',
      url: photo.images.original.url,
      info: {},
    });
  };

  return (
    <Box width="100%" height="100%">
      {photos === null || photos.length < 1 ? (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <ImageSearchIcon />
          <Typography variant="subtitle1">Search for gifs</Typography>
        </Box>
      ) : (
        <Box width="100%" height="100%" p={1} sx={{ overFlowY: 'auto' }}>
          <Grid container spacing={1}>
            {photos.map((photo) => (
              <Grid item xs={4}>
                <Box
                  width="100%"
                  height={160}
                  borderRadius={2.5}
                  bgcolor={Colors.lightBlueGray}
                  onClick={() => selectPhoto(photo)}
                  onKeyDown={() => selectPhoto(photo)}
                  role="button"
                  tabIndex={0}
                >
                  <ImgStyled alt="gifs" src={photo.images.original.url} />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  );
};

GiphyPhotos.propTypes = {
  photos: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
};

GiphyPhotos.defaultProps = {
  photos: null,
};

export default GiphyPhotos;
