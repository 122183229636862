import React, { useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, ButtonBase, Hidden, Theme, useMediaQuery } from '@mui/material';

import NavigationContext, { INavigationContext } from 'contexts/NavigationContext';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { subscriptionActions } from 'store/entities/Subscriptions';
import { teamsActions } from 'store/entities/Teams';
import { MembersActions } from 'store/entities/Members';

import FullWidthNavigation from './components/FullWidthNavigation';
import MiniNavigation from './components/MiniNavigation';
import NavigationDrawer from './components/NavigationDrawer';
import { RootState } from 'store';
import { templatesActions } from 'store/entities/Templates';

export type NavigationWidth = 'full' | 'minified'

export interface DrawerHandleProps {
  icon: JSX.Element;
  onClick: () => void;
}

const DrawerHandle: React.FC<DrawerHandleProps> = ({ icon, onClick }) => (
  <Box position="relative" right={4} height={1} zIndex={3000}>
    <Box
      position="absolute"
      top={0}
      bottom={0}
      width={8}
      sx={{
        cursor: 'pointer',
        '&:hover .fill': {
          transform: 'scaleX(100%)',
        },
        '&:hover .arrow': {
          transform: `translate(-50%, -50%) scale(50%)`,
        },
      }}
      onClick={onClick}
    >
      <Box
        className="fill"
        bgcolor="primary.main"
        sx={(theme) => ({
          position: 'relative',
          left: 2,
          height: 1,
          width: 4,
          transform: 'scaleX(0)',
          transition: theme.transitions.create('transform'),
        })}
      />
      <ButtonBase
        className="arrow"
        sx={(theme) => ({
          height: 44,
          width: 44,
          borderRadius: '50%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%) scale(0)`,
          bgcolor: 'primary.main',
          transition: theme.transitions.create('transform'),
        })}
        onClick={onClick}
      >
        {icon}
      </ButtonBase>
    </Box>
    <ButtonBase />
  </Box>
)

const HomeLayout = () => {
  const [_navigationWidth, setNavigationWidth] = useState<NavigationWidth>('full');
  const [navigationOpen, setNavigationOpen] = useState(false)
  const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const navigationWidth = smDown ? 'full' : _navigationWidth;
  const dispatch = useDispatch();

  const navigationContext: INavigationContext = {
    state: navigationOpen ? 'open' : 'close',
    open: () => setNavigationOpen(true),
    close: () => setNavigationOpen(false),
  }

  useEffect(() => {
      dispatch(subscriptionActions.fetchSubscriptions());
      dispatch(subscriptionActions.getPaymentMethods());
      dispatch(templatesActions.fetchTemplates());
      dispatch(teamsActions.fetchTeams({ filter: {} }));
      dispatch(MembersActions.fetchMembers({}));
      dispatch(subscriptionActions.clearStatus());
  }, [])

  return (
    <Box height="100vh" display="flex">
      <Box height={1} display="flex">
        <NavigationDrawer
          variant={smDown ? 'temporary' : 'permanent'}
          railWidth={navigationWidth === 'minified'}
          open={navigationOpen}
          onClose={() => setNavigationOpen(false)}
        >
          {
            navigationWidth === 'minified' ?
              <MiniNavigation /> :
              <FullWidthNavigation />
          }
        </NavigationDrawer>
        <Hidden smDown>
          <DrawerHandle
            icon={
              navigationWidth === 'full' ?
                <ArrowBackIcon sx={{ color: 'white' }} /> :
                <ArrowForwardIcon sx={{ color: 'white' }} />
            }
            onClick={
              () => (navigationWidth === 'full' ?
                setNavigationWidth('minified') :
                setNavigationWidth('full'))
            }
          />
        </Hidden>
      </Box>
      <Box component="main" height={1} overflow="auto" flexGrow={1}>
        <NavigationContext.Provider value={navigationContext}>
          <Outlet />
        </NavigationContext.Provider>
      </Box>
    </Box>
  )
};

export default HomeLayout;
