import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';

import ActiveIcon from 'assets/images/active.svg';
import InActiveIcon from 'assets/images/inactive.svg';
import PropTypes from 'prop-types';

const StatusCell = ({ isActive }) => (isActive ? (
    <Box display="flex" alignItems="center">
      <Box
        height={64}
        width={64}
        mr={2.5}
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${ActiveIcon})`,
          backgroundColor: Colors.active,
        }}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="center">
      <Box
        height={64}
        width={64}
        mr={2.5}
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${InActiveIcon})`,
          backgroundColor: Colors.lightBlueGray,
        }}
      />
    </Box>
  )
);

StatusCell.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default StatusCell;
