import React from 'react';

import Grid from '@mui/material//Grid';
import Typography from '@mui/material//Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import PermissionRequired from 'components/PermissionRequired';

export interface EmptyPageProps {
  title?: string;
  description?: string;
  actions?: JSX.Element[];
  permissions?: string[];
  isActive?: boolean;
  isVendor?: boolean;
}

const EmptyPage: React.FC<EmptyPageProps> = ({
  title,
  description,
  permissions,
  actions,
  isActive,
  isVendor,
}) => (
  <main>
    <Box px={8} py={0}>
      <Container maxWidth="sm">
        <Typography
          component="h1"
          variant="h2"
          align="center"
          color="textPrimary"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          {description}
        </Typography>
        {(isVendor && isActive) || !isVendor ? (
          <Box mt={4}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <PermissionRequired permissions={permissions}>
                  {actions}
                </PermissionRequired>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Container>
    </Box>
  </main>
);

export default EmptyPage;
