/* eslint-disable no-param-reassign */

import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
  createDraftSafeSelector,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Business from 'store/models/Business';
import Campaign, { CampaignAttributes, ICampaign } from 'store/models/Campaign';
import CampaignTag from 'store/models/CampaignTag';
import Coupon from 'store/models/Coupon';
import User from 'store/models/User';
import { selectCampaignsState } from 'store/selectors';
import { createDatePointer } from 'store/utils/pointers';
import { RequestStatus, Sort } from 'store/utils/types';
import { BusinessFolderUpload } from 'utils/cloudinary';

const sliceName = 'campaigns';

const getcampaignsId = (Campaign: ICampaign) =>
  `${Campaign.business_pointer?.objectId}/${Campaign?.objectId}`;

const campaignsAdapter = createEntityAdapter<ICampaign>({
  selectId: getcampaignsId,
});

export interface CampaignFilter {
  tags?: string[] | null;
  text?: string;
  teamMemberId?: string;
}

export type CampaignSort = Sort<keyof CampaignAttributes>;

export interface fetchCampaignsPayload {
  limit?: number;
  page?: number;
  filter?: any;
  filterTags?: any;
  sort?: any;
  desc?: any;
}

const fetchAllCampaigns = createAsyncThunk(
  `${sliceName}/fetchAllCampaigns`,
  async (
    { limit, page, filter, filterTags, sort, desc }: fetchCampaignsPayload,
    { getState },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Campaigns:getWithPagination', {
        limit,
        page,
        filter,
        filterTags,
        sort,
        desc,
        businessId,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const fetchCurrentCampaigns = createAsyncThunk(
  `${sliceName}/fetchCurrentCampaigns`,
  async (
    { limit, page, filter, filterTags, sort, desc }: fetchCampaignsPayload,
    { getState },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Campaigns:getWithPagination', {
        limit,
        page,
        filter,
        filterTags,
        sort,
        desc,
        type: 'active',
        businessId,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const fetchPastCampaigns = createAsyncThunk(
  `${sliceName}/fetchPastCampaigns`,
  async (
    { limit, page, filter, filterTags, sort, desc }: fetchCampaignsPayload,
    { getState },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Campaigns:getWithPagination', {
        limit,
        page,
        filter,
        filterTags,
        sort,
        desc,
        type: 'past',
        businessId,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const fetchSavedCampaigns = createAsyncThunk(
  `${sliceName}/fetchSavedCampaigns`,
  async ({ limit = 10, page = 1, filter, filterTags }:
    fetchCampaignsPayload, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Campaigns:getWithPagination', {
        limit,
        page,
        filter,
        filterTags,
        type: 'saved',
        businessId,
      })
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const fetchFutureCampaigns = createAsyncThunk(
  `${sliceName}/fetchFutureCampaigns`,
  async (
    { limit, page, filter, filterTags, sort, desc }: fetchCampaignsPayload,
    { getState },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      return await Parse.Cloud.run('Campaigns:getWithPagination', {
        limit,
        page,
        filter,
        filterTags,
        sort,
        desc,
        type: 'future',
        businessId,
      });
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface GetCampaignPayload {
  campaignId: string;
}

const getCampaign = createAsyncThunk(
  `${sliceName}/getCampaign`,
  async ({ campaignId }: GetCampaignPayload) => {
    try {
      return await Parse.Cloud.run('Campaigns:getCampaignWithTags', { campaignId })
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const getCampaignTags = createAsyncThunk(
  `${sliceName}/getCampaignTags`,
  async (props, { getState }) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      return await Parse.Cloud.run('Business:getCampaignTags', { businessId })
    } catch (error) {
      throw new Error(`Error: ${error}`);
    }
  },
);

export interface TerminateCampaignPayload {
  campaignId: string;
  reason: string;
  callback: () => void;
}

const terminateCampaign = createAsyncThunk(
  `${sliceName}/terminateCampaign`,
  async ({ campaignId, reason, callback }: TerminateCampaignPayload) => {
    const query = new Parse.Query(Campaign);
    const campaign = await query.get(campaignId);
    if (!campaign) {
      throw new Error('Campaign Not Found');
    }
    campaign.set('terminated', true);
    campaign.set('termination_reason', reason);
    await campaign.save();
    callback();
    return campaign.toJSON();
  },
);

export interface CreateGenericLinkPayload {
  campaignId: string;
  name: string;
  callback: () => void;
}

const createGenericLink = createAsyncThunk(
  `${sliceName}/createGenericLink`,
  async (
    { campaignId, name, callback }: CreateGenericLinkPayload,
    { getState, dispatch },
  ) => {
    try {
      const state = getState() as RootState;
      const user = userSelectors.getCurrentUser(state);
      const coupon = new Coupon({
        premium_pointer: Campaign.getPointer(campaignId),
        generic: name,
        agent_id: user?.objectId,
      });
      await coupon.save();
      callback();
      dispatch(getGenericLinks({ campaignId }));
      return coupon.toJSON();
    } catch (e) {
      console.error(`Error: ${e}`);
      return null;
    }
  },
);

export interface GetGenericLinkPayload {
  campaignId: string;
}

const getGenericLinks = createAsyncThunk(
  `${sliceName}/getGenericLinks`,
  async ({ campaignId }: GetGenericLinkPayload) => {
    try {
      return await Parse.Cloud.run('Campaigns:getGenericLinksAndMetrics', {
        campaignId,
        type: 'premium',
      });
    } catch (e) {
      console.error(`Error: ${e}`);
      return null;
    }
  },
);

export interface GetMapDataPayload {
  campaignId: string;
}

const getMapData = createAsyncThunk(
  `${sliceName}/getMapData`,
  async ({ campaignId }: GetMapDataPayload) => {
    try {
      return await Parse.Cloud.run('Metrics:campaignMapEngagements', {
        campaignId,
        type: 'premium',
      });
    } catch (e) {
      console.error(`Error: ${e}`);
      return null;
    }
  },
);

export interface GetBreakDownPayload {
  campaignId: string;
}

const getBreakDown = createAsyncThunk(
  `${sliceName}/getBreakDown`,
  async ({ campaignId }: GetBreakDownPayload) => {
    try {
      return await Parse.Cloud.run('Campaigns:getPremiumBreakdown', {
        campaignId,
      });
    } catch (e) {
      console.error(`Error: ${e}`);
      return null;
    }
  },
);

export interface CreateCampaignPayload {
  title: string;
  published: boolean;
  shouldShowBusinessName: boolean;
  businessUrl: string | undefined;
  description: string;
  image: any;
  info: any;
  teamMembers: string[];
  teamsIds: string[];
  startDate: string;
  endDate: string;
  id?: string | null;
  locationId?: string | null;
  advanced: any;
  callback: (campaignJson: any) => void;
}

const createCampaign = createAsyncThunk(
  `${sliceName}/createCampaign`,
  async (
    {
      title,
      description,
      image,
      published,
      info,
      teamMembers,
      teamsIds,
      businessUrl,
      startDate,
      endDate,
      id,
      advanced,
      locationId,
      shouldShowBusinessName,
      callback,
    }: CreateCampaignPayload,
    { getState },
  ) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const infoArray: any = [];
      for (let x = 0; x < info.length; x++) {
        let name = 'row_one';
        if (x === 1) {
          name = 'row_two';
        } else if (x === 2) {
          name = 'row_three';
        } else if (x === 3) {
          name = 'row_four';
        } else if (x === 4) {
          name = 'row_five';
        }
        const rowDetail: any = JSON.parse(JSON.stringify(info[x].item));
        switch (rowDetail.type) {
          case 'info':
            if (
              rowDetail.info.image !== null &&
              typeof rowDetail.info.image !== 'undefined'
            ) {
              if (rowDetail?.info?.image?.type === 'custom') {
                // eslint-disable-next-line
                const imageUrl = await BusinessFolderUpload({
                  businessId,
                  image: rowDetail.info?.image?.url,
                });
                rowDetail.info.image = {
                  type: 'custom',
                  url: imageUrl,
                };
              }
            }
            break;
          case 'image':
            if (
              rowDetail.info.image !== null &&
              typeof rowDetail.info.image !== 'undefined'
            ) {
              if (rowDetail.info.image.type === 'custom') {
                // eslint-disable-next-line
                const imageUrl = await BusinessFolderUpload({
                  businessId,
                  image: rowDetail.info?.image?.url,
                });
                rowDetail.info.image = {
                  type: 'custom',
                  url: imageUrl,
                };
              }
            }
            break;
          case 'carousel':
            if (
              rowDetail.info.images !== null &&
              typeof rowDetail.info.images !== 'undefined' &&
              rowDetail.info.images.length > 0
            ) {
              for (let y = 0; y < rowDetail.info.images.length; y++) {
                if (rowDetail.info.images[y].image.type === 'custom') {
                  // eslint-disable-next-line
                  const imageUrl = await BusinessFolderUpload({
                    businessId,
                    image: rowDetail.info.images[y].image.url,
                  });
                  rowDetail.info.images[y].image.url = imageUrl;
                }
              }
            }
            break;
          default:
            // does nothing
            break;
        }
        infoArray.push({ name, rowDetail });
      }
      const campaign = await Parse.Cloud.run(
        'Campaigns:createPremiumCampaign',
        {
          title,
          description,
          businessId,
          image,
          published,
          info: infoArray,
          teamMembers,
          teamsIds,
          startDate,
          endDate,
          businessUrl,
          id,
          advanced,
          locationId,
          shouldShowBusinessName,
        },
      );
      callback(campaign);
      return info;
    } catch (e) {
      console.error(`Error creating run again campaign: ${e}`);
      console.error(e);
      return new Error(`Error creating campaign: ${e}`);
    }
  },
);

/* export interface UpdateCampaignPayload {}

const updateCampaign = createAsyncThunk(
  `${sliceName}/updateCampaign`,
  async () => {
    const query = new Parse.Query(Campaign);
    const campaigns = await query.findAll();
    return campaigns.map((campaign) => campaign.toJSON());
  },
); */

export interface RunAgainCampaignPayload {
  campaignId: string;
  startDate: string;
  endDate: string;
  tags?: string[];
  onSuccess: () => void;
}

const runAgainCampaign = createAsyncThunk(
  `${sliceName}/runAgainCampaign`,
  async ({
    campaignId,
    startDate,
    endDate,
    tags,
    onSuccess,
  }: RunAgainCampaignPayload) => {
    try {
      const campaign = await Parse.Cloud.run('Campaign:quickRunAgain', {
        campaignId,
        type: 'premium',
        startDate,
        endDate,
        tags,
      });
      onSuccess();
      return campaign;
    } catch (e) {
      console.error(`Error creating run again campaign: ${e}`);
      return null;
    }
  },
);

export interface DeleteCampaignPayload {
  campaignId: string;
  callBack: (removed: any) => void;
}

const deleteCampaign = createAsyncThunk(
  `${sliceName}/deleteCampaign`,
  async ({ campaignId, callBack }: DeleteCampaignPayload, { dispatch }) => {
    const result = await Parse.Cloud.run('Campaigns:deleteCampaign', { campaignId })
    if (!result.success) {
      callBack({
        error: 'Cannot Remove Campaign',
      });
      return {
        error: 'Cannot Remove Campaign',
      };
    }
    dispatch(fetchSavedCampaigns({}));
    callBack({ success: true });
    return { success: true };
  },
);

export interface Campaignstate extends EntityState<ICampaign> {
  status: RequestStatus;
  error?: string;
  activeCampaigns?: any;
  pastCampaigns?: any;
  futureCampaigns?: any;
  activeLoading?: boolean;
  pastLoading?: boolean;
  futureLoading?: boolean;
  savedLoading?: boolean;
  savedCampaigns?: any;
  businessCampaignTags?: any;
  campaign?: any;
  removed?: any;
  allCampaigns?: any;
  genericLinks?: any;
  mapData?: any;
  breakdownData?: any;
  loadingBreakdown?: boolean;
}

const initialState: Campaignstate = {
  ...campaignsAdapter.getInitialState(),
  status: 'idle',
};

const campaignsSliceName = createSlice({
  name: 'campaigns',
  initialState,
  extraReducers: (builder) => {
    // Fetch Current
    builder.addCase(fetchAllCampaigns.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchAllCampaigns.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.allCampaigns = action.payload;
    });
    builder.addCase(fetchAllCampaigns.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Fetch Current
    builder.addCase(fetchCurrentCampaigns.pending, (state) => {
      state.status = 'pending';
      state.activeLoading = true;
    });
    builder.addCase(fetchCurrentCampaigns.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.activeCampaigns = action.payload;
      state.activeLoading = false;
    });
    builder.addCase(fetchCurrentCampaigns.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.activeLoading = false;
    });
    // Fetch Past
    builder.addCase(fetchPastCampaigns.pending, (state) => {
      state.status = 'pending';
      state.pastLoading = true;
    });
    builder.addCase(fetchPastCampaigns.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.pastCampaigns = action.payload;
      state.pastLoading = false;
    });
    builder.addCase(fetchPastCampaigns.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.pastLoading = false;
    });
    // Fetch Future
    builder.addCase(fetchFutureCampaigns.pending, (state) => {
      state.status = 'pending';
      state.futureLoading = true;
    });
    builder.addCase(fetchFutureCampaigns.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.futureCampaigns = action.payload;
      state.futureLoading = false;
    });
    builder.addCase(fetchFutureCampaigns.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.futureLoading = false;
    });
    // Fetch Saved
    builder.addCase(fetchSavedCampaigns.pending, (state) => {
      state.status = 'pending';
      state.savedLoading = true;
    });
    builder.addCase(fetchSavedCampaigns.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.savedCampaigns = action.payload;
      state.savedLoading = false;
    });
    builder.addCase(fetchSavedCampaigns.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.savedLoading = false;
    });
    // Get
    builder.addCase(getCampaign.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCampaign.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
    });
    builder.addCase(getCampaign.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get Generic Links
    builder.addCase(getGenericLinks.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getGenericLinks.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.genericLinks = action.payload;
    });
    builder.addCase(getGenericLinks.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get Map Data
    builder.addCase(getMapData.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getMapData.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.mapData = action.payload;
    });
    builder.addCase(getMapData.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get break Down Data
    builder.addCase(getBreakDown.pending, (state) => {
      state.status = 'pending';
      state.loadingBreakdown = true;
    });
    builder.addCase(getBreakDown.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.breakdownData = action.payload;
      state.loadingBreakdown = false;
    });
    builder.addCase(getBreakDown.rejected, (state, action) => {
      state.status = 'rejected';
      state.loadingBreakdown = false;
      state.error = action.error.message;
    });
    // Get Campaign Tags
    builder.addCase(getCampaignTags.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getCampaignTags.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.businessCampaignTags = action.payload;
    });
    builder.addCase(getCampaignTags.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createCampaign.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createCampaign.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(createCampaign.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Terminate
    builder.addCase(terminateCampaign.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(terminateCampaign.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
    });
    builder.addCase(terminateCampaign.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Run Again
    builder.addCase(runAgainCampaign.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(runAgainCampaign.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.campaign = action.payload;
    });
    builder.addCase(runAgainCampaign.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // create Generic Link
    builder.addCase(createGenericLink.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createGenericLink.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(createGenericLink.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Delete
    builder.addCase(deleteCampaign.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(deleteCampaign.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.removed = action.payload;
    });
    builder.addCase(deleteCampaign.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
      state.removed = {
        error: action.error.message,
      };
    });
  },
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
  },
});

const getCurrentCampaigns = createDraftSafeSelector(
  selectCampaignsState,
  (campaigns) =>
    campaigns?.filter(
      (Campaign) => new Date(Campaign.end_date.iso) < new Date(),
    ),
);

const getPastCampaigns = createDraftSafeSelector(
  selectCampaignsState,
  (campaigns) =>
    campaigns?.filter(
      (Campaign) => new Date(Campaign.end_date.iso) >= new Date(),
    ),
);

const campaignsSelector = {
  getCurrentCampaigns,
  getPastCampaigns,
};

export const campaignActions = {
  fetchAllCampaigns,
  fetchCurrentCampaigns,
  fetchFutureCampaigns,
  fetchPastCampaigns,
  fetchSavedCampaigns,
  createCampaign,
  getCampaign,
  getCampaignTags,
  terminateCampaign,
  createGenericLink,
  runAgainCampaign,
  deleteCampaign,
  getGenericLinks,
  getMapData,
  getBreakDown,
  ...campaignsSliceName.actions,
};

export { campaignsSelector, getcampaignsId };

export default campaignsSliceName.reducer;
