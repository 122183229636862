import React from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

import MatCheckbox from '../../Elements/MatCheckbox/MatCheckbox';

const Body = ({ teams, rowsPerPage, page, selected, onSelect }) => (
  <TableBody sx={{ borderRadius: 2.5 }}>
    {teams
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((team) => (
        <TableRow
          hover
          key={team.id}
          sx={{
            boxShadow: '0 2px 5px 0 rgba(182,194,213,0.5)',
            '&:hover': {
              boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
              cursor: 'pointer',
              borderRadius: 2.5,
            },
          }}
        >
          <TableCell
            sx={{
              width: 80,
              borderTopLeftRadius: 10,
              borderBottomLeftRadius: 10,
              backgroundColor: '#EEEEEE',
              paddingTop: 1.25,
              paddingBottom: 1.25,
              border: 'none',
            }}
            align="center"
          >
            <MatCheckbox
              checked={selected.includes(team.id)}
              onChange={() => onSelect(team.id)}
            />
          </TableCell>
          <TableCell
            sx={{
              backgroundColor: '#EEEEEE',
              paddingTop: 1.25,
              paddingBottom: 1.25,
              border: 'none',
            }}
            align="left"
          >
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle2">{team.name}</Typography>
            </Box>
          </TableCell>
        </TableRow>
      ))}
  </TableBody>
);

Body.propTypes = {
  teams: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  selected: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Body;
