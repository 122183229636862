import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createStateSyncMiddleware, initMessageListener } from 'redux-state-sync';

import rootReducer from './reducers';

const stateSyncMiddleware = createStateSyncMiddleware({});

const persistConfig = {
  key: 'speakeasypersist',
  storage,
  blacklist: [],
};

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([stateSyncMiddleware]),
})

initMessageListener(store);
const persistor = persistStore(store);

export {
  store,
  persistor,
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
