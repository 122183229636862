import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Collapse,
  FormGroup,
  LinearProgress,
  MenuItem,
  Select,
} from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from 'routes';
import { campaignActions } from 'store/entities/Campaigns';

import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import CampaignFilterDrawer from './components/CampaignFilterDrawer';
import CampaignList from './components/CampaignList/CampaignList';
import SavedList from './components/SavedList';
import useWindowSize from 'utils/hooks/useWindowSize';

const CreateCampaignButton: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="contained"
      component={Link}
      to={routes.campaigns.build()}
      startIcon={<AddIcon />}
    >
      {t('create')}
    </Button>
  );
};

const Campaigns = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [filterTags, setFilterTags] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [activeLimit, setActiveLimit] = useState(5);
  const [activePage, setActivePage] = useState(0);
  const [activeSort, setActiveSort] = useState('createdAt');
  const [activeDesc, setActiveDesc] = useState(true);
  const [pastLimit, setPastLimit] = useState(5);
  const [pastPage, setPastPage] = useState(0);
  const [pastSort, setPastSort] = useState('createdAt');
  const [pastDesc, setPastDesc] = useState(true);
  const [futureLimit, setFutureLimit] = useState(5);
  const [savedLimit, setSavedLimit] = useState(5);
  const [savedPage, setSavedPage] = useState(0);
  const [futurePage, setFuturePage] = useState(0);
  const [futureSort, setFutureSort] = useState('createdAt');
  const [futureDesc, setFutureDesc] = useState(true);
  const [searchType, setSearchType] = useState('title');
  const [filter, setFilter] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const {
    activeCampaigns,
    pastCampaigns,
    futureCampaigns,
    activeLoading,
    pastLoading,
    futureLoading,
    savedLoading,
    savedCampaigns,
    businessCampaignTags,
  } = useSelector((state: any) => state.campaigns);

  useEffect(() => {
    dispatch(campaignActions.getCampaignTags());
    dispatch(campaignActions.clearStatus());
  }, []);

  const handleSearch = (event) => {
    if (!event.target.value) {
      const filterObj = {};
      filterObj[searchType] = event.target.value;
      setFilter(filterObj);
    }
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    setSearchValue('');
    setFilter({});
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      const filterObj = {};
      filterObj[searchType] = searchValue;
      setFilter(filterObj);
    }
  };

  const handleActiveSettings = ({ limit, page, sort, desc }) => {
    if (limit !== activeLimit) {
      setActiveLimit(limit);
      if (
        parseInt((activeCampaigns.total / activeCampaigns.limit) as any, 10) < 1
      ) {
        setActivePage(0);
      }
    }
    if (page !== activePage) {
      setActivePage(page);
    }
    if (sort !== activeSort) {
      setActiveSort(sort);
    }
    if (desc !== activeDesc) {
      setActiveDesc(desc);
    }
  };

  const handlePastSettings = ({ limit, page, sort, desc }) => {
    if (limit !== pastLimit) {
      setPastLimit(limit);
      if (
        parseInt((pastCampaigns.total / pastCampaigns.limit) as any, 10) < 1
      ) {
        setPastPage(0);
      }
    }
    if (page !== pastPage) {
      setPastPage(page);
    }
    if (sort !== pastSort) {
      setPastSort(sort);
    }
    if (desc !== pastDesc) {
      setPastDesc(desc);
    }
  };

  const handleFutureSettings = ({ limit, page, sort, desc }) => {
    if (limit !== futureLimit) {
      setFutureLimit(limit);
      if (
        parseInt((futureCampaigns.total / futureCampaigns.limit) as any, 10) < 1
      ) {
        setFuturePage(0);
      }
    }
    if (page !== futurePage) {
      setFuturePage(page);
    }
    if (sort !== futureSort) {
      setFutureSort(sort);
    }
    if (desc !== futureDesc) {
      setFutureDesc(desc);
    }
  };

  const handleSavedSettings = ({ limit, page }) => {
    if (limit !== savedLimit) {
      setSavedLimit(limit);
      if (
        parseInt((savedCampaigns.total / savedCampaigns.limit) as any, 10) < 1
      ) {
        setSavedPage(0);
      }
    }
    if (page !== futurePage) {
      setSavedPage(page);
    }
  };

  const onApplyFilter = () => {
    dispatch(
      campaignActions.fetchCurrentCampaigns({
        limit: activeLimit,
        page: 1 + activePage,
        sort: activeSort,
        desc: activeDesc,
        filter,
        filterTags,
      }),
    );
    dispatch(
      campaignActions.fetchPastCampaigns({
        limit: pastLimit,
        page: 1 + pastPage,
        sort: pastSort,
        desc: pastDesc,
        filter,
        filterTags,
      }),
    );
    dispatch(
      campaignActions.fetchFutureCampaigns({
        limit: futureLimit,
        page: 1 + futurePage,
        sort: futureSort,
        desc: futureDesc,
        filter,
        filterTags,
      }),
    );

    dispatch(
      campaignActions.fetchSavedCampaigns({
        limit: savedLimit,
        page: 1 + savedPage,
        filter,
        filterTags,
      }),
    );
  };

  /* useEffect(() => {
    onApplyFilter();
  }, [filter]); */

  useEffect(() => {
    if (activeLoading || pastLoading || futureLoading || savedLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [activeLoading, pastLoading, futureLoading, savedLoading]);

  useEffect(() => {
    setIsLoading(activeLoading && pastLoading && futureLoading && savedLoading);
  }, [activeLoading, pastLoading, futureLoading, savedLoading]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchCurrentCampaigns({
        limit: activeLimit,
        page: 1 + activePage,
        sort: activeSort,
        desc: activeDesc,
        filter,
        filterTags,
      }),
    );
  }, [activeLimit, activePage, activeSort, activeDesc, filterTags, filter]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchSavedCampaigns({
        limit: savedLimit,
        page: 1 + savedPage,
        filter,
        filterTags,
      }),
    );
  }, [savedLimit, savedPage, filterTags, filter]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchSavedCampaigns({
        limit: savedLimit,
        page: 1 + savedPage,
        filter,
        filterTags,
      }),
    );
  }, [savedLimit, savedPage, filterTags]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchPastCampaigns({
        limit: pastLimit,
        page: 1 + pastPage,
        sort: pastSort,
        desc: pastDesc,
        filter,
        filterTags,
      }),
    );
  }, [pastLimit, pastPage, pastSort, pastDesc, filterTags, filter]);

  useEffect(() => {
    dispatch(
      campaignActions.fetchFutureCampaigns({
        limit: futureLimit,
        page: 1 + futurePage,
        sort: futureSort,
        desc: futureDesc,
        filter,
        filterTags,
      }),
    );
  }, [futureLimit, futurePage, futureSort, futureDesc, filterTags, filter]);

  const showActive = () => {
    if (
      activeCampaigns !== null &&
      typeof activeCampaigns !== 'undefined' &&
      activeCampaigns.data !== null &&
      typeof activeCampaigns.data !== 'undefined' &&
      activeCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const showPast = () => {
    if (
      pastCampaigns !== null &&
      typeof pastCampaigns !== 'undefined' &&
      pastCampaigns.data !== null &&
      typeof pastCampaigns.data !== 'undefined' &&
      pastCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const showFuture = () => {
    if (
      futureCampaigns !== null &&
      typeof futureCampaigns !== 'undefined' &&
      futureCampaigns.data !== null &&
      typeof futureCampaigns.data !== 'undefined' &&
      futureCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  // const isLoading = () => {
  //   if (
  //     typeof pastCampaigns !== 'undefined' ||
  //     typeof futureCampaigns !== 'undefined' ||
  //     typeof activeCampaigns !== 'undefined' ||
  //     typeof savedCampaigns !== 'undefined'
  //   ) {
  //     return true;
  //   }
  //   return false;
  // };

  const checkEmptyPage = () => {
    if (
      futureCampaigns?.data?.length > 0 ||
      pastCampaigns?.data?.length > 0 ||
      activeCampaigns?.data?.length > 0 ||
      savedCampaigns?.data?.length > 0
    ) {
      return true;
    }
    return false;
  };

  const onRefresh = () => {
    setActivePage(0);
    dispatch(
      campaignActions.fetchCurrentCampaigns({
        limit: activeLimit,
        page: 1 + 0,
        sort: activeSort,
        desc: activeDesc,
        filter,
        filterTags,
      }),
    );
  };

  const handleFilterSubmit = (selectedTags = null) => {
    setFilterTags(selectedTags);
  };

  const handleOptionChange = (event) => {
    setSearchType(event.target.value as string);
  };

  return (
    <Scaffold
      HeaderProps={{
        title: t('campaigns'),
        actions: [width < 660 ? <></> : <CreateCampaignButton />],
      }}
    >
      {isLoading ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : (
        <HomeContainer>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9} lg={10} xl={10}>
              <FormGroup>
                <MatInput
                  variant="outlined"
                  placeholder={t('searchCampaigns')}
                  onChange={handleSearch}
                  onKeyPress={handleKeyPress}
                  sx={{
                    borderRadius: 1,
                    backgroundColor: Colors.white,
                    [`& fieldset`]: {
                      borderColor: Colors.primary,
                      opacity: '.5',
                    },
                  }}
                  value={searchValue}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        {searchValue.length > 0 ? (
                          <ClearIcon onClick={clearSearch} />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                    startAdornment: (
                      <Select
                        value={searchType}
                        onChange={handleOptionChange}
                        sx={{
                          backgroundColor: Colors.white,
                          color: Colors.black,
                          marginRight: '1rem',
                        }}
                        displayEmpty
                        variant="standard"
                      >
                        <MenuItem value="title">{t('title')}</MenuItem>
                        <MenuItem value="description">
                          {t('description')}
                        </MenuItem>
                        <MenuItem value="tag">{t('tag')}</MenuItem>
                      </Select>
                    ),
                    inputProps: {
                      style: {
                        // padding: '10px', // add padding to the placeholder text
                      },
                    },
                  }}
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} md={3} lg={2} xl={2}>
              <Button
                sx={{
                  backgroundColor: Colors.white,
                  color: Colors.black,
                  height: 50,
                  width: '100%',
                  borderRadius: 1,
                  boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    backgroundColor: Colors.lightGray,
                  },
                }}
                variant="contained"
                onClick={() => setIsFiltersOpen(true)}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <SettingsIcon />
                  <Typography
                    style={{ flexGrow: 1, marginRight: 10 }}
                    variant="subtitle1"
                  >
                    {t('filter')}
                  </Typography>
                </div>
              </Button>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: 3.5 }}>
            <Grid item md={12} sx={{ flex: 1 }}>
              {!checkEmptyPage() && !(searchValue.length > 0 || filterTags) && (
                <EmptyPage
                  actions={[<CreateCampaignButton />]}
                  title={t('campaigns')}
                  description={t('emptyCampaignDescription')}
                  permissions={[Permissions.campaign.create]}
                />
              )}
              {!checkEmptyPage() && (searchValue.length > 0 || filterTags) && (
                <EmptyPage
                  actions={[<></>]}
                  description={t('noCampaignsFromSearch')}
                  title={' '}
                  permissions={[Permissions.campaign.create]}
                />
              )}
              {showActive() && (
                <div>
                  <Typography variant="h6">{t('active')}</Typography>
                  <CampaignList
                    campaigns={activeCampaigns}
                    loading={activeLoading}
                    sortBy={activeSort}
                    desc={activeDesc}
                    handleTableSettings={handleActiveSettings}
                    reloadCampaigns={onRefresh}
                  />
                </div>
              )}
              {showFuture() && (
                <div>
                  <Typography variant="h6">{t('upcoming')}</Typography>
                  <CampaignList
                    campaigns={futureCampaigns}
                    loading={futureLoading}
                    sortBy={futureSort}
                    desc={futureDesc}
                    handleTableSettings={handleFutureSettings}
                    reloadCampaigns={onRefresh}
                  />
                </div>
              )}

              {showPast() && (
                <div>
                  <Typography variant="h6">{t('completed')}</Typography>
                  <CampaignList
                    campaigns={pastCampaigns}
                    loading={pastLoading}
                    sortBy={pastSort}
                    desc={pastDesc}
                    handleTableSettings={handlePastSettings}
                    showRunAgain={true}
                    reloadCampaigns={onRefresh}
                  />
                </div>
              )}
              {savedCampaigns &&
                savedCampaigns.data &&
                savedCampaigns.data.length > 0 && (
                  <div>
                    <Typography variant="h6">{t('saved')}</Typography>
                    <SavedList
                      campaigns={savedCampaigns}
                      loading={savedLoading}
                      handleTableSettings={handleSavedSettings}
                    />
                  </div>
                )}
            </Grid>
          </Grid>
        </HomeContainer>
      )}
      <CampaignFilterDrawer
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={handleFilterSubmit}
        options={businessCampaignTags}
      />
    </Scaffold>
  );
};

export default Campaigns;
