import React, { useEffect, useState } from 'react';

import DateRangeIcon from '@mui/icons-material/DateRange';
import { Autocomplete, Box, Chip } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { membersSelectors } from 'store/entities/Members';
import { teamsActions, teamsSelectors } from 'store/entities/Teams';

import DateRangeDrawer from 'components/DateRangeDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import RichTextEditor from 'components/RichTextEditor';
import TeamMemberSelectTable from 'components/TeamMemberSelectTable';
import TeamsSelectTable from 'components/TeamsSelectTable';
import TemplatesSelectable from 'components/TemplatesSelectable';

const BasicInfo = ({
  range,
  teamMembers,
  teams,
  handleRange,
  handleTeamMembers,
  handleTeams,
  handleLocationId,
  age,
  tags,
  facebookPixel,
  targetAudience,
  conditions,
  handleAge,
  handleTags,
  handleConditions,
  handlePixel,
  handleAudience,
  title,
  handleTitle,
  locationId,
  handleSelectTemplate,
  vendor,
  recipient,
  setRecipient,
  showBusiness,
  setShowBusiness,
  businessUrl,
  handleBusinessUrl,
}) => {
  const [showDateDrawer, setShowDateDrawer] = useState(false);
  const [members, setMembers] = useState<any[]>([]);
  const [showMembers, setShowMembers] = useState(false);
  const [showTeams, setShowTeams] = useState(false);

  const dispatch = useDispatch();
  const activeLocations = useSelector(teamsSelectors.getActiveTeams);
  const activeTeamMembers = useSelector(membersSelectors.getActiveMembers);
  const { templates } = useSelector((state: any) => state.templates);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    if (locationId !== 'all' && locationId) {
      setMembers(
        activeTeamMembers.filter((el) => el.locationId === locationId),
      );
    } else {
      setMembers(activeTeamMembers);
    }
  }, [activeTeamMembers]);

  useEffect(() => {
    switch (recipient) {
      case 'members':
        setShowTeams(false);
        setShowMembers(true);
        break;
      case 'teams':
        setShowTeams(true);
        setShowMembers(false);
        break;
      case 'teamsmembers':
        setShowMembers(true);
        setShowTeams(true);
        break;
      default:
        setShowMembers(false);
        setShowTeams(false);
        break;
    }
  }, [recipient]);

  const handleSelectLocation = (event) => {
    handleLocationId(event.target.value);
    if (event.target.value !== 'all') {
      setMembers(
        activeTeamMembers.filter((el) => el.locationId === event.target.value),
      );
    } else {
      setMembers(activeTeamMembers);
    }
  };

  const handleShowBusiness = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowBusiness(event.target.checked);
  };

  const handleDateRange = (value) => {
    handleRange(value);
    setShowDateDrawer(false);
  };

  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '100%',
      }}
    >
      <FormControl
        variant="outlined"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
        margin="dense"
      >
        <MatFormLabel
          icon={
            <Tooltip title="Enter title to be associated with this campaign">
              <AlertIcon />
            </Tooltip>
          }
        >
          Campaign Title
        </MatFormLabel>
        <MatInput
          value={title}
          variant="outlined"
          label="Campaign Title"
          onChange={handleTitle}
          size="small"
        />
      </FormControl>
      <FormControl
        variant="outlined"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
        margin="dense"
      >
        <MatFormLabel
          icon={
            <Tooltip title="Enter business website to be associated with this campaign">
              <AlertIcon />
            </Tooltip>
          }
        >
          Business Website
        </MatFormLabel>
        <MatInput
          value={businessUrl}
          variant="outlined"
          label="Business Website"
          onChange={handleBusinessUrl}
          size="small"
        />
      </FormControl>
      <FormControlLabel
        control={
          <Checkbox checked={showBusiness} onChange={handleShowBusiness} />
        }
        label="Show Business Name"
      />
      <Box
        onClick={() => setShowDateDrawer(true)}
        onKeyDown={() => setShowDateDrawer(true)}
        tabIndex={0}
        role="button"
        sx={{
          width: '100%',
          cursor: 'pointer',
          marginTop: 3,
          marginBottom: 2.75,
        }}
      >
        <MatFormLabel
          icon={
            <Tooltip title="Set the duration that this campaign will run for">
              <AlertIcon />
            </Tooltip>
          }
        >
          Duration
        </MatFormLabel>
        <Box
          sx={{
            width: '100%',
            paddingTop: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <DateRangeIcon style={{ marginRight: 8 }} />
          <Typography variant="subtitle1">
            {`${range[0].format('MMM D, YYYY')} - ${range[1].format(
              'MMM D, YYYY',
            )}`}
          </Typography>
        </Box>
      </Box>
      {/* <MatFormLabel
        icon={
          <Tooltip title="Select which location to add the team member to">
            <AlertIcon />
          </Tooltip>
        }
      >
        Choose Recipients
      </MatFormLabel>
      <MatSelect
        value={recipient}
        onChange={(event) => setRecipient(event.target.value)}
        options={[
          {
            name: 'None',
            id: 'all',
          },
          {
            name: 'Select Members',
            id: 'members',
          },
          {
            name: 'Select Teams',
            id: 'teams',
          },
          {
            name: 'Select members and teams',
            id: 'teamsmembers',
          },
        ]}
        nameKey="name"
        valueKey="id"
        size="small"
      />
      {showMembers && (
        <>
          <MatFormLabel
            icon={
              <Tooltip title="Select the team members to add to this card">
                <AlertIcon />
              </Tooltip>
            }
          >
            Select Team Members
          </MatFormLabel>
          <TeamMemberSelectTable
            selected={teamMembers}
            activeTeamMembers={members}
            handleTeamMemberSelect={(members) => handleTeamMembers(members)}
          />
        </>
      )}
      {showTeams && (
        <>
          <MatFormLabel
            icon={
              <Tooltip title="Select the teams to add to this card">
                <AlertIcon />
              </Tooltip>
            }
          >
            Select Teams
          </MatFormLabel>
          <TeamsSelectTable
            selected={teams}
            handleTeamSelect={(teams) => handleTeams(teams)}
          />
        </>
      )} */}
      {/* <MatFormLabel
        icon={
          <Tooltip title="Choose a Template">
            <AlertIcon />
          </Tooltip>
        }
      >
        Templates (Not Required)
      </MatFormLabel>
      <TemplatesSelectable
        activeTemplates={templates}
        handleSelectTemplate={handleSelectTemplate}
      /> */}
      {/* <Typography
        sx={{
          marginTop: 3,
        }}
        variant="h6"
      >
        Advanced Options (Not Required)
      </Typography> */}
      <FormControl
        variant="outlined"
        margin="dense"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
      >
        <MatFormLabel
          icon={
            <Tooltip title="Enter specific details or limitations placed on this campaign">
              <AlertIcon />
            </Tooltip>
          }
        >
          Conditions
        </MatFormLabel>
        <RichTextEditor
          defaultValue={conditions}
          placeholder="Enter conditions"
          onChange={handleConditions}
          disabled={vendor !== null}
          size="small"
        />
      </FormControl>
      <FormControl
        variant="outlined"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
        margin="dense"
      >
        <MatFormLabel
          icon={
            <Tooltip title="Enter the age range to be associated with this campaign">
              <AlertIcon />
            </Tooltip>
          }
        >
          Age Restriction
        </MatFormLabel>
        <MatSelect
          size="small"
          nameKey="title"
          valueKey="value"
          value={age}
          onChange={(e) => handleAge(e.target.value)}
          options={[
            { value: 'all', title: 'All Ages' },
            { value: '13', title: '13+' },
            { value: '18', title: '18+' },
            { value: '21', title: '21+' },
            { value: '55', title: '55+' },
          ]}
        />
      </FormControl>
      {/* <FormControl
        variant="outlined"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
        margin="dense"
      >
        <MatFormLabel
          icon={
            <Tooltip title="Add a Facebook Pixel for further data tracking">
              <AlertIcon />
            </Tooltip>
          }
        >
          Facebook Tracking Pixel
        </MatFormLabel>
        <MatInput
          value={facebookPixel}
          size="small"
          variant="outlined"
          label="Facebook Tracking Pixel"
          placeholder=""
          onChange={(e) => handlePixel(e.target.value)}
        />
      </FormControl> */}
      <FormControl
        variant="outlined"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
        margin="dense"
      >
        <MatFormLabel
          icon={
            <Tooltip title="Add a message that will be displayed to your Team Members before sending this campaign">
              <AlertIcon />
            </Tooltip>
          }
        >
          Target Audience
        </MatFormLabel>
        <MatInput
          value={targetAudience}
          variant="outlined"
          label="Target Audience"
          onChange={(e) => handleAudience(e.target.value)}
          multiline={true}
          rows={2}
        />
      </FormControl>
      <FormControl
        variant="outlined"
        sx={{
          marginBottom: 2,
          width: '100%',
        }}
        margin="dense"
      >
        <MatFormLabel
          icon={
            <Tooltip title="Enter tags or keywords to be associated with this campaign">
              <AlertIcon />
            </Tooltip>
          }
        >
          Tags
        </MatFormLabel>
        {/* <MatChip
          value={tags}
          onAdd={(chips) => handleAddTag(chips)}
          onDelete={(chip, index) => handleRemoveTag(chip, index)}
          fullWidth={true}
          placeholder="Tags"
        /> */}
        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          value={tags}
          freeSolo
          onChange={(event, newInputValue) => {
            handleTags(newInputValue);
          }}
          renderTags={(value: readonly string[], getTagProps) =>
            value.map((option: string, index: number) => (
              <Chip
                color="primary"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <MatInput {...params} variant="outlined" placeholder="Tags" />
          )}
        />
      </FormControl>
      <DateRangeDrawer
        open={showDateDrawer}
        onClose={() => setShowDateDrawer(false)}
        onSave={handleDateRange}
        startDate={range[0]}
        endDate={range[1]}
        allowTime={true}
      />
    </Box>
  );
};

BasicInfo.propTypes = {
  range: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleRange: PropTypes.func.isRequired,
  handleTeamMembers: PropTypes.func.isRequired,
  handleSelectTemplate: PropTypes.func.isRequired,
  handleLocationId: PropTypes.func.isRequired,
  handleTeams: PropTypes.func.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.string),
  teams: PropTypes.arrayOf(PropTypes.string),
  age: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  facebookPixel: PropTypes.string,
  targetAudience: PropTypes.string,
  conditions: PropTypes.object,
  handleAge: PropTypes.func.isRequired,
  title: PropTypes.string,
  businessUrl: PropTypes.string,
  vendor: PropTypes.any.isRequired,
  locationId: PropTypes.string,
  handleTitle: PropTypes.func.isRequired,
  handleBusinessUrl: PropTypes.func.isRequired,
  handleTags: PropTypes.func.isRequired,
  handleConditions: PropTypes.func.isRequired,
  handlePixel: PropTypes.func.isRequired,
  handleAudience: PropTypes.func.isRequired,
  recipient: PropTypes.string.isRequired,
  setRecipient: PropTypes.func.isRequired,
  showBusiness: PropTypes.bool.isRequired,
  setShowBusiness: PropTypes.func.isRequired,
};

BasicInfo.defaultProps = {
  teamMembers: [],
  tags: [],
  facebookPixel: '',
  targetAudience: '',
  locationId: 'all',
  conditions: null,
};

export default BasicInfo;
