import React, { useEffect, useState } from 'react';

import { tableStyles } from 'constants/styles';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LinkIcon from '@mui/icons-material/Link';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { campaignActions } from 'store/entities/Campaigns';

import HeaderCell from 'components/HeaderCell';
import CreateLinkDrawer from '../CreateLinkDrawer';
import QRDrawer from '../QRDrawer';

import Row from './Row';
import { TableContainer } from '@mui/material';

const columns = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Link',
    key: 'link',
  },
  {
    name: 'Engagements',
    key: 'engagements',
    hideInMobile: true,
  },
  {
    name: 'Views',
    key: 'impressions',
    hideInMobile: true,
  },
  {
    name: 'Actions',
    key: 'conversions',
    hideInMobile: true,
  },
];

const useStyles: any = makeStyles({
  ...tableStyles,
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  empty: {
    display: 'flex',
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  icon: {
    width: 48,
    height: 48,
  },
  spinner: {
    width: 48,
    height: 48,
  },
  headerRow: {
    display: 'flex',
    width: '100%',
    height: 32,
  },
  grow: {
    flexGrow: 1,
  },
  addIcon: {
    cursor: 'pointer',
  },
});

const GenericLinksTable = ({ campaignId, campaignTitle, type, allowNewLinks }) => {
  const classes: any = useStyles();
  const dispatch = useDispatch();
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [showCreate, setShowCreate] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [selectedLink, setSelectedLink] = useState(null);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [loadingGenericLinks, setLoadingGenericLinks] = useState(false);

  const { genericLinks, status } = useSelector((state: any) => state.campaigns);

  useEffect(() => {
    switch (status) {
      case 'pending':
        setLoadingGenericLinks(true);
        break;
      case 'fulfilled':
        setLoadingGenericLinks(false);
        dispatch(campaignActions.clearStatus());
        break;
      default:
        setLoadingGenericLinks(false);
        break;
    }
  }, [status]);

  const desc = (a, b, oBy) => {
    const aInfo = a[oBy];
    const bInfo = b[oBy];

    if (bInfo < aInfo) {
      return -1;
    }
    if (bInfo > aInfo) {
      return 1;
    }
    return 0;
  };

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, val) => {
    setPage(val);
  };

  const handleRowClick = (link, campaignTitle) => {
    setSelectedLink(link);
    setSelectedCampaign(campaignTitle);
    setShowQr(true);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return o;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (o, oBy) => {
    if (o === 'desc') {
      return (a, b) => desc(a, b, oBy);
    }
    return (a, b) => -desc(a, b, oBy);
  };

  const copyLink = (link) => {
    const ele = document.createElement('textarea');
    ele.value = link;
    ele.setAttribute('readonly', '');
    ele.style.position = 'absolute';
    ele.style.left = '-9999px';
    document.body.appendChild(ele);
    ele.select();
    document.execCommand('copy');
    document.body.removeChild(ele);
  };

  if (loadingGenericLinks) {
    return (
      <div className={classes.root}>
        <div className={classes.empty}>
          <CircularProgress className={classes.spinner} />
          <Typography variant="subtitle2">Loading Links</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerRow}>
        <Typography variant="h6" className={classes.grow}>
          Links
        </Typography>
        {allowNewLinks && (
          <AddCircleOutlineIcon
            className={classes.addIcon}
            onClick={() => setShowCreate(true)}
          />
        )}
      </div>
      {genericLinks && genericLinks.length > 0 ? (
        <TableContainer>
          <Table className={classes.table}>
            <TableHead className={classes.header}>
              <TableRow>
                {columns.map((column) => (
                  <HeaderCell
                    key={column.key}
                    column={column}
                    sx={{ border: 'none', padding: 0, minHeight: 64 }}
                    orderBy={orderBy}
                    order={order}
                    onCreateSortHandler={createSortHandler}
                    hideInMobile={column.hideInMobile}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody className={classes.tBody}>
              {stableSort(genericLinks, getSorting(order, orderBy))
                .slice(page * 5, page * 5 + 5)
                .map((link) => {
                  let updatedName = '';
                  const linkName = link.name;
                  let count = 0;

                  for (let i = 0; i < linkName.length; i++) {
                    if (count < 7) {
                      updatedName += linkName[i];
                      if (linkName[i] !== ' ') {
                        count += 1;
                      }
                    }
                  }

                  if (count >= 7) {
                    updatedName += '...';
                  }

                  return (
                    <Row
                      name={updatedName}
                      originalName={link.name}
                      link={link.link}
                      engagements={link.engagements}
                      impressions={link.impressions}
                      conversions={link.conversions}
                      onClick={() => handleRowClick(link, campaignTitle)}
                    />
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={genericLinks.length}
            rowsPerPage={5}
            labelDisplayedRows={() =>
              `${page + 1}-${Math.floor(genericLinks.length / 5) + 1}`
            }
            page={page}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      ) : (
        <div className={classes.empty}>
          <LinkIcon className={classes.icon} />
          <Typography variant="subtitle1">
            Links
          </Typography>
        </div>
      )}
      <CreateLinkDrawer
        open={showCreate}
        onClose={() => setShowCreate(false)}
        campaignId={campaignId}
        type={type}
      />
      <QRDrawer
        open={showQr}
        campaignTitle={selectedCampaign}
        onClose={() => setShowQr(false)}
        genericLink={selectedLink}
        copyLink={(val) => copyLink(val)}
      />
    </div>
  );
};

GenericLinksTable.propTypes = {
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  allowNewLinks: PropTypes.bool,
};

GenericLinksTable.defaultProps = {
  allowNewLinks: true,
};

export default GenericLinksTable;
