import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';

const SelectedCount = ({ count, loading }) => {
  const renderLoading = () => (
    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="end">
      <Typography variant="subtitle2">Refreshing</Typography>
      <CircularProgress
        sx={{
          marginLeft: 8,
        }}
        size={16}
      />
    </Box>
  );

  if (count > 0) {
    return (
      <Box
        pt={1.5}
        pb={1.5}
        width="100%"
        display="flex"
        borderBottom={0.5}
        borderColor={Colors.lightBlueGray}
        alignItems="center"
      >
        <Box
          borderRadius={25}
          bgcolor={Colors.primary}
          color={Colors.white}
          width={30}
          height={30}
          mr={1}
          fontSize={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          {count > 99 ? '99+' : count}
        </Box>
        <Typography variant="subtitle2">Selected team members</Typography>
        {loading && renderLoading()}
      </Box>
    );
  }

  return (
    <Box
      pt={1.5}
      pb={1.5}
      width="100%"
      display="flex"
      borderBottom={0.5}
      borderColor={Colors.lightBlueGray}
      alignItems="center"
    >
      <Typography variant="subtitle2">No team members selected</Typography>
      {loading && renderLoading()}
    </Box>
  );
};

SelectedCount.propTypes = {
  count: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

SelectedCount.defaultProps = {
  loading: false,
};

export default SelectedCount;
