import React from 'react';

import PropTypes from 'prop-types';

import ButtonRow from './ButtonRow';
import FormCard from './FormCard';
import ImageCard from './ImageCard';
import ImageCarousel from './ImageCarousel';
import InfoCard from './InfoCard';
import MailChimpCard from './MailChimpCard';
import PollCard from './PollCard';
import SocialBar from './SocialBar';
import VideoCard from './VideoCard';

const CardDisplay = ({
  item,
  cardId,
  index,
  activeRow,
  handleActiveRow,
  setValue,
  onUpdate,
  setActiveImage,
}) => {
  switch (item.type) {
    case 'info':
      return (
        <InfoCard
          image={item.info.image}
          item={item.info}
          title={item.info.title}
          setValue={setValue}
          description={item.info.description}
          ctaOne={item.info.ctaOne}
          ctaTwo={item.info.ctaTwo}
          styling={item.info.styling}
          active={activeRow === index}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
          onUpdate={onUpdate}
        />
      );
    case 'image':
      return (
        <ImageCard
          image={item.info.image}
          title={item.info.title}
          setValue={setValue}
          cta={item.info.cta}
          styling={item.info.styling}
          active={activeRow === index}
          item={item.info}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
          onUpdate={onUpdate}
        />
      );
    case 'video':
      return (
        <VideoCard
          type={item.info.type}
          code={item.info.code}
          title={item.info.title}
          active={activeRow === index}
          styling={item.info.styling}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
        />
      );
    case 'button':
      return (
        <ButtonRow
          active={activeRow === index}
          color={item.info.color}
          item={item.info}
          styling={item.info.styling}
          setValue={setValue}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
        />
      );
    case 'social':
      return (
        <SocialBar
          facebook={item.info.facebook}
          twitter={item.info.twitter}
          linkedin={item.info.linkedin}
          instagram={item.info.instagram}
          active={activeRow === index}
          styling={item.info.styling}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
        />
      );
    case 'form':
      return (
        <FormCard
          item={item.info}
          active={activeRow === index}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
        />
      );
    case 'poll':
      return (
        <PollCard
          item={item.info}
          active={activeRow === index}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
        />
      );
    case 'carousel':
      return (
        <ImageCarousel
          item={item.info}
          setValue={setValue}
          onUpdate={onUpdate}
          active={activeRow === index}
          styling={item.info.styling}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
          setActiveImage={setActiveImage}
        />
      );
    case 'mailchimp':
      return (
        <MailChimpCard
          title={item.info.title}
          active={activeRow === index}
          styling={item.info.styling}
          onClick={() => {
            handleActiveRow(index);
          }}
          cardId={cardId}
        />
      );
    default:
      return <div />;
  }
};

CardDisplay.propTypes = {
  item: PropTypes.object.isRequired,
  cardId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  activeRow: PropTypes.number.isRequired,
  handleActiveRow: PropTypes.func.isRequired,
  setValue: PropTypes.func,
  setActiveImage: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default CardDisplay;
