export default {
  primary: '#2085FF',
  primaryDark: '#0059cb',
  primaryLight: '#70b4ff',
  primaryBlack: '#1C2228',
  background: '#EFF3F7',
  font: '#4D5A77',
  white: '#FFFFFF',
  black: '#212121',
  error: '#F44336',
  gray: '#999999',
  lightGray: '#EEEEEE',
  lightBlueGray: '#CFD9E4',
  lightBlue: '#ECF5FF',
  lightGreen: '#E8F5E9',
  lightRed: '#ffebee',
  success: '#4caf50',
  active: '#1DE6A8',
  iPhone: '#64B5F6',
  android: '#AED581',
  other: '#E0E0E0',
  darkGray: '#263238',
  inActiveGray: '#8a9db3',
  yellow: '#F9A825',
  teal: '#008080',
  tealLight: '#00a7a7',
  violet: '#8675FF',
};
