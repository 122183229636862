import React, { useState, useEffect } from 'react';

import { Box } from '@mui/material';

import Moment from 'moment';

import MatDrawer from '../Elements/MatDrawer/MatDrawer';

import Calendar from './components/Calendar';
import DateDisplay from './components/DateDisplay';
import Time from './components/Time';
import { Save } from '@mui/icons-material';

export interface DateRangeDrawerProps {
  open: boolean;
  onClose: () => any;
  onSave: (a: any) => any;
  allowTime?: boolean;
  allowPastDates?: boolean;
  startDate?: Moment.Moment;
  endDate?: Moment.Moment;
}

const DateRangeDrawer: React.FC<DateRangeDrawerProps> = ({
  open,
  onClose,
  onSave,
  startDate,
  endDate,
  allowTime,
  allowPastDates,
}) => {
  const [range, setRange] = useState<any[]>([
    Moment(),
    Moment().add(5, 'days'),
  ]);
  const [handleStartDate, setHandleStartDate] = useState(true);

  useEffect(() => {
    const newRange = [
      startDate || Moment(),
      endDate || Moment().add(5, 'days'),
    ];
    setRange(newRange);
  }, [startDate, endDate]);

  const onDateSelect = (date): any => {
    const newRange = [...range];
    if (handleStartDate) {
      newRange[0] = date;
      if (date.isAfter(range[1], 'day')) {
        newRange[1] = null;
      }
      setHandleStartDate(false);
    } else if (date.isAfter(range[0], 'day') || date.isSame(range[0], 'day')) {
      newRange[1] = date;
      setHandleStartDate(true);
    }

    setRange(newRange);
  };

  const updateStartDate = (date): any => {
    const newRange = [...range];
    newRange[0] = date;
    setRange(newRange);
  };

  const updateEndDate = (date): any => {
    const newRange = [...range];
    newRange[1] = date;
    setRange(newRange);
  };

  const handleSave = () => {
    if (range[1] !== null) {
      if (allowTime && range[0]?.isBefore(range[1])) {
        onSave(range);
      } else if (!allowTime) {
        onSave(range);
      }
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSave}
      title="Select Date Range"
      submitBtn={<Save />}
      cancelBtn="Cancel"
    >
      <Box maxWidth={450} display="flex" flexDirection="column" alignItems="center">
        <Calendar
          range={range}
          onDateSelect={onDateSelect}
          allowPastDates={allowPastDates}
        />
        {allowTime && (
          <Time
            startTime={range[0]}
            endTime={range[1]}
            setStartTime={updateStartDate}
            setEndTime={updateEndDate}
          />
        )}
        <DateDisplay
          startDate={range[0]}
          endDate={range[1]}
          startActive={handleStartDate}
          endActive={!handleStartDate}
          onStartClick={() => setHandleStartDate(true)}
          onEndClick={() => setHandleStartDate(false)}
          showTime={allowTime}
        />
      </Box>
    </MatDrawer>
  );
};

export default DateRangeDrawer;
