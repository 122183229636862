import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import CheckedRadio from 'components/Elements/RadioButtons/CheckedRadio';
import UncheckedRadio from 'components/Elements/RadioButtons/UncheckedRadio';

const today = Moment();

const RangeDrawer = ({ open, onClose, defaultTimeFrame, onSubmit }) => {
  const { t } = useTranslation();
  const [timeFrame, setTimeFrame] = useState(defaultTimeFrame || 'month');

  // eslint-disable-next-line
  const listTile = ({ title, subtext, value }) => (
    <ListItem button onClick={() => setTimeFrame(value)}>
      <ListItemIcon>
        {timeFrame === value ? <CheckedRadio /> : <UncheckedRadio />}
      </ListItemIcon>
      <ListItemText primary={title} secondary={subtext} />
    </ListItem>
  );

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => onSubmit(timeFrame)}
      title={t('metricTimeFrame')}
      submitBtn={t('apply')}
      cancelBtn={t('cancel')}
    >
      <Box maxWidth={450}>
        <Typography variant="subtitle2">
          {t('chooseTimeParam')}
        </Typography>
        <List>
          {listTile({
            title: t('week'),
            subtext: `${today
              .clone()
              .startOf('week')
              .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
            value: 'week',
          })}
          <Divider />
          {listTile({
            title: t('month'),
            subtext: `${today
              .clone()
              .startOf('month')
              .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
            value: 'month',
          })}
          <Divider />
          {listTile({
            title: t('quarter'),
            subtext: `${today
              .clone()
              .startOf('quarter')
              .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
            value: 'quarter',
          })}
          <Divider />
          {listTile({
            title: t('year'),
            subtext: `${today
              .clone()
              .startOf('year')
              .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
            value: 'year',
          })}
          <Divider />
          {listTile({
            title: t('currentYear'),
            subtext: `${today
              .clone()
              .startOf('year')
              .format('MMM D, YYYY')} - ${today.format('MMM D, YYYY')}`,
            value: 'current_year',
          })}
        </List>
      </Box>
    </MatDrawer>
  );
};

RangeDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultTimeFrame: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

RangeDrawer.defaultProps = {
  defaultTimeFrame: 'current_year',
};

export default RangeDrawer;
