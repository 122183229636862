import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';

import PropTypes from 'prop-types';

import MatDrawer from '../../Elements/MatDrawer/MatDrawer';
import MatFormLabel from '../../Elements/MatFormLabel/FormLabel';
import MatSelect from '../../Elements/MatSelect/MatSelect';

const FilterDrawer = ({
  open,
  onClose,
  onSave,
  locations,
  filteredLocation,
}) => {
  const [location, setLocation] = useState(filteredLocation);

  const applyFilters = () => {
    onSave(location);
  };

  const closeDrawer = () => {
    onClose();
  };

  const handleLocationSelect = (e) => {
    setLocation(e.target.value);
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={closeDrawer}
      onCancel={closeDrawer}
      onSubmit={applyFilters}
      title="Filter"
      submitBtn="APPLY"
      cancelBtn="Cancel"
    >
      <Box minWidth={350} mt={15}>
        <div>
          <MatFormLabel component="legend" style={{ marginBottom: 22 }}>
            By Location
          </MatFormLabel>
          <MatSelect
            value={[location]}
            defaultValue="All"
            onChange={handleLocationSelect}
            options={[{ name: 'All', id: 'all' }, ...locations]}
            nameKey="name"
            valueKey="id"
            sx={{
              [`& fieldset`]: {
                borderColor: Colors.primary,
                opacity: '.5',
              },
            }}
          />
        </div>
      </Box>
    </MatDrawer>
  );
};

FilterDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  filteredLocation: PropTypes.string.isRequired,
  locations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default FilterDrawer;
