import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import BillingHistory from './BillingHistory';
import CancelDrawer from './CancelDrawer';
import CardBox from './CardBox';
import { subscriptionActions } from 'store/entities/Subscriptions';
import { useNavigate } from 'react-router-dom';

const SubscriptionOverview = ({ setUpgradePlan }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    subscriptions,
    subscriptionType,
    subscriptionPackages,
    subscriptionId,
    billingHistory,
    nextPayment,
    actionsCount,
    cards,
    defaultCard,
  } = useSelector((state: any) => state.subscriptions);
  const [planDetails, setPlanDetails] = useState<any>({});

  useEffect(() => {
    setPlanDetails(
      subscriptionPackages.filter((el) => el.name === subscriptionType)[0],
    );
  }, []);
  const [showCancelDrawer, setShowCancelDrawer] = useState(false);
  const SAMPLE_BENEFITS = [
    `${planDetails.userLimit} Users`,
    `${planDetails.locationLimit} Locations Limit`,
    t('unlimitedMonthlyCampaigns'),
    t('liveCampaignEdit'),
    t('earlyCampaignTermination'),
    t('campaignMetrics'),
    t('teamMemberMetrics'),
    t('fullTeamAnnouncements'),
    t('jotterNotes'),
  ];
  const navigate = useNavigate();

  const cancelPlan = () => {
    dispatch(
      subscriptionActions.cancelSubscription({ planId: subscriptionId }),
    );
    setShowCancelDrawer(false);
  };

  const getNextPaymentDate = () => {
    if (planDetails.subDetails) {
      if (planDetails.subDetails.status === 'active') {
        return Moment.unix(nextPayment).format('MMM D, YYYY');
      } else if (planDetails.subDetails.status === 'canceled') {
        return t('cancelled');
      }
    }
    return '';
  };

  const benefitRow = (value) => (
    <Box alignItems="center" display="flex" pt={0.75} pb={0.75} ml={1}>
      <CheckIcon
        sx={{
          color: Colors.success,
          marginRight: 1,
          width: 16,
          height: 16,
        }}
      />
      <Typography variant="subtitle1">{value}</Typography>
    </Box>
  );

  const costAction = `${planDetails.costAction}$ X 
  ${
    actionsCount - planDetails.includedAction > 0
      ? actionsCount - planDetails.includedAction
      : 0
  }`;

  return (
    <Box width="100%" display="flex">
      <Box flexGrow={1}>
        <Box
          width="100%"
          pt={1}
          pb={1}
          pl={5}
          pr={5}
          borderRadius={1}
          borderColor={Colors.lightBlueGray}
          border={0.5}
        >
          <Box display="flex" alignItems="center">
            <Typography sx={{ flexGrow: 1, fontWeight: 'bold', fontSize: 16 }}>
              {t('planDetails')}
            </Typography>
            {planDetails.subDetails &&
              planDetails.subDetails.status === 'active' && (
                <Button variant="text" color="primary">
                  {t('changePlan')}
                </Button>
              )}
          </Box>
          <Grid
            container
            sx={{
              marginTop: 3,
              marginBottom: 3,
              justifyContent: 'space-between',
            }}
          >
            <Grid item md={3}>
              <Typography variant="caption">{t('plan')}</Typography>
              <Typography variant="body1">{planDetails.name || ''}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="caption">{t('cost')}</Typography>
              <Typography variant="body1">{`$${planDetails.price}/month`}</Typography>
            </Grid>
            <Grid item md={3}>
              <Typography variant="caption">{t('nextPayment')}</Typography>
              <Typography variant="body1">
                {Moment.unix(nextPayment).format('MMM D, YYYY')}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Typography variant="caption">Actions Click</Typography>
            <Typography variant="h6">{`${actionsCount}/${planDetails.includedAction}`}</Typography>
            <Typography variant="caption">Cost X Extra Action Click</Typography>
            <Typography variant="h6">{costAction}</Typography>
          </div>
          <div>
            <Typography variant="caption">{t('benefits')}</Typography>
            {SAMPLE_BENEFITS.map((item) => benefitRow(item))}
          </div>
          <Box display="flex" width="100%" justifyContent="end" mt={3}>
            <Button
              variant="text"
              sx={{ color: Colors.success }}
              onClick={() => setUpgradePlan(true)}
            >
              Update Subscription
            </Button>
            <Button
              variant="text"
              sx={{ color: Colors.error }}
              onClick={() => setShowCancelDrawer(true)}
            >
              {t('cancelSubscription')}
            </Button>
          </Box>
        </Box>
      </Box>
      <Box pl={2}>
        <CardBox cards={cards} defaultCard={defaultCard} />
        <BillingHistory billingItems={billingHistory} />
      </Box>
      <CancelDrawer
        open={showCancelDrawer}
        onClose={() => setShowCancelDrawer(false)}
        onSubmit={cancelPlan}
      />
    </Box>
  );
};

SubscriptionOverview.prototype = {
  setUpgradePlan: PropTypes.func,
};

export default SubscriptionOverview;
