import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import GroupIcon from '@mui/icons-material/Group';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

import HeaderCell from 'components/HeaderCell';

import Row from './Row';
import { TableContainer } from '@mui/material';

const columns = [
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: 'Engagements',
    key: 'engagements',
  },
  {
    name: 'Views',
    key: 'impressions',
  },
  {
    name: 'Actions',
    key: 'conversions',
  },
];

const TeamMembersTable = ({ campaignId, type }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [page, setPage] = useState(0);
  const [teamMembers, setTeamMembers] = useState<any>([]);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);

  const { membersCampaign } = useSelector((state: RootState) => state.members);

  useEffect(() => {
      setLoadingTeamMembers(false)
      setTeamMembers(membersCampaign);
  }, [membersCampaign])

  const desc = (a, b, oBy) => {
    const aInfo = a[oBy];
    const bInfo = b[oBy];

    if (bInfo < aInfo) {
      return -1;
    }
    if (bInfo > aInfo) {
      return 1;
    }
    return 0;
  };

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, val) => {
    setPage(val);
  };

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const o = cmp(a[0], b[0]);
      if (o !== 0) return o;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (o, oBy) => {
    if (o === 'desc') {
      return (a, b) => desc(a, b, oBy);
    }
    return (a, b) => -desc(a, b, oBy);
  };

  if (loadingTeamMembers) {
    return (
      <Box width="100%" height="100%">
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <CircularProgress sx={{ width: 48, height: 48 }} />
          <Typography variant="subtitle2">Loading Team Members</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box width="100%" height="100%">
      <Typography variant="h6">Team Members</Typography>
      {teamMembers && teamMembers.length > 0 ? (
        <TableContainer>
          <Table
            sx={{
              borderSpacing: ' 0 10px !important',
              borderCollapse: 'separate !important' as any,
              '& .MuiTableCell-root': {
                border: 'none !important',
              },
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <HeaderCell
                    key={column.key}
                    column={column}
                    sx={{ border: 'none', padding: 0, minHeight: 64 }}
                    orderBy={orderBy}
                    order={order}
                    onCreateSortHandler={createSortHandler}
                    align={column.key === 'name' ? 'left' : 'center'}
                  />
                ))}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                '& tr': {
                  color: Colors.black,
                  borderRadius: 2.5,
                },
                '& tr td:first-child': {
                  borderTopLeftRadius: 2.5,
                  borderBottomLeftRadius: 2.5,
                  overflow: 'hidden',
                },
                '& tr td:last-child': {
                  borderTopRightRadius: 2.5,
                  borderBottomRightRadius: 2.5,
                  overflow: 'hidden',
                },
              }}
            >
              {stableSort(teamMembers, getSorting(order, orderBy))
                .slice(page * 5, page * 5 + 5)
                .map((teamMember) => (
                  <Row
                    name={teamMember.name}
                    engagements={teamMember.engagements}
                    impressions={teamMember.impressions}
                    conversions={teamMember.conversions}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5]}
            component="div"
            count={teamMembers.length}
            rowsPerPage={5}
            labelDisplayedRows={() =>
              `${page + 1}-${Math.floor(teamMembers.length / 5) + 1}`
            }
            page={page}
            onPageChange={handleChangePage}
          />
        </TableContainer>
      ) : (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <GroupIcon sx={{ width: 48, height: 48 }} />
          <Typography variant="subtitle1">
            No Team Member data to display
          </Typography>
        </Box>
      )}
    </Box>
  );
};

TeamMembersTable.propTypes = {
  campaignId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default TeamMembersTable;
