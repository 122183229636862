const routes = {
  dashboard: () => '/',
  campaigns: {
    main: () => '/campaigns',
    build: () => '/campaigns/build',
    campaign: (campaignId: string) => `/campaigns/${campaignId}`,
    edit: (campaignId: string) => `/campaigns/${campaignId}/edit`,
  },
  templates: {
    main: () => '/templates',
    build: () => '/templates/build',
    campaign: (campaignId: string) => `/templates/${campaignId}`,
    edit: (campaignId: string) => `/templates/${campaignId}/edit`,
  },
  teamHub: {
    main: () => '/teamHub',
    build: () => '/teamHub/card/build',
    folder: (folderId: string) => `/teamHub/folder/${folderId}`,
    card: (cardId: string) => `/teamHub/card/${cardId}`,
    edit: (cardId: string) => `/teamHub/card/${cardId}/edit`,
  },
  announcements: {
    main: () => '/announcements',
    build: () => '/announcements/build',
    announcement: (announcementId: string) => `/announcements/${announcementId}`,
    edit: (announcementId: string) => `/announcements/${announcementId}/edit`,
  },
  auth: {
    signin: () => '/auth/signin',
    signup: () => '/auth/signup',
    vendor: () => '/auth/signup/vendor',
    forgotPassword: () => '/auth/forgot-password',
    passwordReset: () => '/auth/password-reset',
  },
  teams: () => '/teams',
  reporting: () => '/reporting',
  members: () => '/members',
  settings: () => '/settings',
  PrivacyAndPolicy: () => '/privacy-statement',
  TermsAndConditions: () => '/terms-and-conditions',

}

export default routes;
