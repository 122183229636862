/* eslint-disable no-param-reassign */
import {
  createEntityAdapter,
  createSlice,
  createAsyncThunk,
  EntityState,
  createDraftSafeSelector,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import Campaign from 'store/models/Campaign';
import Coupon from 'store/models/Coupon';
import Location, { ILocation, LocationAttributes } from 'store/models/Location';
import { RequestStatus, Sort, FetchPayload } from 'store/utils/types';
import { BusinessFolderUpload, LocationUpload } from 'utils/cloudinary';

const sliceName = 'TeamHub';
const getTeamId = (team: ILocation) => `${team.business_id}/${team?.objectId}`;

const TeamHubAdapter = createEntityAdapter<ILocation>({ selectId: getTeamId });

export interface getTeamPayload {
  folderId: string;
}

const getFolderCards = createAsyncThunk(
  `${sliceName}/getFolderCards`,
  async ({ folderId }: getTeamPayload) => {
    try {
      const folder = await Parse.Cloud.run('Campaigns:getWithPagination', {
        folderId,
        type: 'active',
      });
      return folder;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface createFolderPayload {
  name: string;
  color: string;
}

const createFolder = createAsyncThunk(
  `${sliceName}/createFolder`,
  async ({ name, color }: createFolderPayload, { getState, dispatch }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      const folder = await Parse.Cloud.run('Folders:createFolder', {
        name,
        color,
        businessId,
      });
      dispatch(fetchFolders());
      return folder;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface updateFolderrPayload {
  folderId: string;
  name?: string;
  color?: string;
}

const updateFolder = createAsyncThunk(
  `${sliceName}/updateFolder`,
  async ({ name, color, folderId }: updateFolderrPayload, { getState, dispatch }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }

      if (!folderId) {
        throw new Error('Missing folder Id');
      }
      const folder = await Parse.Cloud.run('Folders:updateOne', {
        name,
        color,
        folderId,
      });
      dispatch(fetchFolders());
      return folder;
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface createCardPayload {
  title: string;
  published: boolean;
  description: string;
  image: any;
  info: any;
  teamMembers: string[];
  teamsIds: string[];
  actionsForExpire: number;
  isAvailableForNewUsers: boolean;
  startDate: string;
  endDate?: string;
  id?: string | null;
  locationId?: string | null;
  advanced: any;
  folderId: string;
  callback: (cardJson: any) => void;
}

const createCard = createAsyncThunk(
  `${sliceName}/createCard`,
  async (
    {
      title,
      description,
      image,
      published,
      info,
      teamMembers,
      teamsIds,
      actionsForExpire,
      isAvailableForNewUsers,
      startDate,
      endDate,
      id,
      advanced,
      locationId,
      folderId,
      callback,
    }: createCardPayload,
    { getState, dispatch },
  ) => {
    try {
      if (!title || !description || !startDate || !folderId) {
        throw new Error('Missing fields');
      }

      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      const user = userSelectors.getCurrentUser(state);
      if (!businessId) {
        throw new Error('No active business.');
      }

      const infoIndex = 0;
      const rowDetail: any = JSON.parse(JSON.stringify(info[infoIndex].item));

      if (rowDetail.type !== 'info') {
        throw new Error('Invalid card');
      }

      if (
        rowDetail.info.image !== null &&
        typeof rowDetail.info.image !== 'undefined'
      ) {
        if (rowDetail?.info?.image?.type === 'custom') {
          // eslint-disable-next-line
          const imageUrl = await BusinessFolderUpload({
            businessId,
            image: rowDetail.info?.image?.url,
          });
          rowDetail.info.image = {
            type: 'custom',
            url: imageUrl,
          };
        }
      }

      const infoMap = [
        {
          name: 'row_one',
          rowDetail,
        },
      ];

      const card = await Parse.Cloud.run('Campaigns:createPremiumCampaign', {
        title,
        description,
        image,
        published,
        info: infoMap,
        teamMembers,
        teamsIds,
        actionsForExpire,
        isAvailableForNewUsers,
        startDate,
        endDate,
        id,
        advanced,
        locationId,
        folderId,
        businessId,
      });
      const cardJson = card.toJSON();
      dispatch(getFolderCards({ folderId }));
      callback(cardJson);
      const coupon = new Coupon({
        premium_pointer: Campaign.getPointer(cardJson.objectId),
        generic: 'card coupon',
        agent_id: user?.objectId,
      });
      await coupon.save();
      return card;
    } catch (e) {
      console.error(`Error creating run again campaign: ${e}`);
      console.error(e);
      return new Error(`Error creating campaign: ${e}`);
    }
  },
);

export type TeamHubtatus = 'active' | 'inactive' | 'all';

export interface TeamFilter {
  status?: TeamHubtatus;
}

export type TeamHubort = Sort<keyof LocationAttributes>;

export interface fetchFoldersPayload
  extends FetchPayload<TeamFilter, TeamHubort> {}

/* const defaultPageSize = 10; */

const fetchFolders = createAsyncThunk(
  `${sliceName}/fetchFolders`,
  async (props, { getState }: any) => {
    try {
      const state = getState() as RootState;
      const businessId = userSelectors.getbusinessId(state);
      if (!businessId) {
        throw new Error('No active business.');
      }
      const folders = await Parse.Cloud.run('Folders:getAllByBusinessId', {
        businessId,
      });
      return folders.map((el) => ({
        name: el.name,
        color: el.color,
        id: el.objectId,
        count: el.activeCardsCount,
      }));
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface TeamHubtate extends EntityState<ILocation> {
  status: RequestStatus;
  error?: string;
  folders?: any;
  cards?: any;
}

const initialState: TeamHubtate = {
  ...TeamHubAdapter.getInitialState(),
  status: 'idle',
};

const TeamHubSliceName = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Fetch
    builder.addCase(fetchFolders.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(fetchFolders.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.folders = action.payload;
      state.error = '';
    });
    builder.addCase(fetchFolders.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Get
    builder.addCase(getFolderCards.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(getFolderCards.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.cards = action.payload;
      state.error = '';
    });
    builder.addCase(getFolderCards.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createFolder.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createFolder.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(createFolder.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // update
    builder.addCase(updateFolder.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateFolder.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(updateFolder.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create
    builder.addCase(createCard.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createCard.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.error = '';
    });
    builder.addCase(createCard.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    setOne(state, action) {
      TeamHubAdapter.setOne(state, action.payload);
    },
    setMany(state, action) {
      TeamHubAdapter.setMany(state, action.payload);
    },
    removeOne: TeamHubAdapter.removeOne,
    removeMany: TeamHubAdapter.removeMany,
  },
});

export const TeamHubSelectors = {};

export const TeamHubActions = {
  fetchFolders,
  createFolder,
  updateFolder,
  createCard,
  getFolderCards,
  ...TeamHubSliceName.actions,
};

export default TeamHubSliceName.reducer;
