import React, { useEffect, useState } from 'react';

import Permissions from 'constants/permissions';
import RequestStatus from 'constants/requestStatus'

import { Box, Grid, Typography, FormControlLabel } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  getPermissionsId, getUserPermisions, permissionsSelector,
  setUserPermissions,
} from 'store/entities/Permissions';

import MatCheckbox from 'components/Elements/MatCheckbox/MatCheckbox';
import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import { Save } from '@mui/icons-material';

const PermissionDrawer = ({ open, onClose, businessId, userId }) => {
  const { status } = useSelector((state: any) => state.permissions)
  const permissionSet = useSelector((state) =>
    permissionsSelector.selectById(state, getPermissionsId(businessId, userId)))
  const dispatch = useDispatch()
  const [permissions, setPermissions] = useState<any>([])
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    if (userId && businessId) {
      dispatch(getUserPermisions({
        businessId,
        userId,
      }))
    }
  }, [userId, businessId])

  useEffect(() => {
    if (permissionSet && permissionSet.permissions) {
      setPermissions(permissionSet.permissions)
    }
  }, [permissionSet])

  useEffect(() => {
    if (saving && status === RequestStatus.fulfilled) {
      setSaving(false);
      onClose();
    }
  }, [saving, status])

  const handleSave = () => {
    dispatch(setUserPermissions({
      businessId,
      userId,
      permissions,
    }));
    setSaving(true);
  };

  const handleActionClick = (checked, resource, action) => {
    const permission = Permissions[resource][action];
    const set: any = new Set(permissions)
    if (checked) {
      set.add(permission)
    } else {
      set.delete(permission)
    }
    const newPermissions: any = [...set].filter((p) => !!p)
    setPermissions(newPermissions)
  }

  const handleResourceClick = (checked, resource) => {
    const ppermissions = Object.values(Permissions[resource]);
    const set: any = new Set(permissions)
    if (checked) {
      ppermissions.forEach((p) => set.add(p))
    } else {
      ppermissions.forEach((p) => set.delete(p))
    }
    const newPermissions: any = [...set].filter((p) => !!p)
    setPermissions(newPermissions)
  }

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={handleSave}
      title="User Permissions"
      submitBtn={status === RequestStatus.pending ? <CircularProgress color="inherit" size="1em" /> : <Save />}
      cancelBtn="Cancel"
    >
      <Box width={465}>
        <Box mb={2}>
          <Typography>
            Control what access and visibility your Team
            Members have throughout the TappShare platform.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Campaigns</span>}
              control={
                <MatCheckbox
                  checked={Object.keys(Permissions.campaign).reduce(
                    (prev: any, curr: any) => prev &&
                      permissions.includes(Permissions.campaign[curr]),
                    true)}
                  onClick={(e: any) => handleResourceClick(e.target.checked, 'campaign')
                  }
                />
              }
            />
            <br />
            <FormControlLabel
              label="Create"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.campaign.create)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'campaign', 'create')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="Edit"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.campaign.edit)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'campaign', 'edit')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="View all"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.campaign.view)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'campaign', 'view')}
                />
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Announcements</span>}
              control={
                <MatCheckbox
                  checked={Object.keys(Permissions.announcement).reduce(
                    (prev, curr) => prev && permissions.includes(Permissions.announcement[curr]),
                    true)}
                  onClick={(e: any) => handleResourceClick(e.target.checked, 'announcement')
                  }
                />
              }
            />
            <br />
            <FormControlLabel
              label="Create"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.announcement.create)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'announcement', 'create')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="Edit"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.announcement.edit)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'announcement', 'edit')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="View all"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.announcement.view)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'announcement', 'view')}
                />
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Users</span>}
              control={
                <MatCheckbox
                  checked={Object.keys(Permissions.user).reduce(
                    (prev, curr) => prev && permissions.includes(Permissions.user[curr]),
                    true)}
                  onClick={(e: any) => handleResourceClick(e.target.checked, 'user')
                  }
                />
              }
            />
            <br />
            <FormControlLabel
              label="Create"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.user.create)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'user', 'create')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="Edit"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.user.edit)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'user', 'edit')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="View all"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.user.view)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'user', 'view')}
                />
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Teams</span>}
              control={
                <MatCheckbox
                  checked={Object.keys(Permissions.team).reduce(
                    (prev, curr) => prev && permissions.includes(Permissions.team[curr]),
                    true)}
                  onClick={(e: any) => handleResourceClick(e.target.checked, 'team')
                  }
                />
              }
            />
            <br />
            <FormControlLabel
              label="Create"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.team.create)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'team', 'create')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="Edit"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.team.edit)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'team', 'edit')}
                />
              }
            />
            <br />
            <FormControlLabel
              label="View all"
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.team.view)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'team', 'view')}
                />
              }
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Edit Business</span>}
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.business.edit)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'business', 'edit')}
                />
              }
            />
            <br />
            <Typography>
              Ability to update business settings such as name and logo.
              Can also edit User level permissions.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Generate Report</span>}
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.report.create)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'report', 'create')}
                />
              }
            />
            <br />
            <Typography>
              Ability to generate report.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Manage subscriptions</span>}
              control={
                <MatCheckbox
                  checked={Object.keys(Permissions.subscription).reduce(
                    (prev, curr) => prev && permissions.includes(Permissions.subscription[curr]),
                    true)}
                  onClick={(e: any) => handleResourceClick(e.target.checked, 'subscription')
                  }
                />
              }
            />
            <br />
            <Typography>
              Ability to generate report.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              label={<span style={{ fontWeight: 'bold' }}>Manage integrations</span>}
              control={
                <MatCheckbox
                  checked={permissions.includes(Permissions.integration.edit)}
                  onClick={(e: any) => handleActionClick(e.target.checked,
                    'integration', 'edit')}
                />
              }
            />
            <br />
            <Typography>
              Ability to manage integrations.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

PermissionDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  businessId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};

export default PermissionDrawer;
