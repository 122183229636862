import React from 'react';

import Permissions from 'constants/permissions';

import { IconButton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'store';

import PermissionRequired from 'components/PermissionRequired';

import navigationLinks from './navigationLinks';
import {
  SubscriptionNavRequired,
  VendorNavRequired,
} from './FullWidthNavigation';

export interface LinkItemProps {
  path: string;
  icon: JSX.Element;
  display: string;
}

const LinkItem: React.FC<LinkItemProps> = ({ path, icon, display }) => (
  <Box py={1}>
    <IconButton component={Link} to={path}>
      {icon}
    </IconButton>
  </Box>
);

const MiniNavigation = () => {
  const { user, vendorId } = useSelector((state: RootState) => state.user);
  const { accountStatus } = useSelector(
    (state: RootState) => state.subscriptions,
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={1}
      maxWidth={75}
    >
      <Box my={2}>
        <Avatar alt="Profile Photo" src={user?.photo_url || null} />
      </Box>
      {!vendorId && (
        <LinkItem
          path={navigationLinks.dashboard.path}
          icon={navigationLinks.dashboard.icon}
          display={navigationLinks.dashboard.display}
        />
      )}
      <VendorNavRequired vendor={vendorId}>
        <LinkItem
          path={navigationLinks.templates.path}
          icon={navigationLinks.templates.icon}
          display={navigationLinks.templates.display}
        />
      </VendorNavRequired>
      <SubscriptionNavRequired status={accountStatus}>
        <LinkItem
          path={navigationLinks.campaigns.path}
          icon={navigationLinks.campaigns.icon}
          display={navigationLinks.campaigns.display}
        />
        {/* <LinkItem
        path={navigationLinks.announcements.path}
        icon={navigationLinks.announcements.icon}
        display={navigationLinks.announcements.display}
      /> */}
        {/* <PermissionRequired permissions={[Permissions.team.view]}>
          <LinkItem
            path={navigationLinks.teamHub.path}
            icon={navigationLinks.teamHub.icon}
            display={navigationLinks.teamHub.display}
          />
        </PermissionRequired> */}
        <PermissionRequired permissions={[Permissions.user.view]}>
          <LinkItem
            path={navigationLinks.members.path}
            icon={navigationLinks.members.icon}
            display={navigationLinks.members.display}
          />
        </PermissionRequired>
        <PermissionRequired permissions={[Permissions.team.view]}>
          <LinkItem
            path={navigationLinks.teams.path}
            icon={navigationLinks.teams.icon}
            display={navigationLinks.teams.display}
          />
        </PermissionRequired>
        <PermissionRequired permissions={[Permissions.report.create]}>
          <LinkItem
            path={navigationLinks.reporting.path}
            icon={navigationLinks.reporting.icon}
            display={navigationLinks.reporting.display}
          />
        </PermissionRequired>
      </SubscriptionNavRequired>
      <Box flexGrow={1} />
      <List>
        <LinkItem
          path={navigationLinks.settings.path}
          icon={navigationLinks.settings.icon}
          display={navigationLinks.settings.display}
        />
      </List>
    </Box>
  );
};

export default MiniNavigation;
