import React, { useState, useEffect } from 'react';

import TimeFrames, { ITimeFrameValue } from 'constants/TimeFrames';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import routes from 'routes';
import { RootState } from 'store';
import { announcementActions } from 'store/entities/Announcements';
import { campaignActions } from 'store/entities/Campaigns';
import { reportActions, reportsSelector } from 'store/entities/Reports';
import { getUserState } from 'store/selectors';

import PermissionRequired from 'components/PermissionRequired';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import DataByTime from './components/DataByTime/DataByTime';
import EmptyDashboard from './components/EmptyDashboard/EmptyDashboard';
import EngagementsByGeography from './components/EngagementsByGeography/EngagementsByGeography';
import MetricsContainer from './components/Metrics/MetricsContainer';
import RangeDrawer from './components/RangeDrawer';
import RecentActivity from './components/Recents/RecentActivity';
import TimeFrameDrawer from './components/TimeFrameDrawer';
import { Collapse, LinearProgress } from '@mui/material';
import useWindowSize from 'utils/hooks/useWindowSize';

interface CreateCampaignButtonProps {
  display: string;
}

const CreateCampaignButton: React.FC<CreateCampaignButtonProps> = ({
  display,
}) => (
  <PermissionRequired permissions={[Permissions.campaign.create]}>
    <Button
      component={Link}
      to={routes.campaigns.build()}
      variant="contained"
      startIcon={<AddIcon />}
    >
      {display}
    </Button>
  </PermissionRequired>
);

interface TimeFrameButtonProps {
  display: string;
  onClick: () => void;
}

const TimeFrameButton: React.FC<TimeFrameButtonProps> = ({
  display,
  onClick,
}) => (
  <Box
    width={124}
    sx={{ cursor: 'pointer' }}
    ml={6}
    onClick={onClick}
    onKeyPress={onClick}
    tabIndex={0}
    role="button"
    display="flex"
  >
    <Typography sx={{ flexGrow: 1 }}>{display}</Typography>
    <KeyboardArrowDown />
  </Box>
);

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showTimeFrame, setShowTimeFrame] = useState(false);
  const [timeFrame, setTimeFrame] = useState<ITimeFrameValue>('month');
  const [timeFrameDrawerOpen, setTimeFrameDrawerOpen] = useState(false);
  const [metrics, setMetrics] = useState<any>([]);
  const [metricsChange, setMetricsChange] = useState<any>([]);
  const [loadingRecent, setLoadingRecent] = useState(true);
  const [loadingDashboard, setLoadingDashboard] = useState(true);

  const onTimeFrameButtonClick = () => {
    setTimeFrameDrawerOpen(!timeFrameDrawerOpen);
  };

  const {
    dashboard,
    loadingMetrics,
    loadingDataOverTime,
    dataByTime,
  } = useSelector((state: RootState) => state.reports);
  const { accountStatus, statusIsLoading } = useSelector(
    (state: RootState) => state.subscriptions,
  );
  const { businessId, role, user, vendorId } = useSelector(getUserState);
  const recentActivity = useSelector(reportsSelector.getRecentActivitiy);
  const { width } = useWindowSize();

  useEffect(() => {
    if (vendorId) {
      navigate(routes.templates.main());
    }
  }, [vendorId]);

  useEffect(() => {
    if (dashboard) {
      setMetrics(dashboard.metrics);
      setMetricsChange(dashboard.metricsChange);
    }
  }, [dashboard]);

  useEffect(() => {
    setLoadingRecent(false);
  }, [recentActivity]);

  useEffect(() => {
    dispatch(reportActions.fetchMetrics({ timeFrame }));
    //  dispatch(announcementActions.fetchCurrentAnnouncements({ filter: {} }));
    //  dispatch(announcementActions.fetchPastAnnouncements({ filter: {} }));
    dispatch(campaignActions.fetchAllCampaigns({}));
    dispatch(reportActions.getDataOverTime({ timeFrame }));
    dispatch(reportActions.clearStatus());
    dispatch(campaignActions.clearStatus());
    setLoadingDashboard(true);
  }, [timeFrame, businessId]);

  const handleCreateNewCampaign = () => {
    navigate(routes.campaigns.build());
  };

  const handleCreateNewTeam = () => {
    navigate(routes.teams());
  };

  const handleCreateNewUser = () => {
    navigate(routes.members());
  };

  const handleCampaignClick = () => {
    // dispatch(setSelectedCampaign({ campaign }));
    navigate('/CampaignDetails');
  };

  const handleAnnouncementClick = (announcement) => {
    navigate(routes.announcements.announcement(announcement.id));
  };

  const updateTimeFrame = (val) => {
    setTimeFrame(val);
    setShowTimeFrame(false);
  };

  const refreshActivity = () => {
    /* dispatch(getRecent({ timeFrame })); */
  };

  const showDashboard = () =>
    ((metrics && metrics.teamMembers > 1) ||
      (recentActivity && recentActivity.length > 0)) &&
    accountStatus;

  return (
    <Scaffold
      HeaderProps={{
        title: 'Dashboard',
        actions: [
          accountStatus && width >= 660 ? (
            <CreateCampaignButton display="new Campaign" />
          ) : (
            <></>
          ),
        ],
        children: accountStatus && (
          <TimeFrameButton
            display={TimeFrames[timeFrame].name}
            onClick={onTimeFrameButtonClick}
          />
        ),
      }}
    >
      {statusIsLoading && !accountStatus ? (
        <Collapse in={true}>
          <LinearProgress />
        </Collapse>
      ) : showDashboard() ? (
        <HomeContainer>
          <Box
            display="grid"
            minHeight="322px"
            sx={(theme) => ({
              marginBottom: 1,
              gridTemplateColumns: '1fr 1fr',
              gap: theme.spacing(1),
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
                flexDirection: 'column',
              },
            })}
          >
            <div>
              <MetricsContainer
                isLoading={loadingMetrics}
                engagements={metrics?.engagements || 0}
                impressions={metrics?.impressions || 0}
                conversions={metrics?.actions || 0}
                teamMembers={metrics?.teamMembers || 0}
                engagementsIncrease={metricsChange?.engagements || 0}
                impressionsIncrease={metricsChange?.impressions || 0}
                conversionsIncrease={metricsChange?.actions || 0}
                selectedTimeFrame={timeFrame}
              />
            </div>
            <div>
              <EngagementsByGeography timeFrame={timeFrame} />
            </div>
          </Box>
          <Box
            display="grid"
            sx={(theme) => ({
              gridTemplateRows: '1fr 1fr',
              gap: theme.spacing(1),
              [theme.breakpoints.down('lg')]: {
                display: 'flex',
                flexDirection: 'column',
              },
            })}
          >
            <Box
              minHeight="322px"
              sx={(theme) => ({
                [theme.breakpoints.up('md')]: {
                  minWidth: '574px',
                },
              })}
            >
              <DataByTime data={dataByTime} isLoading={loadingDataOverTime} />
            </Box>
            <div>
              <RecentActivity
                isLoading={loadingRecent}
                activity={recentActivity}
                handleCampaignClick={handleCampaignClick}
                handleAnnouncementClick={handleAnnouncementClick}
                refreshActivity={refreshActivity}
                handleCreate={handleCreateNewCampaign}
              />
            </div>
          </Box>
        </HomeContainer>
      ) : (
        <EmptyDashboard
          handleCreateNewCampaign={handleCreateNewCampaign}
          handleCreateNewTeam={handleCreateNewTeam}
          handleCreateNewUser={handleCreateNewUser}
          subscriptionStatus={accountStatus}
          owner={role === 'owner'}
          isActive={user?.is_active || false}
        />
      )}
      <RangeDrawer
        open={showTimeFrame}
        onClose={() => setShowTimeFrame(false)}
        onSubmit={updateTimeFrame}
        defaultTimeFrame={timeFrame}
      />
      <TimeFrameDrawer
        open={timeFrameDrawerOpen}
        onClose={() => setTimeFrameDrawerOpen(false)}
        onChange={(value) => {
          setTimeFrame(value);
          setTimeFrameDrawerOpen(false);
        }}
        value={timeFrame}
      />
    </Scaffold>
  );
};

export default Dashboard;
