const dev = {
  PARSE_URL: 'https://api-dev.justspeakeasy.com/parse',
  PARSE_APP_ID: 'o0tjRPZnbwmurH37bznkfsrht9Rmdt4zPS7f4v2T',
  GIPHY_API_KEY: 'cDqTvqg6r2CytSx2ryDXbMdTDjweng0M',
  INVITE_LINK: 'https://account-dev.justspeakeasy.com/enroll/',
  STRIPE_PUBLIC_KEY: 'pk_test_YTr3JpmNMBdGwyF8xCfKQ9Qu'
};

const staging = {
  PARSE_URL: 'https://api-staging.justspeakeasy.com/parse',
  PARSE_APP_ID: 'q6M6nUahLWsg2W9m0Vff0PaT1tfukcuk6tgxNoNN',
  GIPHY_API_KEY: 'cDqTvqg6r2CytSx2ryDXbMdTDjweng0M',
  INVITE_LINK: 'https://account-staging.justspeakeasy.com/enroll/',
  STRIPE_PUBLIC_KEY: '##################################'
};

const prod = {
  PARSE_URL: 'https://api.justspeakeasy.com/parse',
  PARSE_APP_ID: 'GGoRYuDhntlY4DvTF7MFfEdQ73gGqg9YViQuuGd3',
  GIPHY_API_KEY: 'cDqTvqg6r2CytSx2ryDXbMdTDjweng0M',
  INVITE_LINK: 'https://account.justspeakeasy.com/enroll/',
  STRIPE_PUBLIC_KEY: 'pk_live_8z6C7U4NblZ2CQtK3Y2zZyY6',
  MAPBOX_ACCESS_TOKEN:
    'pk.eyJ1IjoiemFjaC1qdXN0c3BlYWtlYXN5IiwiYSI6ImNrYTRjam44bzB0cXkzZW54NWY3NTV6YjkifQ.AhG4-LoaKQzTfUUe_VrmuQ',
  REDEMPTION_SITE: 'https://justforyou.fyi/',
PRIVACY_URL: 'https://docs.google.com/document/d/19z4KHMKHbzhmFsTrJ6IIigZ2ODK-XpsNjAkoQMpan2I/edit#',
  TERMS_CONDITIONS_URL: 'https://www.termsfeed.com/live/f1df9e83-049e-4456-ac56-5205c8e24b06',
};

const config =
  process.env.REACT_APP_STAGE === 'dev'
    ? dev
    : process.env.REACT_APP_STAGE === 'staging'
    ? staging
    : prod;

export default {
  ...config
};

