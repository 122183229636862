import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import routes from 'routes';
import { campaignActions } from 'store/entities/Campaigns';

import HeaderCell from 'components/HeaderCell';

import Row from './Row';

const columns = [
  {
    name: 'Title',
    key: 'title',
  },
];

const SavedList = ({ campaigns, loading, handleTableSettings }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order] = useState(true);
  const [orderBy] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { enqueueSnackbar } = useSnackbar();

  const onClick = (campaign) => {
    navigate(routes.campaigns.campaign(campaign.id), {
      state: { type: 'saved' },
    });
  };

  const callBack = (removed) => {
    if (removed.success) {
      enqueueSnackbar('Campaign Deleted successfuly.', {
        variant: 'success',
      });
    } else if (removed.error) {
      enqueueSnackbar(removed.error, { variant: 'error' });
    }
  };

  const onDelete = (campaignId) => {
    dispatch(campaignActions.deleteCampaign({ campaignId, callBack }));
    dispatch(campaignActions.clearStatus());
  };

  const handleChangePage = (event, val) => {
    setPage(val);
    handleTableSettings({
      limit: rowsPerPage,
      page: val,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    handleTableSettings({
      limit: event.target.value,
      page,
    });
  };

  return (
    <Box width="100%">
      <Table
        sx={{
          borderSpacing: ' 0 10px !important',
          borderCollapse: 'separate !important' as any,
          '& .MuiTableCell-root': {
            border: 'none !important',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <HeaderCell
                key={column.key}
                column={column}
                orderBy={orderBy}
                order={order ? 'desc' : 'asc'}
              />
            ))}
          </TableRow>
        </TableHead>
        {campaigns.data && (
          <TableBody
            sx={{
              '& tr': {
                color: Colors.black,
                borderRadius: 2.5,
              },
              '& tr td:first-child': {
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                overflow: 'hidden',
              },
              '& tr td:last-child': {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                overflow: 'hidden',
              },
            }}
          >
            {campaigns.data.map((campaign) => (
              <Row
                key={campaign.id}
                campaign={campaign}
                onClick={onClick}
                onDelete={onDelete}
              />
            ))}
          </TableBody>
        )}
      </Table>
      {loading ? (
        <Box
          width="100%"
          height={36}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Typography variant="caption">Loading</Typography>
          <CircularProgress sx={{ marginLeft: 1 }} size={16} />
        </Box>
      ) : (
        <TablePagination
          rowsPerPageOptions={[5, 10, 20]}
          component="div"
          count={campaigns.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          labelDisplayedRows={() =>
            `${page + 1}-${Math.floor(campaigns.data.length / rowsPerPage) + 1}`
          }
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

SavedList.propTypes = {
  campaigns: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  handleTableSettings: PropTypes.func.isRequired,
};

SavedList.defaultProps = {
  loading: false,
};

export default SavedList;
