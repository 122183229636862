/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { userSelectors } from 'store/domain/user';
import { IInvite } from 'store/models/Invite';
import { selectState } from 'store/selectors';
import { RequestStatus } from 'store/utils/types';

import { MembersActions } from './Members';

const sliceName = 'Invits';
const getInviteId = (invite: IInvite) => invite.objectId;

export interface inviteMemberPayload {
  email?: string;
  firstName?: string;
  lastName?: string;
  teamId: string;
  mass?: boolean;
  onSuccess: (inviteJSon: any) => void;
}

const inviteMember = createAsyncThunk(
  `${sliceName}/inviteMember`,
  async (
    {
      email,
      firstName,
      lastName,
      teamId,
      mass,
      onSuccess,
    }: inviteMemberPayload,
    { getState, dispatch }: any,
  ) => {
    const state = getState() as RootState;
    const businessId = userSelectors.getbusinessId(state);

    if (!businessId) {
      throw new Error('No active business.');
    }

    const invite = await Parse.Cloud.run(
      'Business:emailInvite', {
      firstNames: [firstName],
      lastNames: [lastName],
      locationId: teamId,
      businessId,
      role: 'agent',
      emails: [email?.trim()],
      mass,
    });
    const inviteJSon = invite.toJSON();
    dispatch(MembersActions.fetchMembers({}));
    onSuccess(inviteJSon);
    return inviteJSon;
  },
);

const InvitsAdapter = createEntityAdapter<IInvite>({
  selectId: getInviteId,
});

export interface InvitsState extends EntityState<IInvite> {
  status: RequestStatus;
  error?: string;
}

const initialState: InvitsState = {
  ...InvitsAdapter.getInitialState(),
  status: 'idle',
};

const InvitsSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    // Invite
    builder.addCase(inviteMember.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(inviteMember.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state = InvitsAdapter.setOne(state, action.payload);
      state.error = '';
    });
    builder.addCase(inviteMember.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
  reducers: {
    setOne(state, action) {
      InvitsAdapter.setOne(state, action.payload);
    },
    setMany(state, action) {
      InvitsAdapter.setMany(state, action.payload);
    },
    setAll(state, action) {
      InvitsAdapter.setAll(state, action.payload);
    },
    removeOne(state, action) {
      InvitsAdapter.removeOne(state, action.payload);
    },
    removeMany(state, action) {
      InvitsAdapter.removeMany(state, action.payload);
    },
  },
});

const getInvits = createDraftSafeSelector(selectState, (state) => {
  const { invits, teams } = state;
  return invits.ids
    .map((id) => invits.entities[id])
    .map((member) => ({
      name: `${member.firstname} ${member.lastname}`,
      id: member.objectId,
      email: member.email,
      role: member.role,
      status: 'pending',
      link: member.mass ? member.dynamic_link : null,
      mass: member.mass,
      location:
        teams.entities[`${member.business_id}/${member.location_id}`].name,
      locationId: member.location_id,
      verificationCode: member.verification_code,
    }));
});

export const invitsSelectors = {
  getInvits,
};

export const InvitsActions = {
  inviteMember,
  ...InvitsSlice.actions,
};

export default InvitsSlice.reducer;
