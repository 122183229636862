import React, { useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PropTypes from 'prop-types';
import { getSorting, stableSort } from 'utils/filter';

import HeaderCell from 'components/HeaderCell';

import Row from './Row';

const columns = [
  {
    name: '',
    key: 'icon',
  },
  {
    name: 'Status',
    key: 'status',
  },
  {
    name: 'Name',
    key: 'name',
  },
  {
    name: '',
    key: 'menu',
  },
];

const LocationsList = ({
  locations,
  menuOptions,
  onClick,
  onEdit,
  loading,
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const createSortHandler = (property) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, val) => {
    setPage(val);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box width="100%">
      <Table
        sx={{
          borderSpacing: ' 0 10px !important',
          borderCollapse: 'separate !important' as any,
          '& .MuiTableCell-root': {
            border: 'none !important',
          },
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <HeaderCell
                key={column.key}
                column={column}
                orderBy={orderBy}
                order={order}
                onCreateSortHandler={createSortHandler}
              />
            ))}
          </TableRow>
        </TableHead>
        {locations && (
          <TableBody
            sx={{
              '& tr': {
                color: Colors.black,
                borderRadius: 2.5,
              },
              '& tr td:first-child': {
                borderTopLeftRadius: 10,
                borderBottomLeftRadius: 10,
                overflow: 'hidden',
              },
              '& tr td:last-child': {
                borderTopRightRadius: 10,
                borderBottomRightRadius: 10,
                overflow: 'hidden',
              },
            }}
          >
            {stableSort(locations, getSorting(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((location) => (
                <Row
                  key={location.objectId}
                  location={location}
                  onClick={onClick}
                  menuOptions={menuOptions}
                  onEdit={onEdit}
                />
              ))}
          </TableBody>
        )}
      </Table>
      {loading ? (
        <Box
          width="100%"
          height={36}
          display="flex"
          justifyContent="end"
          alignItems="center"
        >
          <Typography variant="caption">Loading</Typography>
          <CircularProgress sx={{ marginLeft: 1 }} size={16} />
        </Box>
      ) : (
        <TablePagination
          rowsPerPageOptions={[10, 15, 20]}
          component="div"
          count={locations.length}
          rowsPerPage={rowsPerPage}
          labelDisplayedRows={() =>
            `${page + 1}-${Math.floor(locations.length / rowsPerPage) + 1}`
          }
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Box>
  );
};

LocationsList.propTypes = {
  locations: PropTypes.array.isRequired,
  menuOptions: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LocationsList.defaultProps = {
  loading: false,
};

export default LocationsList;
