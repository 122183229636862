import React, { useEffect, useState } from 'react';

import { Box } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { membersSelectors } from 'store/entities/Members';
import { teamsActions } from 'store/entities/Teams';

import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import TeamMemberSelectTable from 'components/TeamMemberSelectTable';
import TeamsSelectTable from 'components/TeamsSelectTable';

const SelectTeams = ({
  teamMembers,
  teams,
  handleTeamMembers,
  handleTeams,
  locationId,
  recipient,
  setRecipient,

}) => {
  const [members, setMembers] = useState<any[]>([]);
  const [showMembers, setShowMembers] = useState(false);
  const [showTeams, setShowTeams] = useState(false);

  const dispatch = useDispatch();
  const activeTeamMembers = useSelector(membersSelectors.getActiveMembers);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: { status: 'active' } }));
  }, []);

  useEffect(() => {
    if (locationId !== 'all' && locationId) {
      setMembers(
        activeTeamMembers.filter((el) => el.locationId === locationId),
      );
    } else {
      setMembers(activeTeamMembers);
    }
  }, [activeTeamMembers]);

  useEffect(() => {
    switch (recipient) {
      case 'members':
        setShowTeams(false);
        setShowMembers(true);
        break;
      case 'teams':
        setShowTeams(true);
        setShowMembers(false);
        break;
      case 'teamsmembers':
        setShowMembers(true);
        setShowTeams(true);
        break;
      default:
        setShowMembers(false);
        setShowTeams(false);
        break;
    }
  }, [recipient]);

  return (
    <Box
      sx={{
        width: '100%',
        maxHeight: '100%',
        padding: '20px',
        overflow: 'auto',
      }}
    >
      <MatSelect
        value={recipient}
        onChange={(event) => setRecipient(event.target.value)}
        options={[
          {
            name: 'None',
            id: 'all',
          },
          {
            name: 'Select Members',
            id: 'members',
          },
          {
            name: 'Select Teams',
            id: 'teams',
          },
          {
            name: 'Select members and teams',
            id: 'teamsmembers',
          },
        ]}
        nameKey="name"
        valueKey="id"
        size="small"
      />
      {showMembers && (
        <>
          <MatFormLabel
            icon={
              <Tooltip title="Select the team members to add to this card">
                <AlertIcon />
              </Tooltip>
            }
          >
            Select Team Members
          </MatFormLabel>
          <TeamMemberSelectTable
            selected={teamMembers}
            activeTeamMembers={members}
            handleTeamMemberSelect={(members) => handleTeamMembers(members)}
          />
        </>
      )}
      {showTeams && (
        <>
          <MatFormLabel
            icon={
              <Tooltip title="Select the teams to add to this card">
                <AlertIcon />
              </Tooltip>
            }
          >
            Select Teams
          </MatFormLabel>
          <TeamsSelectTable
            selected={teams}
            handleTeamSelect={(teams) => handleTeams(teams)}
          />
        </>
      )}
    </Box>
  );
};

SelectTeams.propTypes = {
  handleTeamMembers: PropTypes.func.isRequired,
  handleTeams: PropTypes.func.isRequired,
  teamMembers: PropTypes.arrayOf(PropTypes.string),
  teams: PropTypes.arrayOf(PropTypes.string),
  locationId: PropTypes.string,
  recipient: PropTypes.string.isRequired,
  setRecipient: PropTypes.func.isRequired,
};

SelectTeams.defaultProps = {
  teamMembers: [],
  locationId: 'all',
};

export default SelectTeams;
