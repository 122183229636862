import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { userActions } from 'store/domain/user';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';

const ChangePasswordDrawer = ({ open, onClose, onSuccess }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [originalPassword, setOriginalPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const handleOriginalPassword = (e) => {
    setOriginalPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const change = () => {
    if (newPassword === confirmPassword) {
      dispatch(
        userActions.changePassword({
          oldPassword: originalPassword,
          newPassword,
        }),
      );
      dispatch(userActions.clearStatus());
      onClose();
      onSuccess();
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={() => change()}
      title={t('changePassword')}
      submitBtn={t('update')}
      cancelBtn={t('cancel')}
      isLoading={false}
    >
      <Box maxWidth={450}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MatFormLabel>{t('currentPassword')}</MatFormLabel>
            <MatInput
              value={originalPassword}
              variant="outlined"
              placeholder=""
              onChange={handleOriginalPassword}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MatFormLabel>{t('newPassword')}</MatFormLabel>
            <MatInput
              value={newPassword}
              variant="outlined"
              placeholder=""
              onChange={handleNewPassword}
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <MatFormLabel>{t('confirmPassword')}</MatFormLabel>
            <MatInput
              value={confirmPassword}
              variant="outlined"
              placeholder=""
              onChange={handleConfirmPassword}
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

ChangePasswordDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default ChangePasswordDrawer;
