import React from 'react';

import Colors from 'constants/colors';

import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

const DateDisplay = ({
  startDate,
  endDate,
  onStartClick,
  onEndClick,
  startActive,
  endActive,
  showTime,
}) => {
  const renderEndDate = () => {
    if (endDate && showTime) {
      return endDate.format('MMM D, YYYY h:mm A');
    } else if (endDate) {
      return endDate.format('MMM D, YYYY');
    }
    return '?';
  };

  return (
    <Box width={1} display="flex" justifyContent="center" alignItems="center" mt={5}>
      <Box sx={startActive ? {
        borderBottomColor: Colors.primary,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
      } : undefined}
      >
        <Box
          onClick={onStartClick}
          onKeyDown={onStartClick}
          tabIndex={0}
          role="button"
          sx={{
            cursor: 'pointer',
            width: 150,
            textAlign: 'center',
          }}
        >
          <Typography sx={{
            fontWeight: 'bold',
            fontSize: 20,
          }}
          >
            {showTime
              ? startDate.format('MMM D, YYYY h:mm A')
              : startDate.format('MMM D, YYYY')}
          </Typography>
        </Box>
      </Box>
      <Box mx={2}>
        <Typography variant="subtitle1">to</Typography>
      </Box>
      <Box sx={endActive ? {
        borderBottomColor: Colors.primary,
        borderBottomWidth: 2,
        borderBottomStyle: 'solid',
      } : undefined}
      >
        <Box
          onClick={onEndClick}
          onKeyDown={onEndClick}
          tabIndex={0}
          role="button"
          sx={{
            cursor: 'pointer',
            width: 150,
            textAlign: 'center',
          }}
        >
          <Typography sx={{
            fontWeight: 'bold',
            fontSize: 20,
          }}
          >
            {renderEndDate()}

          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DateDisplay;
