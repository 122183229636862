import React from 'react'

import colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LoyaltyOutlinedIcon from '@mui/icons-material/LoyaltyOutlined';
import RoomOutlinedIcon from '@mui/icons-material/RoomOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HubIcon from '@mui/icons-material/Hub';

export interface NavigationLink {
  path: string;
  display: string;
  icon: JSX.Element;
  permissions?: string[];
}

const navigationLinks: Record<string, NavigationLink> = {
  dashboard: {
    path: '/',
    display: 'Dashboard',
    icon: <DashboardOutlinedIcon sx={{ color: colors.white }} />,
  },
  campaigns: {
    path: '/campaigns',
    display: 'Campaigns',
    icon: <LoyaltyOutlinedIcon sx={{ color: colors.white }} />,
  },
  templates: {
    path: '/templates',
    display: 'Templates',
    icon: <LoyaltyOutlinedIcon sx={{ color: colors.white }} />,
  },
  announcements: {
    path: '/announcements',
    display: 'Announcements',
    icon: <AnnouncementOutlinedIcon sx={{ color: colors.white }} />,
  },
  members: {
    path: '/members',
    display: 'Members',
    icon: <GroupOutlinedIcon sx={{ color: colors.white }} />,
    permissions: [Permissions.user.view],
  },
  teams: {
    path: '/teams',
    display: 'Teams',
    icon: <RoomOutlinedIcon sx={{ color: colors.white }} />,
    permissions: [Permissions.team.view],
  },
  teamHub: {
    path: '/teamHub',
    display: 'Team Hub',
    icon: <HubIcon sx={{ color: colors.white }} />,
    permissions: [Permissions.team.view],
  },
  reporting: {
    path: '/reporting',
    display: 'Reporting',
    icon: <AssessmentOutlinedIcon sx={{ color: colors.white }} />,
    permissions: [Permissions.report.create],
  },
  settings: {
    path: '/settings',
    display: 'Settings',
    icon: <SettingsOutlinedIcon sx={{ color: colors.white }} />,
  },
}

export default navigationLinks
