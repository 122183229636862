/* eslint-disable no-param-reassign */
import {
  createAsyncThunk,
  createDraftSafeSelector,
  createSlice,
} from '@reduxjs/toolkit';
import Parse from 'parse';
import { RootState } from 'store';
import { businessActions } from 'store/entities/Businesses';
import { permissionsActions } from 'store/entities/Permissions';
import Business from 'store/models/Business';
import BusinessMembers from 'store/models/BusinessMembers';
import User, { IUser } from 'store/models/User';
import { selectState, selectUserState } from 'store/selectors';
import { ParseId, RequestStatus } from 'store/utils/types';
import { AgentUpload } from 'utils/cloudinary';

const sliceName = 'user';

export interface LoginPayload {
  username: string;
  password: string;
}

const login = createAsyncThunk(
  `${sliceName}/login`,
  async ({ username, password }: LoginPayload, { dispatch }) => {
    const user = await Parse.Cloud.run('User:login', {
      email: username,
      password,
    });
    await User.logIn<User>(username, password);
    dispatch(businessActions.fetchBusinesses());
    dispatch(
      permissionsActions.getUserPermisions({
        userId: user?.objectId,
        businessId: user?.business_id,
      }),
    );
    return {
      user: {
        fullName: [user.firstname, user.lastname].join(' '),
        ...user,
      },
      businessId: user?.business_id,
      role: user?.role,
    };
  },
);

export interface resetPasswordayload {
  email: string;
  onSuccess: () => void;
}

const resetPassword = createAsyncThunk(
  `${sliceName}/resetPassword`,
  async ({ email, onSuccess }: resetPasswordayload) => {
    try {
      Parse.User.requestPasswordReset(email);
      onSuccess();
      return true;
    } catch (e) {
      return new Error(`Error resetting password: ${e}`);
    }
  },
);

export interface passwordResetPayload {
  username: string;
  password: string;
  token: string;
  onSuccess: () => void;
}

const passwordReset = createAsyncThunk(
  `${sliceName}/passwordReset`,
  async ({ username, password, token, onSuccess }: passwordResetPayload) => {
    try {
      onSuccess();
      return await Parse.Cloud.run('User:passwordReset', {
        username,
        password,
        token,
      });
    } catch (e) {
      return new Error(`Error resetting password: ${e}`);
    }
  },
);

export interface ChangePasswordPayload {
  oldPassword: string;
  newPassword: string;
}

const changePassword = createAsyncThunk(
  `${sliceName}/changePassword`,
  async ({ oldPassword, newPassword }: ChangePasswordPayload, { getState }) => {
    const { user } = getState() as RootState;
    if (!newPassword || !oldPassword) {
      throw new Error('Missing required parameters');
    }
    if (!user) {
      throw new Error('Not authenticated');
    }
    const { username } = user?.user;
    const newUser = await User.logIn<User>(username, oldPassword);
    newUser.set('password', newPassword);
    await newUser.save();
    return newUser.serialize();
  },
);

export interface UpdateProfilePayload {
  firstName: string;
  lastName: string;
  image: any;
  phoneNumber: string;
  email: string;
}

const updateProfile = createAsyncThunk(
  `${sliceName}/updateProfile`,
  async (
    { firstName, lastName, image, phoneNumber, email }: UpdateProfilePayload,
    { dispatch },
  ) => {
    try {
      const user = await User.currentAsync<User>();
      if (!user) {
        throw new Error('Not authenticated');
      }
      let imageUrl;
      if (image.upload) {
        imageUrl = await AgentUpload(image.url);
      } else {
        imageUrl = image.url;
      }
      user.set('firstname', firstName);
      user.set('lastname', lastName);
      user.set('photo_url', imageUrl);
      user.set('phone', phoneNumber);
      await user.save();
      const json = user.toJSON();
      if (email !== user.get('email')) {
        const emailResult: any = await Parse.Cloud.run('User:update', {
          email,
        });
        dispatch(userActions.logoutUser());
        return null;
      }
      return {
        ...json,
        firstname: json.firstname || '',
        lastname: json.lastname || '',
        emailVerified: !!json.emailVerified,
        username: json.username as string,
        email: json.email as string,
        fullName: [json.firstname, json.lastname].join(' '),
        photo_url: json.photo_url || '',
        phoneNumber: json.phone || '',
      };
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

const setBusinessId = createAsyncThunk(
  `${sliceName}/setBusinessId`,
  async (_: undefined) => {
    const user = Parse.User.current<User>();

    if (!user) {
      throw new Error('No active user found.');
    }
    const memberships = await new Parse.Query(BusinessMembers)
      .equalTo('team_member_id', user)
      .equalTo('active', true)
      .include('business_id')
      .find();

    const businessToJson = memberships.map((membership) => membership.toJSON());

    return businessToJson[0];
  },
);

export interface createVendorAccountPayload {
  vendorName;
  firstName;
  lastName;
  email;
  password;
}

const createVendorAccount = createAsyncThunk(
  `${sliceName}/createVendorAccount`,
  async ({
    vendorName,
    firstName,
    lastName,
    email,
    password,
  }: createVendorAccountPayload) => {
    try {
      const user: any = await Parse.Cloud.run('User:createVendorAccount', {
        vendorName,
        firstName,
        lastName,
        email,
        password,
      });

      const userJson = user.toJSON();
      return {
        ...userJson,
        firstname: userJson.firstname || '',
        lastname: userJson.lastname || '',
        username: userJson.username as string,
        email: userJson.email as string,
        fullName: [userJson.firstname, userJson.lastname].join(' '),
        photo_url: userJson.photo_url || '',
        phoneNumber: userJson.phone || '',
        vendorId: userJson.vendor_id || '',
      };
    } catch (e) {
      throw new Error(`Error: ${e}`);
    }
  },
);

export interface CreateBusinessAccountPayload {
  businessName;
  firstname;
  lastname;
  email;
  password;
}

const createBusinessAccount = createAsyncThunk(
  `${sliceName}/createBusinessAccount`,
  async (
    {
      businessName,
      firstname,
      lastname,
      email,
      password,
    }: CreateBusinessAccountPayload,
    { dispatch },
  ) => {
    const business = new Business({
      name: businessName,
      isEnterprise: false,
    });
    await business.save();
    const newUser = new User({
      username: email,
      firstname,
      lastname,
      email,
      password,
      business_id: business.toJSON().objectId,
    });
    await newUser.save();
    const membership = new BusinessMembers({
      active: true,
      business_id: business,
      team_member_id: newUser,
      role: 'owner',
    });
    await membership.save();
    const user = await User.logIn<User>(email, password);
    dispatch(businessActions.fetchBusinesses());
    dispatch(setBusinessId());

    return user.serialize();
  },
);

export interface UserState {
  user?: IUser;
  status: RequestStatus;
  error?: string;
  businessId?: ParseId;
  vendorId?: ParseId;
  role?: any;
}

const initialState: UserState = {
  status: 'idle',
};

export const userSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearStatus: (state) => {
      state.status = 'idle';
    },
    logoutUser: (state) => {
      state.status = 'idle';
      Parse.User.logOut();
    },
  },
  extraReducers: (builder) => {
    // Login
    builder.addCase(login.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.user = action.payload.user;
      state.businessId = action.payload.businessId;
      state.vendorId = action.payload.user?.vendor_id;
      state.role = action.payload?.role;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // resetPassword
    builder.addCase(resetPassword.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(resetPassword.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // passwordReset
    builder.addCase(passwordReset.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(passwordReset.fulfilled, (state, action) => {
      state.status = 'fulfilled';
    });
    builder.addCase(passwordReset.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create Business Account
    builder.addCase(createBusinessAccount.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createBusinessAccount.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.user = action.payload;
      state.businessId = action.payload.businessId;
    });
    builder.addCase(createBusinessAccount.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Create Vendor Account
    builder.addCase(createVendorAccount.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(createVendorAccount.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.user = action.payload;
      state.vendorId = action.payload.vendorId;
    });
    builder.addCase(createVendorAccount.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Change Password
    builder.addCase(changePassword.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.user = action.payload;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Update
    builder.addCase(updateProfile.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if (action.payload) {
        state.status = 'fulfilled';
        state.user = action.payload;
      }
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
    // Fetch Memberships
    builder.addCase(setBusinessId.pending, (state) => {
      state.status = 'pending';
    });
    builder.addCase(setBusinessId.fulfilled, (state, action) => {
      state.status = 'fulfilled';
      state.businessId = action.payload?.business_id?.objectId;
      state.role = action.payload?.role;
    });
    builder.addCase(setBusinessId.rejected, (state, action) => {
      state.status = 'rejected';
      state.error = action.error.message;
    });
  },
});

const getCurrentUser = createDraftSafeSelector(
  selectUserState,
  (user) => user.user,
);

const getStripeCustomerRef = createDraftSafeSelector(
  selectUserState,
  (user) => user.user.stripe_customer_ref,
);

const getbusinessId = createDraftSafeSelector(
  selectUserState,
  (user) => user.businessId,
);

const getUserbusinesses = createDraftSafeSelector(selectUserState, (user) =>
  user?.businesses?.map((business) => ({
    id: business.objectId,
    name: business.name,
    status: business.status,
  })),
);

const getActiveMembership = createDraftSafeSelector(selectState, (state) => {
  const businessId = getUserbusinesses(state);
  if (!businessId) {
    return undefined;
  }
  return state.BusinessMembers?.entities[businessId];
});

const getUserMemberships = createDraftSafeSelector(selectState, (state) => {
  const userbusinesses = getUserbusinesses(state);
  return userbusinesses.map(
    (userMembershipId) => state.BusinessMembers?.entities[userMembershipId],
  );
});

const getActiveBusiness = createDraftSafeSelector(selectState, (state) => {
  const activeBusinesse = getUserbusinesses(state)?.filter(
    (business) => business.status,
  )[0];
  if (!activeBusinesse) {
    return undefined;
  }
  return activeBusinesse;
});

const getUserBusinesses = createDraftSafeSelector(selectState, (state) => {
  const activeMembership = getActiveMembership(state);
  if (!activeMembership) {
    return undefined;
  }
  return state.businesses?.entities[activeMembership.businessId];
});

export const userActions = {
  login,
  createBusinessAccount,
  createVendorAccount,
  setBusinessId,
  changePassword,
  updateProfile,
  resetPassword,
  passwordReset,
  ...userSlice.actions,
};

export const userSelectors = {
  getCurrentUser,
  getbusinessId,
  getStripeCustomerRef,
  getActiveMembership,
  getActiveBusiness,
  getUserbusinesses,
  getUserMemberships,
  getUserBusinesses,
};

export default userSlice.reducer;
