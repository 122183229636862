import React, { useEffect, useState } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  Tooltip,
} from 'recharts';

import CardContainer from 'components/Elements/CardContainer/CardContainer';
import MatSelect from 'components/Elements/MatSelect/MatSelect';
import Title from 'components/Elements/Title/Title';

import CustomTooltip from './CustomTooltip';

const DataByTime = ({ data, isLoading }) => {
  const [display, setDisplay] = useState('All');
  const [filteredData, setFilteredData] = useState(data);
  const { t } = useTranslation();
  const DATA_TYPES = [
    {
      id: 'conversions',
      name: t('actions'),
      firstColor: '#FFCC80',
      secondColor: '#FF9800',
    },
    {
      id: 'impressions',
      name: t('Views'),
      firstColor: Colors.primary,
      secondColor: Colors.primaryLight,
    },
    {
      id: 'engagements',
      name: t('engagements'),
      firstColor: '#673AB7',
      secondColor: '#B39DDB',
    },
  ];
  const DISPLAY_OPTIONS = [
    {
      title: 'All',
      value: t('all'),
    },
    {
      title: t('actions'),
      value: 'conversions',
    },
    {
      title: t('views'),
      value: 'impressions',
    },
    {
      title: t('engagements'),
      value: 'engagements',
    },
  ];

  const filterData = (originalData, value) => {
    if (value === 'All') {
      setFilteredData(originalData);
      return;
    }
    const filtered = originalData.map((item) => ({
      date: item.date,
      label: item.label,
      [value]: item[value],
    }));
    setFilteredData(filtered);
  };

  const handleDisplayChange = (event) => {
    const { value } = event.target;
    setDisplay(value);
    filterData(data, value);
  };

  const renderGradient = (types) =>
    types.map((type) => (
      <linearGradient key={type.id} id={type.id} x1="0" y1="0" x2="0" y2="1">
        <stop offset="5%" stopColor={type.firstColor} stopOpacity={0.9} />
        <stop offset="95%" stopColor={type.secondColor} stopOpacity={9} />
      </linearGradient>
    ));

  const renderArea = (types) =>
    types.map((type) => (
      <Area
        key={type.id}
        type="monotone"
        dataKey={type.id}
        name={type.name}
        stroke="none"
        fill={`url(#${type.id})`}
        fillOpacity={0.6}
      />
    ));

  useEffect(() => {
    filterData(data, display);
  }, [data]);

  return (
    <CardContainer>
      <Box
        maxWidth="100%"
        sx={(theme) => ({
          [theme.breakpoints.up('lg')]: {
            height: 'calc(100% - 48px)',
          },
          [theme.breakpoints.down('lg')]: {
            height: '322px',
          },
        })}
        maxHeight="100%"
        m={1}
        position="relative"
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Title size="medium" weight="bold">
            {t('dataByTime') as any}
          </Title>

          <Box display="flex">
            <MatSelect
              value={display}
              onChange={handleDisplayChange}
              nameKey="title"
              valueKey="value"
              options={DISPLAY_OPTIONS}
              sx={{ minWidth: 128, height: 30, marginLeft: 1.25 }}
              borderColor="grey"
            />
          </Box>
        </Box>
        {isLoading && (
          <CircularProgress
            sx={{ position: 'absolute', top: '43%', left: '50%' }}
          />
        )}
        <ResponsiveContainer width="100%" height="80%">
          <AreaChart data={filteredData}>
            <CartesianGrid
              // strokeArray="2 2"
              vertical={false}
              stroke={Colors.lightBlueGray}
            />

            <defs>{renderGradient(DATA_TYPES)}</defs>

            <XAxis
              dataKey="label"
              stroke={Colors.primaryBlack}
              tickLine={false}
              axisLine={false}
              tickMargin={15}
              tick={{ fill: Colors.primaryBlack, fontSize: 11 }}
            />
            <YAxis
              stroke={Colors.primaryBlack}
              axisLine={false}
              tickLine={false}
              tickMargin={15}
              tick={{ fill: Colors.primaryBlack, fontSize: 11 }}
            />

            {renderArea(DATA_TYPES)}
            <Tooltip content={<CustomTooltip />} />
            <Legend wrapperStyle={{ bottom: -5 }} />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </CardContainer>
  );
};

DataByTime.propTypes = {
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default DataByTime;
