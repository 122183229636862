import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/styles';

import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import CropperDrawer from '../../CropperDrawer';

import UploadedImage from './UploadedImage';

const ImgStyled = styled('img')({
  width: '100%',
  height: 100,
  objectFit: 'contain',
  borderRadius: 2.5,
  transition: '.3s ease-in-out',
  '&:hover': {
    opacity: 0.5,
  },
});

const LibraryPhotos = ({ photos, onSelect, defaultImage }) => {
  const [uploadPhoto, setUploadPhoto] = useState('');
  const [showCrop, setShowCrop] = useState(false);

  useEffect(() => {
    if (defaultImage && defaultImage.type === 'custom' && defaultImage.upload) {
      setUploadPhoto(defaultImage.url);
    } else {
      setUploadPhoto('');
    }
  }, [defaultImage]);

  const onDrop = (acceptedFiles) => {
    const reader: any = new FileReader();
    reader.onload = () => {
      setUploadPhoto(reader.result);
      onSelect({
        type: 'custom',
        upload: true,
        url: reader.result,
      });
    };
    reader.readAsDataURL(acceptedFiles[0]);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  });

  const handleCropPhoto = (croppedImage) => {
    setShowCrop(false);
    setUploadPhoto(croppedImage);
    onSelect({
      type: 'custom',
      upload: true,
      url: croppedImage,
    });
  };

  const handlePhotoClick = (url) => {
    onSelect({
      type: 'custom',
      upload: false,
      url,
    });
  };

  const deleteUpload = () => {
    setUploadPhoto('');
    onSelect({
      url: '',
      type: '',
      info: {},
    });
  };

  return (
    <Box width="100%" height="100%">
      {(photos === null || photos.length < 1) && uploadPhoto === '' ? (
        <Box
          {...getRootProps()}
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
          sx={
            isDragActive
              ? {
                  borderColor: Colors.success,
                  borderWidth: 2,
                  borderStyle: 'solid',
                }
              : {}
          }
        >
          <input {...getInputProps()} />
          <AddAPhotoOutlinedIcon />
          <Typography variant="subtitle1">
            {isDragActive
              ? 'Add Photo'
              : 'Click or drag to add your first photo'}
          </Typography>
        </Box>
      ) : (
        <Box width="100%" height="100%" p={1} sx={{ overflowY: 'auto' }}>
          <Grid container spacing={2}>
            {uploadPhoto ? (
              <Grid item xs={4}>
                <UploadedImage
                  image={uploadPhoto}
                  onCrop={() => setShowCrop(true)}
                  onDelete={deleteUpload}
                />
              </Grid>
            ) : (
              <Grid item xs={4}>
                <Box
                  {...getRootProps()}
                  width="100%"
                  height={100}
                  borderRadius={2.5}
                  borderColor={Colors.lightBlueGray}
                  border={0.5}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <input {...getInputProps()} />
                  <AddAPhotoOutlinedIcon />
                </Box>
              </Grid>
            )}
            {photos.map((photo) => (
              <Grid item xs={4}>
                <Box
                  width="100%"
                  height={100}
                  borderRadius={2.5}
                  borderColor={Colors.lightBlueGray}
                  border={0.5}
                  onClick={() => handlePhotoClick(photo.secure_url)}
                  onKeyDown={() => handlePhotoClick(photo.secure_url)}
                  tabIndex={0}
                  role="button"
                  sx={{
                    backgroundImage: `url(${photo.secure_url})`,
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      <CropperDrawer
        open={showCrop}
        onClose={() => setShowCrop(false)}
        onSave={handleCropPhoto}
        photo={uploadPhoto}
      />
    </Box>
  );
};

LibraryPhotos.propTypes = {
  photos: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  defaultImage: PropTypes.string,
};

LibraryPhotos.defaultProps = {
  photos: null,
  defaultImage: null,
};

export default LibraryPhotos;
