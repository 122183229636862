import Colors from 'constants/colors';

import { styled } from '@mui/material';
import TextField from '@mui/material/TextField/TextField';

export interface MatInputProps {
}

const MatInput = styled(TextField)<MatInputProps>({
  width: '100%',
  '& .MuiInputBase-input::placeholder': {
    color: Colors.primaryBlack,
    opacity: 1,
  },
  '& fieldset': {
    border: '1px solid',
    borderColor: Colors.primary,
    borderWidth: '1px !important',
  },
  '& .MuiInputBase-input': {
    fontSize: 14,
    fontFamily: 'Arial, Helvetica, sans-serif',
    color: Colors.primaryBlack,
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  '& .MuiInputAdornment-positionStart': {
    marginRight: -4,
  },
  '& textarea.MuiOutlinedInput-input': {
    padding: 0,
  },
  '& .MuiInputLabel-outlined': {
    color: Colors.primaryBlack,
    opacity: '.8',
  },
});

export default MatInput;
