import React from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { defaultStyles } from 'constants/styles';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';

import PropTypes from 'prop-types';

import PermissionRequired from 'components/PermissionRequired';

import StatusCell from './StatusCell';
import { Hidden } from '@mui/material';
import TextWithToolTip from 'components/TextWithToolTip/TextWithToolTip';

const Row = ({ teamMember, onEdit, onClick }) => {
  const getLocationText = () => teamMember.location;

  return (
    <TableRow
      hover
      key={teamMember.objectId}
      sx={{
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
      onClick={onClick}
    >
      <TableCell
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          '@media max-width:1279.95px': {
            display: 'none',
          },
          fontSize: 12,
          color: 'inherit',
          width: 84,
        }}
      >
        <StatusCell isActive={teamMember.status === 'active'} />
      </TableCell>
      <Hidden lgDown>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            fontWeight: 700,
            color: 'inherit',
          }}
        >
          {teamMember.status === 'active' ? 'Active' : 'Inactive'}
        </TableCell>
      </Hidden>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        <TextWithToolTip text={teamMember.name} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 12,
          color: 'inherit',
        }}
      >
        <TextWithToolTip text={teamMember.email} />
      </TableCell>
      <Hidden lgDown>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            '@media max-width:1279.95px': {
              display: 'none',
            },
            fontSize: 12,
            color: 'inherit',
          }}
        >
          {teamMember.tags && teamMember.tags.length > 0 && (
            <Box display="flex" flexWrap="wrap" justifyContent="center">
              {teamMember.tags.map((tag) => (
                <Chip key={tag} label={tag} sx={{ margin: 0.25 }} />
              ))}
            </Box>
          )}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            '@media max-width:1279.95px': {
              display: 'none',
            },
            fontSize: 16,
            color: 'inherit',
          }}
        >
          {getLocationText()}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            width: 115,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        />
        <TableCell
          align="right"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <PermissionRequired permissions={[Permissions.user.edit]}>
            <IconButton
              onClick={() => onEdit(teamMember)}
              aria-label="delete"
              size="large"
            >
              <EditIcon />
            </IconButton>
          </PermissionRequired>
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

Row.propTypes = {
  teamMember: PropTypes.object.isRequired,
  onEdit: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Row;
