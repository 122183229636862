import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import { Card, Box, IconButton, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

import PropTypes from 'prop-types';
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

import Image from './Image';
import MissingAction from './MissingAction';
import { capitalizeWords } from 'utils/formatTools';
import MatInput from 'components/Elements/MatInput/MatInput';
import { getPlainText } from 'utils/wysiwyg_filter';

const InfoCard = ({
  description,
  active,
  onClick,
  image,
  title,
  ctaTwo,
  ctaOne,
  cardId,
  styling,
  setValue,
  item,
  onUpdate,
}) => {
  const [descrip, setDescription] = useState('');

  useEffect(() => {
    if (description) {
      const cfg = {};
      const converter = new QuillDeltaToHtmlConverter(description.ops, cfg);
      setDescription(converter.convert());
    }
  }, [description]);

  const renderCta = (cta) => {
    if (cta.type === 'redirect') {
      return (
        <Button
          color="primary"
          variant={cta.styling?.buttonVariant}
          disableElevation
          onClick={() => {
            if (setValue) {
              setValue(5);
            } else {
              const link = cta.link.includes('https://')
                ? cta.link
                : `https://${cta.link}`;
              const win = window.open(link);
              win?.focus();
            }
          }}
          sx={{
            borderRadius: cta.styling?.buttonBorder,
            color: cta.styling?.textButtonColor,
            height: 22,
            marginBottom: 1.25,
            marginLeft: 1,
            borderColor:
              cta.styling?.buttonVariant === 'outlined'
                ? cta.styling?.buttonColor
                : '',
            backgroundColor:
              cta.styling?.buttonVariant === 'contained'
                ? cta.styling?.buttonColor
                : '',
            textTransform: 'initial',
          }}
        >
          <Typography
            sx={{
              fontSize: 8,
            }}
          >
            {capitalizeWords(cta.name)}
          </Typography>
        </Button>
      );
    } else if (cta.type === 'event') {
      return (
        <Typography
          sx={{
            fontSize: 10,
            color: Colors.primary,
            marginLeft: 1,
            marginRight: 1,
            paddingBottom: 1,
          }}
        >
          Add to Calendar
        </Typography>
      );
    } else if (setValue) {
      return <MissingAction setValue={setValue} />;
    }
    return null;
  };

  return (
    <Box
      sx={{
        marginLeft: 1,
        marginRight: 1,
        marginBottom: 2,
      }}
    >
      <Card
        sx={
          active
            ? {
                textAlign: 'left',
                borderColor: Colors.primaryLight,
                borderStyle: 'solid',
                borderRadius: styling?.cardBorder,
                borderWidth: 3,
                position: 'relative',
              }
            : {
                width: '100%',
                textAlign: 'left',
                cursor: 'pointer',
                borderRadius: styling?.cardBorder,
                position: 'relative',
              }
        }
        onClick={onClick}
      >
        <Box
          sx={{
            width: '100%',
            position: 'relative',
          }}
        >
          <Box
            sx={{
              width: '100%',
              position: 'relative',
              cursor: 'pointer',
            }}
          >
            {setValue && (
              <IconButton
                sx={{ position: 'absolute', top: 5, left: 5, zIndex: 9 }}
                aria-label="add image"
                onClick={() => setValue(3)}
              >
                <AddPhotoAlternateIcon sx={{ color: 'white' }} />
              </IconButton>
            )}
            <Image image={image} />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: 0,
              marginLeft: 2,
              marginRight: 2,
              marginBottom: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                color: Colors.white,
                lineHeight: 1,
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <CardContent
          sx={{
            paddingTop: 1,
            paddingLeft: 1,
            paddingRight: 1,
          }}
        >
          {descrip ? (
            <Box
              color={styling?.mainColor}
              textAlign={styling?.mainAlignment}
              fontSize={styling?.mainSize}
              onClick={() => (setValue ? setValue(1) : null)}
              fontStyle={
                styling?.mainFormat?.includes('italic') ? 'italic' : ''
              }
              sx={{
                cursor: `${setValue ? 'text' : ''}`,
                wordWrap: 'break-word',
                textDecoration: styling?.mainFormat?.includes('underline')
                  ? 'underline'
                  : '',
              }}
              fontWeight={styling?.mainFormat?.includes('bold') ? 'bold' : ''}
              dangerouslySetInnerHTML={{
                __html: descrip,
              }}
            />
          ) : (
            <Typography
              onClick={() => (setValue ? setValue(1) : null)}
              sx={{
                fontSize: 12,
                cursor: `${setValue ? 'text' : ''}`,
                fontStyle: 'italic',
              }}
            >
              Enter a description
            </Typography>
          )}
        </CardContent>
        <CardActions
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 0.5,
            display: 'flex',
          }}
        >
          <Box style={{ flexGrow: 1 }}>{renderCta(ctaOne)}</Box>
          <Box>{renderCta(ctaTwo)}</Box>
        </CardActions>
        <Box
          sx={{
            top: 0,
            right: 0,
            height: 20,
            marginTop: 1.25,
            marginRight: 0.75,
            paddingLeft: 1.25,
            paddingRight: 1.25,
            position: 'absolute',
            backgroundColor: 'rgba(97,97,97 ,0.7)',
            borderRadius: 6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              color: Colors.white,
              fontSize: 10,
            }}
          >
            {cardId}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
};

InfoCard.propTypes = {
  image: PropTypes.any.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.any.isRequired,
  ctaOne: PropTypes.object.isRequired,
  ctaTwo: PropTypes.object.isRequired,
  styling: PropTypes.object.isRequired,
  active: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  cardId: PropTypes.string.isRequired,
  item: PropTypes.object,
  setValue: PropTypes.func,
  onUpdate: PropTypes.func,
};

export default InfoCard;
