import React, { useEffect, useMemo, useState } from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';

import { ReactComponent as SettingsIcon } from 'assets/images/settings_icon.svg';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { MembersActions, membersSelectors } from 'store/entities/Members';
import { teamsActions } from 'store/entities/Teams';

import MatInput from 'components/Elements/MatInput/MatInput';
import EmptyPage from 'components/EmptyPage';
import MissingData from 'components/MissingData';
import HomeContainer from 'components/ui/HomeContainer';
import Scaffold from 'components/ui/Scaffold';

import EditDrawer from './components/EditDrawer';
import FilterDrawer from './components/FilterDrawer';
import InviteDrawer from './components/InviteDrawer';
import QrInvitesList from './components/QrInviteList/QrInvitesList';
import TeamMemberDrawer from './components/TeamMemberDrawer';
import TeamMembersList from './components/TeamMembersList/TeamMembersList';

interface AddMemberButtonProps extends ButtonProps {
  display: string;
  onClick: () => void;
}

const AddMemberButton: React.FC<AddMemberButtonProps> = ({
  display,
  onClick,
  ...props
}) => (
  <Button
    variant="contained"
    onClick={onClick}
    startIcon={<AddIcon />}
    {...props}
  >
    {display}
  </Button>
);

const TeamMembers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isInviteOpen, setIsInviteOpen] = useState(false);
  const [teamMemberForEdit, setTeamMemberForEdit] = useState(null);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showTeamMemberDrawer, setShowTeamMemberDrawer] = useState(false);
  const [selectedTeamMember, setSelectedTeamMember] = useState(null);
  const [invitesAndTeamMembers, setInvitesAndTeamMembers] = useState<any>([]);
  const { enqueueSnackbar } = useSnackbar();
  const [filters, setFilters] = useState<any>({});

  const teamMemberList = useSelector(membersSelectors.getMembers);

  useEffect(() => {
    dispatch(teamsActions.fetchTeams({ filter: {} }));
    dispatch(MembersActions.fetchMembers({}));
  }, []);

  useEffect(() => {
    setInvitesAndTeamMembers(teamMemberList);
  }, [teamMemberList]);

  const onEditClickHandler = (teamMember) => {
    setIsEditOpen(true);
    setTeamMemberForEdit(teamMember);
  };

  const onDeleteClickHandler = (inviteId) => {
    dispatch(
      MembersActions.cancelInvite({
        inviteId,
        callBack: ({ status, message }) => {
          switch (status) {
            case 'failure':
              enqueueSnackbar(message, { variant: 'error' });
              break;
            case 'success':
              enqueueSnackbar(message, { variant: 'success' });
              break;
            default:
          }
        },
      }),
    );
  };

  const menuOptions = useMemo(
    () => [{ name: t('editInfo'), click: onEditClickHandler }],
    [],
  );

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    const search = event.target.value;
    if (search !== '') {
      dispatch(
        MembersActions.fetchMembers({
          search,
          status: [
            {
              name: 'active',
              key: 'active',
              status: true,
            },
            {
              name: 'inactive',
              key: 'inactive',
              status: true,
            },
            {
              name: 'pending',
              key: 'pending',
              status: true,
            },
          ],
        }),
      );
    } else {
      dispatch(
        MembersActions.fetchMembers({
          status: filters.status,
          locationId: filters.locationId,
          tags: filters.tags,
        }),
      );
    }
  };

  const clearSearch = () => {
    setSearchValue('');
  };

  const handleFilter = (status, locationId, tags) => {
    setFilters({ status, locationId, tags });
    dispatch(
      MembersActions.fetchMembers({
        status,
        locationId,
        tags,
      }),
    );
  };

  const showTeamMember = (member) => {
    setSelectedTeamMember(member);
    setShowTeamMemberDrawer(true);
  };

  const teamMembers = invitesAndTeamMembers.filter((t: any) => !t.mass);
  const qrInvites = invitesAndTeamMembers.filter((t: any) => t.mass);

  return (
    <Scaffold
      HeaderProps={{
        title: 'Members',
        actions: [
          (teamMembers && teamMembers.length > 0) ||
          (qrInvites && qrInvites.length > 0) ? (
            <AddMemberButton
              display={t('addUser')}
              onClick={() => setIsInviteOpen(true)}
            />
          ) : (
            <></>
          ),
        ],
      }}
    >
      <HomeContainer>
        <Grid container spacing={1}>
          <Grid item xs={12} md={9} lg={10} xl={10}>
            <MatInput
              variant="outlined"
              placeholder={t('searchUsers')}
              onChange={handleSearch}
              onKeyPress={handleSearch}
              sx={{
                borderRadius: 1,
                backgroundColor: Colors.white,
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                  opacity: '.5',
                },
                '& .MuiInputBase-root': {
                  color: Colors.black,
                },
              }}
              value={searchValue}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    {searchValue.length > 0 ? (
                      <ClearIcon onClick={clearSearch} />
                    ) : (
                      <SearchIcon />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={3} lg={2} xl={2}>
            <Button
              sx={{
                backgroundColor: Colors.white,
                color: Colors.black,
                height: 50,
                width: '100%',
                borderRadius: 1,
                boxShadow: '0px 8px 15px rgba(0, 0, 0, 0.1)',
                '&:hover': {
                  backgroundColor: Colors.lightGray,
                },
              }}
              variant="contained"
              onClick={() => setIsFiltersOpen(true)}
            >
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <SettingsIcon />
                <Typography
                  style={{ flexGrow: 1, marginRight: 10 }}
                  variant="subtitle1"
                >
                  {t('filter')}
                </Typography>
              </div>
            </Button>
          </Grid>
        </Grid>
        {(teamMembers && teamMembers.length > 0) ||
        (qrInvites && qrInvites.length > 0) ? (
          <Grid container sx={{ marginTop: 3.5 }}>
            <Box flex={1}>
              {teamMembers && teamMembers.length > 0 ? (
                <>
                  <Typography gutterBottom variant="h6">
                    {t('users')}
                  </Typography>
                  <TeamMembersList
                    teamMembers={teamMembers}
                    onClick={showTeamMember}
                    onEdit={onEditClickHandler}
                    menuOptions={menuOptions}
                    onDelete={onDeleteClickHandler}
                  />
                </>
              ) : (
                <MissingData message={t('noUsers')} icon={2} />
              )}
            </Box>
            {qrInvites && qrInvites.length > 0 ? (
              <Grid item md={12}>
                <>
                  <Typography gutterBottom variant="h6">
                    {t('qrCodeInvites')}
                  </Typography>
                  <QrInvitesList
                    qrInvites={qrInvites}
                    onClick={showTeamMember}
                    onEdit={onEditClickHandler}
                    menuOptions={menuOptions}
                    onDelete={onDeleteClickHandler}
                  />
                </>
              </Grid>
            ) : null}
          </Grid>
        ) : (
          <EmptyPage
            actions={[
              <AddMemberButton
                sx={{ margin: 1 }}
                display={t('inviteByEmail')}
                onClick={() => setIsInviteOpen(true)}
              />,
              <AddMemberButton
                sx={{ margin: 1 }}
                display={t('inviteByQr')}
                onClick={() => setIsInviteOpen(true)}
              />,
            ]}
            title={t('users')}
            description={t('usersEmptyDescirption')}
            permissions={[Permissions.user.create]}
          />
        )}
      </HomeContainer>
      <FilterDrawer
        open={isFiltersOpen}
        onClose={() => setIsFiltersOpen(false)}
        onFilter={handleFilter}
      />
      <EditDrawer
        teamMember={teamMemberForEdit}
        onClose={() => setIsEditOpen(false)}
        onEdit={() =>
          dispatch(
            MembersActions.fetchMembers({
              status: filters.status,
              locationId: filters.locationId,
              tags: filters.tags,
            }),
          )
        }
        open={isEditOpen}
      />
      <InviteDrawer
        open={isInviteOpen}
        onClose={() => setIsInviteOpen(false)}
      />
      <TeamMemberDrawer
        teamMember={selectedTeamMember}
        open={showTeamMemberDrawer}
        onClose={() => setShowTeamMemberDrawer(false)}
      />
    </Scaffold>
  );
};

export default TeamMembers;
