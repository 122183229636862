import React from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';

import CampaignIcon from 'assets/images/campaign_icon.svg';
import PropTypes from 'prop-types';

const StatusCell = ({ isActive }) => (isActive ? (
    <Box display="flex" alignItems="center">
      <Box
        height={84}
        width={84}
        mr={2.5}
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${CampaignIcon})`,
          backgroundColor: Colors.primary,
        }}
      />
    </Box>
  ) : (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${CampaignIcon})`,
          backgroundColor: Colors.inActiveGray,
        }}
      />
    </Box>
  )
);

StatusCell.propTypes = {
  isActive: PropTypes.bool.isRequired,
};

export default StatusCell;
