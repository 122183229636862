import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelectMultiple from 'components/Elements/MatSelect/MatSelectMultiple';

const CampaignFilterDrawer = ({ open, options, onClose, onFilter }) => {
  const { t } = useTranslation();

  const formattedOptions =
    (options &&
      options.map((option) => ({
        ...option,
        checked: false,
      }))) ||
    [];

  const [selectedTags, setSelectedTags] = useState<any>([]);

  const handleSelectChange = (event) => {
    setSelectedTags(event.target.value);
  };

  const handleFilterSubmit = () => {
    onFilter(selectedTags.length ? selectedTags : null);
    onClose();
  };

  const resetFilters = () => {
    setSelectedTags([]);
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={resetFilters}
      onSubmit={handleFilterSubmit}
      title={t('filters')}
      submitBtn={t('apply')}
      cancelBtn={t('resetFilters')}
    >
      <Box
        width="100%"
        maxWidth={465}
        pl={2}
        pr={2}
        sx={{
          '@media (min-device-width: 959px)': {
            width: 500,
          },
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <FormControl
            component="fieldset"
            sx={{ margin: 0, flex: 1 }}
            key="tags"
          >
            <MatFormLabel sx={{ marginBottom: 2.75 }}>{t('tags')}</MatFormLabel>
            <FormGroup>
              <MatSelectMultiple
                nameKey="name"
                valueKey="name"
                value={selectedTags}
                onChange={handleSelectChange}
                options={formattedOptions}
                style={{ width: '100%' }}
              />
            </FormGroup>
          </FormControl>
        </Box>
      </Box>
    </MatDrawer>
  );
};

CampaignFilterDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  options: PropTypes.array,
};

CampaignFilterDrawer.defaultProps = {
  options: [],
};

export default CampaignFilterDrawer;
