import React from 'react';

import Colors from 'constants/colors';

import { Autocomplete, Chip, TextField } from '@mui/material';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';

import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatSelect from 'components/Elements/MatSelect/MatSelect';

import EmailRow from './EmailRow';

const EmailTab = ({
  activeLocations,
  multipleEntry,
  setMultipleEntry,
  emailList,
  setEmailList,
  multiEmails,
  setMultiEmails,
  firstNameList,
  setFirstNameList,
  lastNameList,
  setLastNameList,
  emailErrors,
  setEmailErrors,
  locationId,
  setLocationId,
  setErrorMessage,
  setShowLocationCreationDrawer,
}) => {
  const handleSelectLocation = (event) => {
    setLocationId(event.target.value);
  };

  const addAnotherRow = () => {
    if (emailList[emailList.length - 1] !== '') {
      const newList: any = [];
      emailList.forEach((value) => {
        newList.push(value);
      });
      newList.push('');
      setEmailList(newList);

      const first: any = [];
      firstNameList.forEach((value) => {
        first.push(value);
      });
      first.push('');
      setFirstNameList(first);

      const last: any = [];
      lastNameList.forEach((value) => {
        last.push(value);
      });
      last.push('');
      setLastNameList(last);

      let errors: any = [];
      errors = [...emailErrors];
      errors.push(false);
      setEmailErrors(errors);
    } else {
      setErrorMessage('Missing email address');
    }
  };

  const handleEmail = (index, event) => {
    const newEmails = [...emailList];
    newEmails[index] = event.target.value;
    setEmailList(newEmails);

    if (emailErrors[index]) {
      const errors = [...emailErrors];
      errors[index] = false;
      setEmailErrors(errors);
    }
  };

  const handleFirstName = (index, event) => {
    const firstNames = [...firstNameList];
    firstNames[index] = event.target.value;
    setFirstNameList(firstNames);
  };

  const handleLastName = (index, event) => {
    const lastNames = [...lastNameList];
    lastNames[index] = event.target.value;
    setLastNameList(lastNames);
  };

  const handleDeleteRow = (index) => {
    const emails = [...emailList];
    if (emails.length > 1) {
      emails.splice(index, 1);
      setEmailList(emails);
    }

    const firstNames = [...firstNameList];
    if (firstNames.length > 1) {
      firstNames.splice(index, 1);
      setFirstNameList(firstNames);
    }

    const lastNames = [...lastNameList];
    if (lastNames.length > 1) {
      lastNames.splice(index, 1);
      setLastNameList(lastNames);
    }

    const errors = [...emailErrors];
    if (errors.length > 1) {
      errors.splice(index, 1);
      setEmailErrors(errors);
    }
  };

  const handleMultipleEntry = (value) => {
    setMultipleEntry(value);
  };

  /* const handleAddMultiEmail = (chip) => {
    const before = [...multiEmails];
    before.push(chip);
    setMultiEmails(before);
  };

  const handleDeleteMulti = (chip, index) => {
    const before = [...multiEmails];
    before.splice(index, 1);
    setMultiEmails(before);
  }; */

  return (
    <>
      <Grid container spacing={1}>
        <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
          <MatFormLabel
            icon={
              <Tooltip title="Select which location to add the team member to">
                <AlertIcon />
              </Tooltip>
            }
          >
            Team
          </MatFormLabel>
          <MatSelect
            value={locationId}
            onChange={handleSelectLocation}
            options={activeLocations}
            nameKey="name"
            valueKey="id"
            size="small"
          />
          <Typography
            onClick={() => setShowLocationCreationDrawer(true)}
            sx={{
              color: Colors.primary,
              display: 'inline-block',
              cursor: 'pointer',
              marginTop: 2,
            }}
          >
            Add Team
          </Typography>
        </Grid>
      </Grid>
      {!multipleEntry ? (
        <div>
          {emailList.map((email, index) => (
            <EmailRow
              key="row"
              email={emailList[index]}
              firstName={firstNameList[index]}
              lastName={lastNameList[index]}
              handleEmail={(event) => handleEmail(index, event)}
              handleFirstName={(event) => handleFirstName(index, event)}
              handleLastName={(event) => handleLastName(index, event)}
              handleDelete={() => handleDeleteRow(index)}
            />
          ))}
          <Grid container>
            <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
              <div>
                <Typography
                  onClick={addAnotherRow}
                  sx={{
                    color: Colors.primary,
                    display: 'inline-block',
                    cursor: 'pointer',
                    marginTop: 2,
                  }}
                >
                  Add Another
                </Typography>
                <Typography
                  style={{
                    marginLeft: 7,
                    marginRight: 7,
                    display: 'inline-block',
                  }}
                >
                  or
                </Typography>
                <Typography
                  onClick={() => handleMultipleEntry(true)}
                  sx={{
                    color: Colors.primary,
                    display: 'inline-block',
                    cursor: 'pointer',
                    marginTop: 2,
                  }}
                >
                  add many at once
                </Typography>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid container>
            <Grid item md={12} xs={12}>
              <MatFormLabel component="legend" style={{ marginBottom: 1.75 }}>
                Multiple email addresses
              </MatFormLabel>
              {/* <MatChip
                value={multiEmails}
                onAdd={(chips) => handleAddMultiEmail(chips)}
                onDelete={(chip, index) => handleDeleteMulti(chip, index)}
                fullWidth={true}
                placeholder="Email Address"
              /> */}
              <Autocomplete
                multiple
                id="tags-filled"
                options={[]}
                defaultValue={multiEmails}
                freeSolo
                onChange={(event, newInputValue) => {
                  setMultiEmails([...newInputValue, ...multiEmails]);
                }}
                renderTags={(value: readonly string[], getTagProps) =>
                  value.map((option: string, index: number) => (
                    <Chip
                      color="primary"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Email Address"
                    sx={{
                      minWidth: '400px',
                    }}
                  />
                )}
              />
              <Typography variant="caption">
                Hit enter after each email address
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
              <Typography
                sx={{
                  color: Colors.primary,
                  display: 'inline-block',
                  cursor: 'pointer',
                  marginTop: 2,
                }}
                onClick={() => handleMultipleEntry(false)}
              >
                Enter emails one at a time
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

EmailTab.propTypes = {
  activeLocations: PropTypes.array.isRequired,
  multipleEntry: PropTypes.array.isRequired,
  setMultipleEntry: PropTypes.func.isRequired,
  emailList: PropTypes.array.isRequired,
  setEmailList: PropTypes.func.isRequired,
  setShowLocationCreationDrawer: PropTypes.func.isRequired,
  multiEmails: PropTypes.array.isRequired,
  setMultiEmails: PropTypes.func.isRequired,
  firstNameList: PropTypes.array.isRequired,
  setFirstNameList: PropTypes.func.isRequired,
  lastNameList: PropTypes.array.isRequired,
  setLastNameList: PropTypes.func.isRequired,
  emailErrors: PropTypes.array.isRequired,
  setEmailErrors: PropTypes.func.isRequired,
  locationId: PropTypes.string.isRequired,
  setLocationId: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
};

export default EmailTab;
