import React, { useState, useEffect } from 'react';

import Colors from 'constants/colors';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';

import { ReactComponent as AlertIcon } from 'assets/images/alert_icon.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { teamsActions } from 'store/entities/Teams';

import MatDrawer from 'components/Elements/MatDrawer/MatDrawer';
import MatFormLabel from 'components/Elements/MatFormLabel/FormLabel';
import MatInput from 'components/Elements/MatInput/MatInput';
import MatSwitch from 'components/Elements/MatSwitch/MatSwtich';
import { UploadImage } from 'components/ImageSelectors';
import { Save } from '@mui/icons-material';

const EditLocation = ({ open, onClose, location }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [active, setActive] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [website, setWebsite] = useState('');
  const [image, setImage] = useState({
    type: '',
    upload: false,
    url: '',
  });

  useEffect(() => {
    setLoadingUpdate(false);
    if (location) {
      setName(location.name);
      setActive(location.is_active);
      setPhoneNumber(location.phone);
      setWebsite(location.url);
      if (location.logo_url) {
        setImage({
          type: 'custom',
          upload: false,
          url: location.logo_url,
        } as any);
      }
    }
  }, [location]);

  const handleImage = (val) => {
    setImage(val);
  };

  const onSubmit = () => {
    if (name && name !== '') {
      setLoadingUpdate(true);
      dispatch(
        teamsActions.updateTeam({
          teamId: location.objectId,
          name,
          phoneNumber,
          website,
          imageUrl: image.url,
          status: active,
        }),
      );
      onClose();
    }
  };

  return (
    <MatDrawer
      open={open}
      anchor="right"
      onClose={onClose}
      onCancel={onClose}
      onSubmit={onSubmit}
      title={t('editTeam')}
      submitBtn={<Save />}
      cancelBtn={t('cancel')}
      isLoading={loadingUpdate}
    >
      <Box maxWidth={465} width="100%">
        <Grid container spacing={2}>
          <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
            <UploadImage
              currentImage={location ? location.logo_url : null}
              onChange={handleImage}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title={t('giveTeamDistinctName') as any}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              {t('teamName')}
            </MatFormLabel>
            <MatInput
              value={name}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setName(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                },
              }}
            />
          </Grid>
          {/* <Grid item md={8} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title="Street Address">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Street Address
            </MatFormLabel>
            <MatInput
              value={address}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setAddress(e.target.value)}
              sx={{ '& .MuiOutlinedInput-root': {
      maxHeight: 53,
    },
    [`& fieldset`]: {
      borderColor: Colors.primary,
    }, }}
            />
          </Grid>
          <Grid item md={4} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title="Suite or apartment number">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Suite
            </MatFormLabel>
            <MatInput
              value={suite || ''}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setSuite(e.target.value)}
              sx={{ '& .MuiOutlinedInput-root': {
      maxHeight: 53,
    },
    [`& fieldset`]: {
      borderColor: Colors.primary,
    }, }}
            />
          </Grid>
          <Grid item md={4} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title="City">
                  <AlertIcon />
                </Tooltip>
              }
            >
              City
            </MatFormLabel>
            <MatInput
              value={city}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setCity(e.target.value)}
              sx={{ '& .MuiOutlinedInput-root': {
      maxHeight: 53,
    },
    [`& fieldset`]: {
      borderColor: Colors.primary,
    }, }}
            />
          </Grid>
          <Grid item md={4} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title="State">
                  <AlertIcon />
                </Tooltip>
              }
            >
              State
            </MatFormLabel>
            <MatInput
              value={state}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setState(e.target.value)}
              sx={{ '& .MuiOutlinedInput-root': {
      maxHeight: 53,
    },
    [`& fieldset`]: {
      borderColor: Colors.primary,
    }, }}
            />
          </Grid>
          <Grid item md={4} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title="Zip code or postal code">
                  <AlertIcon />
                </Tooltip>
              }
            >
              Postal
            </MatFormLabel>
            <MatInput
              value={zip}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setZip(e.target.value)}
              sx={{ '& .MuiOutlinedInput-root': {
      maxHeight: 53,
    },
    [`& fieldset`]: {
      borderColor: Colors.primary,
    }, }}
            />
          </Grid> */}
          <Grid item md={6} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title={t('phoneNumberReachTeam') as any}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              {t('phoneNumber')}
            </MatFormLabel>
            <MatInput
              value={phoneNumber || ''}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setPhoneNumber(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                },
              }}
            />
          </Grid>
          <Grid item md={6} xs={12} sx={{ marginBottom: 2 }}>
            <MatFormLabel
              icon={
                <Tooltip title={t('websiteSpecificTeam') as any}>
                  <AlertIcon />
                </Tooltip>
              }
            >
              {t('websiteAddress')}
            </MatFormLabel>
            <MatInput
              value={website || ''}
              variant="outlined"
              placeholder=""
              size="small"
              onChange={(e) => setWebsite(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  maxHeight: 53,
                },
                [`& fieldset`]: {
                  borderColor: Colors.primary,
                },
              }}
            />
          </Grid>
          <Grid item md={12} xs={12} sx={{ marginBottom: 2 }}>
            <FormControl sx={{ width: '100%', alignItems: 'center' }}>
              <MatSwitch
                labelActive={t('active')}
                labelInactive={t('inactive')}
                defaultChecked={active}
                onChange={() => setActive(!active)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </MatDrawer>
  );
};

EditLocation.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object,
};

EditLocation.defaultProps = {
  location: null,
};

export default EditLocation;
