import React from 'react';

import Colors from 'constants/colors';
import Permissions from 'constants/permissions';
import { defaultStyles } from 'constants/styles';

import EditIcon from '@mui/icons-material/Edit';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow';
import Hidden from '@mui/material/Hidden';

import Moment from 'moment';
import PropTypes from 'prop-types';

import PermissionRequired from 'components/PermissionRequired';

import StatusCell from './StatusCell';
import TextWithToolTip from 'components/TextWithToolTip/TextWithToolTip';

const Row = ({ campaign, onClick, onMenuButton, showRunAgain }) => {
  const clickHandler = () => {
    onClick(campaign.objectId);
  };

  return (
    <TableRow
      hover
      key={campaign.id}
      onClick={clickHandler}
      sx={{
        boxShadow: defaultStyles.box.shadow,
        backgroundColor: Colors.white,
        borderRadius: 2.5,
        '&:hover': {
          boxShadow: ' 0px 0px 0px 1px rgb(0 99 255 / 50%)',
          backgroundColor: `${Colors.white} !important`,
          cursor: 'pointer',
        },
      }}
    >
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          width: 84,
        }}
      >
        <StatusCell isActive={!!(campaign.type === 'active' || 'future')} />
      </TableCell>
      <TableCell
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          fontWeight: 700,
          color: 'inherit',
        }}
      >
        <Box display="flex" alignItems="center">
          {/* <StatusCell isActive={!!(campaign.type === 'active' || 'future')} /> */}

          <Box display="flex" py={1} flexDirection="column">
            <TextWithToolTip text={campaign.title} />
            <Hidden lgDown={true}>
              {campaign.tags && !!campaign.tags.length && (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  p="4px 0 4px"
                  sx={{
                    '@media max-width:1279.95px': {
                      display: 'none',
                    },
                  }}
                >
                  {campaign.tags.map((tag) => (
                    <Chip
                      key={tag}
                      label={tag}
                      sx={{ margin: 0.25, fontWeight: 400, fontSize: 12 }}
                    />
                  ))}
                </Box>
              )}
            </Hidden>
          </Box>
        </Box>
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
        }}
      >
        {campaign.engagements}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
        }}
      >
        {campaign.impressions}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: 'none',
          padding: 0,
          minHeight: 64,
          fontSize: 16,
          color: 'inherit',
          '@media max-width:1279.95px': {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
          },
        }}
      >
        {campaign.conversions}
      </TableCell>
      <Hidden lgDown={true}>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        >
          {Moment(campaign.createdAt).format('MM/D/YYYY')}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        >
          {Moment(campaign.start_date).format('MM/D/YYYY')}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
        >
          {Moment(campaign.end_date).format('MM/D/YYYY')}
        </TableCell>
        <TableCell
          align="center"
          sx={{
            border: 'none',
            padding: 0,
            minHeight: 64,
            fontSize: 16,
            color: 'inherit',
            width: 115,
            '@media max-width:1279.95px': {
              display: 'none',
            },
          }}
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {campaign.type !== 'legacy' &&
            (showRunAgain ? (
              <Button
                sx={{
                  color: Colors.primary,
                  marginLeft: 0.5,
                  '@media max-width:1279.95px': {
                    display: 'none',
                  },
                }}
                onClick={onMenuButton}
              >
                Run Again
                <PlayArrowIcon
                  sx={{ color: Colors.primary, marginLeft: 0.5 }}
                />
              </Button>
            ) : (
              <PermissionRequired permissions={[Permissions.campaign.edit]}>
                <Button
                  sx={{
                    color: Colors.primary,
                    marginLeft: 0.5,
                    '@media max-width:1279.95px': {
                      display: 'none',
                    },
                  }}
                  onClick={onMenuButton}
                >
                  Edit
                  <EditIcon
                    sx={{ color: Colors.primary, marginLeft: 1.25, width: 16 }}
                  />
                </Button>
              </PermissionRequired>
            ))}
        </TableCell>
      </Hidden>
    </TableRow>
  );
};

Row.propTypes = {
  campaign: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onMenuButton: PropTypes.func.isRequired,
  showRunAgain: PropTypes.bool,
};

Row.defaultProps = {
  showRunAgain: false,
};

export default Row;
