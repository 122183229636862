import React from 'react';

import Colors from 'constants/colors';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import PropTypes from 'prop-types';

const useStyles: any = makeStyles({
  common: {
    color: Colors.primaryBlack,
    textTransform: 'capitalize',
  },
  bold: {
    fontWeight: 'bold',
  },
  thin: {
    fontWeight: 100,
  },
  small: {
    fontSize: 14,
  },
  medium: {
    fontSize: 18,
  },
  big: {
    fontSize: 22,
  },
  grey: {
    opacity: '.5',
  },
});

const Title = ({ children, size, weight, color, className }) => {
  const classes: any = useStyles();
  return (
    <Typography
      className={`${classes[size]} ${classes.common} ${classes[weight]} ${classes[color]} ${className}`}
    >
      {children}
    </Typography>
  );
};

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  size: PropTypes.string.isRequired,
  weight: PropTypes.any,
  color: PropTypes.any,
  className: PropTypes.object,
};

Title.defaultProps = {
  weight: null,
  color: null,
  className: null,
};

export default Title;
